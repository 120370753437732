import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace547 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#FC532D" d="M150.675 0H0v150.675h150.675V0Z" />
      <path fill="#383838" d="M104.968 100.02H44.013v46.994h60.955V100.02Z" />
      <path
        fill="#7814BC"
        d="M104.821 100.734H73.784v46.28h31.037v-46.28Z"
        opacity={0.64}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path
        fill="#DF2D2C"
        d="M30.66 147.014H20.223v-13.23c0-13.818 11.242-25.063 25.063-25.063v10.437a14.64 14.64 0 0 0-14.626 14.626v13.23Z"
      />
      <path
        fill="#C12E58"
        d="M128.635 147.014h-10.437v-13.23a14.642 14.642 0 0 0-14.626-14.626v-10.437c13.818 0 25.063 11.244 25.063 25.063v13.23Z"
      />
      <path
        fill="#383838"
        d="M46.61 107.324h-6.616V123.2h6.615v-15.876ZM108.79 107.324h-6.615V123.2h6.615v-15.876Z"
      />
      <path
        fill="#DF2D2C"
        d="M73.637 124.788c28.032 0 50.756-22.724 50.756-50.756s-22.724-50.756-50.756-50.756S22.88 46 22.88 74.032s22.724 50.756 50.756 50.756Z"
      />
      <path
        fill="#C12E58"
        d="M74.04 23.276c28.031 0 50.756 22.724 50.756 50.756 0 28.031-22.725 50.757-50.756 50.757"
      />
      <path
        fill="#F96020"
        d="M75.928 147.658a9.116 9.116 0 0 0 9.118-9.116 9.116 9.116 0 0 0-9.118-9.117 9.116 9.116 0 0 0-9.116 9.117 9.116 9.116 0 0 0 9.117 9.116Z"
      />
      <path
        fill="#D74816"
        d="M75.928 146.521a7.98 7.98 0 1 0 0-15.96 7.98 7.98 0 0 0 0 15.96Z"
      />
      <path
        fill="#FFA520"
        d="m68.657 141.538 3.692 3.692 10.06-10.061-3.692-3.692-10.06 10.061Z"
      />
      <path
        fill="#F96020"
        d="M75.11 148.239a9.755 9.755 0 0 0 9.755-9.755c0-5.387-4.368-9.755-9.755-9.755-5.388 0-9.755 4.368-9.755 9.755a9.755 9.755 0 0 0 9.755 9.755Z"
      />
      <path
        fill="#D74816"
        d="M75.108 147.022a8.538 8.538 0 1 0 0-17.076 8.538 8.538 0 0 0 0 17.076Z"
      />
      <path
        fill="#FF9317"
        d="M74.733 130.624h.77a.77.77 0 0 0 .165.012c.222.019.441.041.665.076.523.08 1.037.214 1.533.4a7.886 7.886 0 0 1 5.054 6.402c.022.179.038.36.045.539a.24.24 0 0 0 .012.121v.633a.224.224 0 0 0-.012.121 7.635 7.635 0 0 1-.869 3.182 7.87 7.87 0 0 1-9.128 3.947 7.806 7.806 0 0 1-4.37-3.154 7.794 7.794 0 0 1-1.33-3.759 2.477 2.477 0 0 0-.024-.276v-.755a.11.11 0 0 0 .01-.058 7.808 7.808 0 0 1 .772-2.995 7.887 7.887 0 0 1 6.54-4.421c.056-.002.112-.007.167-.015Zm-6.174 7.864a6.557 6.557 0 1 0 6.555-6.559 6.568 6.568 0 0 0-6.061 4.049 6.553 6.553 0 0 0-.498 2.51h.004Z"
      />
      <path
        fill="#FF9317"
        d="M77.085 138.255c.279.046.55.13.806.248.323.146.593.387.775.691.12.21.196.442.223.682.04.304.027.613-.04.913a1.603 1.603 0 0 1-1 1.176 3.714 3.714 0 0 1-.847.225 6.632 6.632 0 0 1-.588.064c-.07 0-.07 0-.07.075v1.168c0 .073 0 .075-.074.075h-.854c-.083 0-.078 0-.078-.078v-1.129c0-.094.007-.084-.086-.084H74.4c-.068 0-.068 0-.07.07v1.137c0 .087 0 .084-.079.084h-.843c-.081 0-.081 0-.081-.084v-1.167c0-.021-.01-.043-.038-.04h-1.926c-.035 0-.05-.009-.05-.046v-.836c0-.04.016-.052.053-.052h.52a1.5 1.5 0 0 0 .313-.039.282.282 0 0 0 .228-.231c.031-.137.045-.278.043-.419V136.282a2 2 0 0 0-.04-.396.297.297 0 0 0-.251-.247 2.03 2.03 0 0 0-.405-.035h-.39c-.07 0-.07 0-.07-.072v-.775c0-.088-.008-.082.08-.083h1.844c.084 0 .084 0 .086-.08v-1.16c0-.029.013-.041.041-.038h.882c.09 0 .082-.009.082.08v1.107c0 .102-.007.091.094.091h.846c.067 0 .067 0 .069-.072v-1.168c0-.029.01-.041.04-.038h.881c.087 0 .084-.013.084.085v1.153c0 .057 0 .057.057.062.304.02.605.069.899.147.162.043.32.102.47.176a1.353 1.353 0 0 1 .735.891c.098.347.104.714.018 1.064-.09.359-.308.673-.613.882-.24.166-.505.291-.785.372-.01.005-.028.002-.038.027Zm-2.757 1.822v1.22c0 .065 0 .067.064.067h.442c.313.007.627-.008.939-.046a2.22 2.22 0 0 0 .538-.137.926.926 0 0 0 .559-.611c.087-.286.1-.59.038-.882a.928.928 0 0 0-.412-.623 1.333 1.333 0 0 0-.39-.15 3.522 3.522 0 0 0-.677-.096c-.352-.021-.703 0-1.054-.012-.038 0-.05.015-.047.049.003.034 0 .035 0 .053v1.168Zm0-3.345v1.085c0 .036 0 .054.05.054.378.01.757-.001 1.135-.034.184-.02.366-.064.54-.131a.811.811 0 0 0 .506-.588c.06-.229.07-.468.033-.702a.805.805 0 0 0-.563-.674 2.701 2.701 0 0 0-.735-.129c-.294-.015-.588 0-.882 0h-.031c-.025 0-.037.012-.036.037.002.025 0 .039 0 .06l-.017 1.022ZM67.59 131.81a2.053 2.053 0 1 0 .001-4.106 2.053 2.053 0 0 0 0 4.106Z"
      />
      <path
        fill="#FF9317"
        d="M64.51 128.729a2.053 2.053 0 1 0 0-4.106 2.053 2.053 0 0 0 0 4.106Z"
      />
      <path
        fill="#FF9317"
        d="M61.43 125.649a2.053 2.053 0 1 0 0-4.106 2.053 2.053 0 0 0 0 4.106Z"
      />
      <path
        fill="#FF9317"
        d="M58.349 122.568a2.053 2.053 0 1 0 0-4.106 2.053 2.053 0 0 0 0 4.106Z"
      />
      <path
        fill="#F96020"
        d="M82.992 131.81a2.053 2.053 0 1 0 0-4.106 2.053 2.053 0 0 0 0 4.106ZM86.073 128.729a2.053 2.053 0 1 0 0-4.106 2.053 2.053 0 0 0 0 4.106ZM89.153 125.649a2.053 2.053 0 1 0 0-4.106 2.053 2.053 0 0 0 0 4.106ZM92.234 122.568a2.053 2.053 0 1 0 0-4.106 2.053 2.053 0 0 0 0 4.106Z"
      />
      <path
        fill="#F26227"
        d="M20.827 78.892a9.002 9.002 0 1 0 0-18.005 9.002 9.002 0 0 0 0 18.005Z"
      />
      <path
        fill="#D74B27"
        d="M20.827 77.769a7.88 7.88 0 1 0 0-15.759 7.88 7.88 0 0 0 0 15.759Z"
      />
      <path
        fill="#F79421"
        d="M20.48 62.637h.709c.048.007.097.01.147.01a8.7 8.7 0 0 1 .614.07c.484.075.959.198 1.417.37a7.285 7.285 0 0 1 4.365 4.556 6.971 6.971 0 0 1 .335 1.851.22.22 0 0 0 .01.11v.588a.202.202 0 0 0-.01.11 7.04 7.04 0 0 1-.806 2.937 7.247 7.247 0 0 1-7.037 3.893 7.03 7.03 0 0 1-1.386-.25 7.213 7.213 0 0 1-4.034-2.91 7.215 7.215 0 0 1-1.227-3.47 1.853 1.853 0 0 0-.022-.254v-.696a.083.083 0 0 0 .01-.053c.016-.315.053-.628.112-.938a7.25 7.25 0 0 1 6.637-5.907c.056-.002.111-.008.166-.017Zm-5.702 7.257a6.053 6.053 0 1 0 3.731-5.598 6.046 6.046 0 0 0-3.731 5.598Z"
      />
      <path
        fill="#F79421"
        d="M22.651 69.68c.257.042.506.12.742.229a1.6 1.6 0 0 1 .716.636c.111.194.181.408.206.63.038.28.025.565-.037.842a1.479 1.479 0 0 1-.923 1.088c-.252.1-.515.169-.783.207-.181.028-.362.048-.546.059-.063 0-.064 0-.064.07v1.078c0 .067 0 .067-.07.067h-.786c-.076 0-.072 0-.072-.07v-1.042c0-.087.007-.078-.08-.078h-.784c-.064 0-.064 0-.064.064v1.05c0 .08.008.076-.073.076h-.782c-.074 0-.075 0-.075-.076v-1.078c0-.025-.009-.04-.035-.036-.027.003-.038 0-.056 0h-1.717c-.031 0-.046 0-.046-.043v-.772c0-.037.015-.047.05-.045h.48c.099-.002.197-.015.293-.038a.26.26 0 0 0 .21-.214c.029-.127.042-.256.039-.386V67.86a1.684 1.684 0 0 0-.035-.365.277.277 0 0 0-.233-.228 1.879 1.879 0 0 0-.373-.034h-.362c-.063 0-.063 0-.063-.066v-.716c0-.082 0-.076.074-.076h1.702c.076 0 .076 0 .076-.075v-1.07c0-.02.014-.037.039-.035h.814c.082 0 .075-.01.075.073v1.022c0 .095 0 .085.085.085h.78c.063 0 .063 0 .063-.068V65.23c0-.016.01-.038.036-.037h.815c.08 0 .076-.012.076.08v1.062c0 .053 0 .052.054.056.28.019.557.064.828.134.152.04.3.094.441.163a1.25 1.25 0 0 1 .684.823c.09.32.095.659.016.982a1.402 1.402 0 0 1-.566.816c-.222.153-.466.27-.725.344-.019.006-.035.001-.044.026Zm-2.544 1.681v1.126c0 .06 0 .06.06.06h.407a5.9 5.9 0 0 0 .867-.04c.17-.022.337-.065.497-.127a.856.856 0 0 0 .515-.565c.08-.262.092-.541.035-.81a.85.85 0 0 0-.38-.574 1.217 1.217 0 0 0-.365-.147 3.174 3.174 0 0 0-.625-.089c-.324-.019-.648 0-.973-.01-.034 0-.046.013-.043.044.003.031 0 .033 0 .049l.005 1.083Zm0-3.087v1c0 .034.007.051.045.05.35.01.7 0 1.048-.03.17-.019.338-.06.498-.122a.755.755 0 0 0 .468-.54c.053-.213.061-.436.022-.653a.734.734 0 0 0-.517-.62 2.455 2.455 0 0 0-.683-.121c-.273-.013-.546 0-.82 0h-.035l-.013.001a.029.029 0 0 0-.018.02.029.029 0 0 0-.001.013v.055c.003.319.004.635.004.947h.002Z"
      />
      <path
        fill="#F26227"
        d="M131.959 79.33a9.002 9.002 0 0 0 9.002-9.002 9.002 9.002 0 1 0-18.004 0 9.002 9.002 0 0 0 9.002 9.002Z"
      />
      <path
        fill="#D74B27"
        d="M131.959 78.207a7.879 7.879 0 0 0 7.879-7.88 7.879 7.879 0 1 0-15.758 0 7.88 7.88 0 0 0 7.879 7.88Z"
      />
      <path
        fill="#F79421"
        d="M131.612 63.073h.709a.747.747 0 0 0 .147.01c.205.018.41.04.614.071.483.075.957.199 1.414.37a7.283 7.283 0 0 1 4.365 4.558 7.026 7.026 0 0 1 .335 1.85.223.223 0 0 0 .01.112v.588a.203.203 0 0 0-.01.112 7.029 7.029 0 0 1-.806 2.936 7.262 7.262 0 0 1-8.423 3.643 7.217 7.217 0 0 1-4.033-2.91 7.224 7.224 0 0 1-1.228-3.47 1.828 1.828 0 0 0-.022-.255V69.999a.086.086 0 0 0 .01-.055c.016-.314.054-.627.112-.936a7.089 7.089 0 0 1 .601-1.828 7.28 7.28 0 0 1 4.769-3.88c.416-.104.84-.17 1.267-.2a1.05 1.05 0 0 0 .169-.027Zm-5.702 7.258a6.053 6.053 0 1 0 12.105-.004 6.053 6.053 0 0 0-12.105.004Z"
      />
      <path
        fill="#F79421"
        d="M133.784 70.116c.254.043.501.12.735.23.298.134.548.356.715.637.112.194.182.408.206.63.038.28.026.565-.037.842a1.464 1.464 0 0 1-.923 1.086c-.252.1-.515.17-.783.207a6.162 6.162 0 0 1-.546.06c-.063 0-.064 0-.064.07v1.077c0 .068 0 .07-.069.07h-.787c-.076 0-.072 0-.072-.073V73.91c0-.087 0-.078-.079-.078h-.785c-.063 0-.063 0-.063.066v1.05c0 .08.007.076-.074.076h-.775c-.073 0-.075 0-.075-.078V73.87c0-.025-.008-.038-.035-.037H128.5c-.03 0-.045-.007-.045-.041v-.772c0-.038.014-.047.05-.047h.479c.099-.002.198-.015.294-.038a.259.259 0 0 0 .21-.213c.028-.127.041-.256.038-.385v-4.038a1.667 1.667 0 0 0-.035-.366.276.276 0 0 0-.232-.228 1.87 1.87 0 0 0-.373-.034h-.362c-.063 0-.063 0-.063-.066v-.716c0-.08 0-.077.073-.077h1.702c.077 0 .077 0 .077-.073v-1.07c0-.018.013-.038.038-.037h.814c.083 0 .075-.007.075.074v1.021c0 .096-.007.085.086.085h.779c.063 0 .063 0 .063-.066v-1.077c0-.016.01-.038.037-.037h.814c.081 0 .077-.01.077.08v1.065c0 .053 0 .053.054.056.279.02.557.065.828.135.151.04.299.094.441.162a1.254 1.254 0 0 1 .683.823c.09.321.096.66.016.984a1.393 1.393 0 0 1-.566.814c-.221.153-.466.27-.724.344-.019.002-.036-.001-.044.022Zm-2.545 1.682v1.126c0 .06 0 .06.06.06h.407c.29.007.58-.007.868-.043.17-.02.337-.063.497-.125a.86.86 0 0 0 .514-.566c.08-.262.093-.54.035-.81a.844.844 0 0 0-.379-.574 1.222 1.222 0 0 0-.366-.147 3.425 3.425 0 0 0-.625-.088c-.323-.02-.648 0-.973-.01-.034 0-.045.013-.042.045.003.032 0 .032 0 .048.003.362.003.722.004 1.084Zm0-3.087v1.001c0 .032.007.05.046.048.349.01.699 0 1.048-.03.17-.018.338-.06.498-.121a.754.754 0 0 0 .467-.538 1.56 1.56 0 0 0 .023-.656.741.741 0 0 0-.518-.62 2.458 2.458 0 0 0-.682-.12c-.273-.014-.547 0-.82-.007h-.036c-.022 0-.033.01-.032.034v.056l.005.953h.001Z"
      />
      <path
        fill="#AAAECC"
        d="M29.51 78.173h-.308a15.697 15.697 0 0 1-15.696-15.698v-9.452h39.145l-23.14 25.15Z"
      />
      <path
        fill="#727FBD"
        d="M118.673 78.173h.316a15.695 15.695 0 0 0 15.688-15.698v-9.452h-32.928l16.924 25.15Z"
      />
      <path
        fill="#4D2F47"
        d="M120.96 58.74a8.002 8.002 0 0 1 0 16.003M27.223 74.743a8.002 8.002 0 0 1 0-16.004"
      />
      <path
        fill="#727FBD"
        d="M27.223 73.69a5.19 5.19 0 0 1 0-10.379M120.343 63.311a5.19 5.19 0 0 1 0 10.38"
      />
      <path
        fill="#4A2955"
        d="M87.276 111.119v7.363l-4.091-4.417v13.252l-6.821-7.364-2.729 8.837v-16.198"
      />
      <path
        fill="#161F26"
        d="M59.886 110.892v7.362l4.091-4.417v13.252l6.821-7.362 2.728 8.835v-16.198"
      />
      <path
        fill="#883A62"
        d="M34.078 81.71c6.882 19.095 22.264 32.393 40.144 32.393s33.263-13.298 40.144-32.393H34.078Z"
      />
      <path
        fill="#001A1B"
        d="M36.852 85.1c6.306 15.141 20.403 25.695 36.783 25.695 16.38 0 30.482-10.541 36.786-25.695h-73.57Z"
      />
      <path
        fill="#EFE1DA"
        d="M93.755 84.654v16.197l-8.185-17.67M81.478 84.654v7.362l-2.728-8.835M77.385 84.654v5.89h-2.727v-5.89"
      />
      <path
        fill="#DBB9BE"
        d="m89.663 108.214-8.185-8.835v11.779M78.75 111.159v-10.308h-4.091v11.78"
      />
      <path
        fill="#EFE1DA"
        d="M54.565 84.688v16.198l8.185-17.67M66.842 84.688v7.363l2.729-8.834M70.935 84.688v5.89h2.728v-5.89"
      />
      <path
        fill="#DBB9BE"
        d="m58.657 108.248 8.185-8.835v11.779M69.571 111.192v-10.306h4.093v11.779"
      />
      <path
        fill="#883A62"
        d="M74.157 114.031c17.878 0 33.263-13.299 40.144-32.395H34.013c6.882 19.096 22.267 32.395 40.144 32.395Zm-.01-3.88c-14.71 0-27.372-10.023-33.034-24.416h66.068c-5.663 14.393-18.324 24.416-33.034 24.416Z"
      />
      <path
        stroke="#001A1B"
        strokeMiterlimit={10}
        strokeWidth={0.902}
        d="M74.115 111.333c-15.73 0-29.262-11.172-35.317-27.205h70.632c-6.048 16.038-19.59 27.205-35.315 27.205Z"
      />
      <path
        fill="#FFF8DA"
        d="M51.215 72.18c6.137 0 11.112-4.975 11.112-11.112 0-6.137-4.975-11.112-11.112-11.112-6.137 0-11.112 4.975-11.112 11.112 0 6.137 4.975 11.112 11.112 11.112Z"
      />
      <path
        fill="#ABA2CA"
        d="M98.128 72.18c6.137 0 11.112-4.975 11.112-11.112 0-6.137-4.975-11.112-11.112-11.112-6.136 0-11.111 4.975-11.111 11.112 0 6.137 4.975 11.112 11.111 11.112Z"
      />
      <path
        fill="#2C1F41"
        d="M51.215 67.24a6.173 6.173 0 1 0 0-12.345 6.173 6.173 0 0 0 0 12.346Z"
      />
      <path
        fill="#172027"
        d="M98.129 67.24a6.172 6.172 0 1 0 0-12.344 6.172 6.172 0 0 0 0 12.345Z"
      />
      <path
        fill="#4D2F47"
        d="M79.998 79.674a5.5 5.5 0 0 1-5.501-5.5v-9.85h6.133a5.803 5.803 0 0 1 5.652 7.125l-.928 3.97a5.502 5.502 0 0 1-5.356 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M79.926 77.88a3.637 3.637 0 1 0 0-7.273 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M79.876 76.984a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path
        fill="#883A62"
        d="M69.015 79.63a5.5 5.5 0 0 0 5.501-5.501V64.28h-6.134a5.804 5.804 0 0 0-5.653 7.125l.93 3.97a5.499 5.499 0 0 0 5.356 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M69.084 77.837a3.637 3.637 0 1 0 0-7.274 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M69.134 76.94a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path
        fill="#FFA520"
        d="M103.587 18.522H74.589l1.877 1.502a20.364 20.364 0 0 0 27.121-1.502Z"
      />
      <path
        fill="#B98D22"
        d="M103.463 18.522H74.466l1.877-1.503a20.364 20.364 0 0 1 27.12 1.503Z"
      />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace547;
