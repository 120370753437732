import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace525 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#EFE2DB" d="M150.675 0H0v150.675h150.675V0Z" />
      <path fill="#001A1B" d="M148.47 77.488H0v69.776h148.47V77.488Z" />
      <path fill="#BCAFB1" d="M116.092 95.356H33.956v52.232h82.136V95.356Z" />
      <path fill="#172027" d="M86.574 84.582h-24.61v63.006h24.61V84.582Z" />
      <path
        fill="#DF2D2C"
        d="M73.637 124.788c28.032 0 50.756-22.724 50.756-50.756s-22.724-50.756-50.756-50.756S22.88 46 22.88 74.032s22.724 50.756 50.756 50.756Z"
      />
      <path
        fill="#C12E58"
        d="M74.04 23.276c28.031 0 50.756 22.724 50.756 50.756 0 28.031-22.725 50.757-50.757 50.757"
      />
      <path
        fill="#BA3D11"
        d="m44.33 40.411-.974-3.788c-2.499-9.735-14.272-13.574-22.035-7.188L5.886 42.139l22.44 5.465 10.692-8.796 2.94 3.567"
      />
      <path
        fill="#A31905"
        d="m105.314 40.522.974-3.788c2.499-9.736 14.273-13.574 22.036-7.189l15.442 12.706-22.438 5.464-10.702-8.804-2.94 3.567"
      />
      <path
        fill="#BA3D11"
        d="M46.999 94.743a43.896 43.896 0 0 0 25.173 7.938h2.033v-7.938"
      />
      <path
        fill="#A31905"
        d="M101.58 94.702a43.902 43.902 0 0 1-25.175 7.938H74.37v-7.938"
      />
      <path
        fill="#6C1908"
        d="m74.205 90.208-3.401-3.4-24.94 7.935h28.341v-4.535Z"
      />
      <path
        fill="#3E1B11"
        d="m74.371 90.167 3.402-3.4 24.94 7.935H74.372v-4.535Z"
      />
      <path
        fill="#3D2F39"
        d="m102.258 52.51-13.733 4.975a7.528 7.528 0 0 0-4.515 9.641l.312.861a7.528 7.528 0 0 0 9.642 4.515l13.733-4.974a7.528 7.528 0 0 0 4.514-9.642l-.312-.86a7.527 7.527 0 0 0-9.641-4.515Z"
      />
      <path
        fill="#EB6447"
        d="M94.894 70.51a6.844 6.844 0 1 0 0-13.69 6.844 6.844 0 0 0 0 13.69Z"
      />
      <path
        fill="#3D2F39"
        d="m93.01 58.287-.12.044c-.56.202-.849.82-.646 1.378l3.152 8.703c.203.559.82.848 1.378.645l.122-.044c.559-.202.847-.819.645-1.378l-3.152-8.703a1.076 1.076 0 0 0-1.378-.645Z"
      />
      <path
        fill="#80191B"
        d="m40.48 67.746 13.734 4.974a7.528 7.528 0 0 0 9.641-4.515l.312-.86a7.528 7.528 0 0 0-4.514-9.642L45.92 52.729a7.528 7.528 0 0 0-9.642 4.514l-.312.861a7.528 7.528 0 0 0 4.515 9.642Z"
      />
      <path
        fill="#EB6447"
        d="M53.289 70.733a6.844 6.844 0 1 0 0-13.689 6.844 6.844 0 0 0 0 13.69Z"
      />
      <path
        fill="#3D2F39"
        d="m51.28 69.23.123.045a1.076 1.076 0 0 0 1.378-.645l3.152-8.704a1.076 1.076 0 0 0-.645-1.378l-.122-.044a1.076 1.076 0 0 0-1.378.645l-3.152 8.704a1.076 1.076 0 0 0 .645 1.378Z"
      />
      <path
        fill="#F55B20"
        d="M74.264 65.64v14.983a5.683 5.683 0 0 1-5.586-6.702l1.506-8.282h4.08Z"
      />
      <path
        fill="#883A62"
        d="M74.12 65.64v14.983a5.686 5.686 0 0 0 5.586-6.702L78.2 65.64h-4.08Z"
      />
      <path fill="#542F5F" d="M74.382 12.936H53.214V32.78h21.168V12.936Z" />
      <path fill="#172027" d="M95.55 12.936H74.382V32.78H95.55V12.936Z" />
      <path fill="#172027" d="M112.749 31.458H37.338v2.646h75.411v-2.646Z" />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace525;
