import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace82 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#F8F3C1" d="M150.675 0H0v150.675h150.675V0Z" />
      <path
        fill="#6C1908"
        d="M74.088 72.65a60.66 60.66 0 0 1 60.654 60.661v14.13H13.422v-14.13a60.659 60.659 0 0 1 60.666-60.66Z"
      />
      <path fill="#DBB9BE" d="M75.088 66.78H23.773v34.435h51.315V66.781Z" />
      <path
        fill="#D7BBBD"
        d="M78.054 100.362H16.783L40.61 124.19h37.444v-23.828Z"
      />
      <path
        fill="#EFE1DA"
        d="M23.921 66.714h51.06V16.79h-1.377a22.62 22.62 0 0 0-17.897 8.788L23.921 66.714Z"
      />
      <path
        fill="#DBB9BE"
        d="M73.027 21.328h-.631v38.579h.63V21.328ZM54.818 35.902l-.521.356 14.75 21.56.52-.357-14.75-21.559Z"
      />
      <path
        fill="#FBF5DE"
        d="M64.768 34.945a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54ZM60.229 29.272a1.135 1.135 0 1 0 0-2.27 1.135 1.135 0 0 0 0 2.27ZM62.498 38.348a1.135 1.135 0 1 0 0-2.27 1.135 1.135 0 0 0 0 2.27ZM53.421 42.887a1.135 1.135 0 1 0 0-2.27 1.135 1.135 0 0 0 0 2.27Z"
      />
      <path fill="#DBB9BE" d="M74.445 101.086h51.315V66.651H74.445v34.435Z" />
      <path
        fill="#D7BBBD"
        d="M71.479 100.266h61.271l-23.828 23.827H71.479v-23.827Z"
      />
      <path
        fill="#EFE1DA"
        d="M125.613 66.617h-51.06V16.693h1.375a22.62 22.62 0 0 1 17.898 8.788l31.787 41.136Z"
      />
      <path
        fill="#DBB9BE"
        d="M77.138 21.23h-.63v38.58h.63V21.23ZM94.716 35.803l-14.75 21.559.52.356 14.75-21.559-.52-.356Z"
      />
      <path
        fill="#FBF5DE"
        d="M84.765 34.848a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54ZM89.304 29.175a1.135 1.135 0 1 0 0-2.27 1.135 1.135 0 0 0 0 2.27ZM87.034 38.252a1.135 1.135 0 1 0 0-2.27 1.135 1.135 0 0 0 0 2.27ZM96.112 42.79a1.135 1.135 0 1 0 0-2.27 1.135 1.135 0 0 0 0 2.27Z"
      />
      <path
        fill="#AAAECC"
        d="M29.51 78.173h-.309a15.697 15.697 0 0 1-15.695-15.698v-9.452h39.145l-23.14 25.15Z"
      />
      <path
        fill="#727FBD"
        d="M118.673 78.173h.316a15.697 15.697 0 0 0 15.688-15.698v-9.453h-32.928l16.924 25.15Z"
      />
      <path
        fill="#4D2F47"
        d="M120.96 58.74a8.002 8.002 0 0 1 0 16.003M27.223 74.743a8.003 8.003 0 0 1 0-16.004"
      />
      <path
        fill="#727FBD"
        d="M27.223 73.69a5.19 5.19 0 0 1 0-10.38M120.343 63.31a5.192 5.192 0 0 1 5.103 5.19 5.193 5.193 0 0 1-5.103 5.19"
      />
      <path fill="#FA8CB2" d="M78.161 81.429H46.665V96.25h31.496V81.429Z" />
      <path fill="#EFE1DA" d="M78.163 83.28H50.37v11.117h27.792V83.281Z" />
      <path
        fill="#001A1B"
        d="M55.93 83.28h-1.853v11.117h1.852V83.281ZM61.487 83.28h-1.852v11.117h1.852V83.281ZM67.05 83.275h-1.852V94.39h1.852V83.275ZM72.608 83.275h-1.852V94.39h1.852V83.275Z"
      />
      <path fill="#F46461" d="M73.526 96.255h28.732V81.433H73.527v14.822Z" />
      <path fill="#EFE1DA" d="M72.599 94.403h25.954V83.287H72.6v11.116Z" />
      <path
        fill="#001A1B"
        d="M92.994 94.403h1.852V83.287h-1.852v11.116ZM87.436 94.403h1.852V83.287h-1.852v11.116ZM81.873 94.397h1.852V83.281h-1.852v11.116ZM76.315 94.397h1.852V83.281h-1.852v11.116Z"
      />
      <path
        fill="#883A62"
        d="M100.419 82.355H48.488v6.491h51.931v-6.491Z"
        opacity={0.3}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path
        fill="#FFF8DA"
        d="M51.215 72.18c6.137 0 11.112-4.975 11.112-11.112 0-6.137-4.975-11.112-11.112-11.112-6.137 0-11.112 4.975-11.112 11.112 0 6.137 4.975 11.112 11.112 11.112Z"
      />
      <path
        fill="#ABA2CA"
        d="M98.129 72.18c6.136 0 11.111-4.975 11.111-11.112 0-6.137-4.975-11.112-11.111-11.112-6.137 0-11.112 4.975-11.112 11.112 0 6.137 4.975 11.112 11.112 11.112Z"
      />
      <path
        fill="#2C1F41"
        d="M51.215 67.24a6.172 6.172 0 1 0 0-12.345 6.172 6.172 0 0 0 0 12.345Z"
      />
      <path
        fill="#172027"
        d="M98.129 67.24a6.173 6.173 0 1 0-.001-12.345 6.173 6.173 0 0 0 0 12.345Z"
      />
      <path
        fill="#4A3022"
        d="m73.985 79.198-14.628-8.776a15.876 15.876 0 0 1 14.068 1.127l.56.335"
      />
      <path
        fill="#021213"
        d="m74.051 79.181 14.628-8.777a15.875 15.875 0 0 0-14.07 1.127l-.558.335"
      />
      <path
        fill="#AF3271"
        d="m74.145 30.37-.051-5.233a2.308 2.308 0 0 0 2.143-1.451l4.307-10.768h2.75c6.476 0 9.894 7.673 5.56 12.495l-4.47 4.957H74.145Z"
      />
      <path
        fill="#E9632A"
        d="M74.165 20.635a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path
        fill="#E9632A"
        d="M74.165 25.173a2.27 2.27 0 1 0 0-4.539 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path
        fill="#DE2F7A"
        d="M74.378 25.137h-.183a2.309 2.309 0 0 1-2.143-1.451l-4.307-10.771h-2.749c-6.477 0-9.894 7.673-5.56 12.495l4.47 4.967h10.24"
      />
      <path fill="#4F4F8E" d="M74.494 30.37H63.905v4.537h10.589V30.37Z" />
      <path fill="#532D5C" d="M74.144 34.907h10.24V30.37h-10.24v4.537Z" />
      <path
        fill="#EB6329"
        d="M74.203 16.058a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path fill="#C84626" d="M74.203 16.134a2.269 2.269 0 1 1 0 4.54" />
      <path fill="#C84626" d="M74.203 20.596a2.27 2.27 0 1 1 0 4.538" />
      <path
        fill="#E8582C"
        d="M74.145 30.37h-10.24v.698h10.24v-.698ZM74.145 34.56h-10.24v.697h10.24v-.698Z"
      />
      <path
        fill="#F4ACC5"
        d="M74.145 31.03h-10.24v.388h10.24v-.388ZM74.145 34.171h-10.24v.388h10.24v-.388ZM74.145 32.114h-.698v1.397h.698v-1.397Z"
      />
      <path
        fill="#E9632A"
        d="m58.374 17.024-.108.161 13.615 9.076.108-.161-13.615-9.076ZM57.659 20.856l-.07.18 13.614 5.238.07-.181-13.614-5.237ZM58.682 23.987l-.035.191 11.52 2.094.035-.191-11.52-2.094Z"
      />
      <path
        fill="#C9481D"
        d="M74.144 31.068h10.24v-.698h-10.24v.698ZM74.144 35.256h10.24v-.698h-10.24v.698Z"
      />
      <path
        fill="#F28DB2"
        d="M74.144 31.416h10.24v-.388h-10.24v.388ZM74.144 34.56h10.24v-.389h-10.24v.388ZM74.144 33.513h.698v-1.397h-.698v1.397Z"
      />
      <path
        fill="#C84626"
        d="M89.917 17.024 76.303 26.1l.108.162 13.614-9.077-.108-.161ZM90.633 20.849l-13.615 5.236.07.182 13.614-5.237-.07-.181ZM89.606 23.985l-11.52 2.096.034.191 11.52-2.096-.034-.191ZM74.203 11.48a2.269 2.269 0 1 1 0 4.538"
      />
      <path
        fill="#EA6428"
        d="m79.388 7.855-5.184-5.184-5.184 5.184 5.184 5.184 5.184-5.184Z"
      />
      <path
        fill="#532D5A"
        d="m78.4 7.855-4.196-4.196-4.197 4.196 4.197 4.196L78.4 7.855Z"
      />
      <path
        fill="#BA3272"
        d="M74.203 10.123a2.27 2.27 0 1 0 0-4.539 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path fill="#BA3272" d="M74.379 0h-.35v7.679h.35v-7.68Z" />
      <path
        fill="#E9632A"
        d="m69.41 23.93-4.492-11.042h2.842l4.307 10.766a2.308 2.308 0 0 0 2.143 1.451l-.012 1.219h-1.19a3.874 3.874 0 0 1-3.597-2.393Z"
      />
      <path
        fill="#C84626"
        d="m78.954 23.92 4.494-11.042h-2.842l-4.307 10.766a2.31 2.31 0 0 1-2.145 1.451l.012 1.219h1.19a3.872 3.872 0 0 0 3.598-2.395Z"
      />
      <path fill="#F4ACC5" d="M74.03 26.18H69.49v1.397h4.538V26.18Z" />
      <path
        fill="#2D2044"
        d="M74.145 26.18v4.19a1.745 1.745 0 0 1-1.745-1.745v-.695a1.744 1.744 0 0 1 1.745-1.75Z"
      />
      <path fill="#4F4F8E" d="M74.144 26.878h-1.047v2.793h1.047v-2.793Z" />
      <path fill="#F28DB2" d="M74.261 27.578H78.8v-1.396h-4.538v1.396Z" />
      <path
        fill="#172027"
        d="M74.145 30.37v-4.19a1.745 1.745 0 0 1 1.745 1.745v.695a1.743 1.743 0 0 1-1.745 1.75Z"
      />
      <path fill="#532D5C" d="M74.107 29.671h1.047v-2.793h-1.047v2.793Z" />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace82;
