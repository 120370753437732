import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace532 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#FC532D" d="M150.675 0H0v150.675h150.675V0Z" />
      <path
        fill="#6C1908"
        d="M74.088 72.65a60.66 60.66 0 0 1 60.654 60.661v14.13H13.422v-14.13a60.66 60.66 0 0 1 60.666-60.66Z"
      />
      <path
        fill="#4A2955"
        d="M17.634 56.588v37.346l57.33 37.194v-74.54h-57.33Z"
      />
      <path
        fill="#161F26"
        d="M51.956 23.316h45.908a27.782 27.782 0 0 1 27.783 27.783v28.644H24.173V51.099a27.783 27.783 0 0 1 27.783-27.783Z"
      />
      <path
        fill="#FA8CB2"
        d="M74.911 31.1V93.8H56.502L26.59 74.395V53.261c0-12.24 15.288-22.16 34.157-22.16h14.165Z"
      />
      <path
        fill="#161F26"
        d="M131.349 56.73v37.347L74.018 131.27V56.73h57.331Z"
      />
      <path
        fill="#F46461"
        d="M74.073 31.227v62.706h18.408l29.913-19.404V53.387c0-12.239-15.288-22.16-34.156-22.16H74.073Z"
      />
      <path
        fill="#F26227"
        d="M20.827 78.892a9.002 9.002 0 1 0 0-18.004 9.002 9.002 0 0 0 0 18.004Z"
      />
      <path
        fill="#D74B27"
        d="M20.827 77.769a7.88 7.88 0 1 0 0-15.759 7.88 7.88 0 0 0 0 15.759Z"
      />
      <path
        fill="#F79421"
        d="M20.48 62.637h.709c.049.007.098.01.147.01.206.018.41.038.614.07a7.12 7.12 0 0 1 1.417.37 7.285 7.285 0 0 1 4.365 4.556 6.978 6.978 0 0 1 .335 1.851.218.218 0 0 0 .01.11v.588a.201.201 0 0 0-.01.11 7.042 7.042 0 0 1-.805 2.937 7.247 7.247 0 0 1-7.037 3.893 7.03 7.03 0 0 1-1.387-.25 7.213 7.213 0 0 1-4.033-2.91 7.216 7.216 0 0 1-1.228-3.47 1.837 1.837 0 0 0-.022-.254v-.696a.084.084 0 0 0 .01-.053c.016-.315.054-.628.112-.938a7.25 7.25 0 0 1 6.637-5.907 1.34 1.34 0 0 0 .166-.017Zm-5.702 7.257a6.053 6.053 0 1 0 3.732-5.598 6.045 6.045 0 0 0-3.732 5.598Z"
      />
      <path
        fill="#F79421"
        d="M22.652 69.68c.256.042.506.12.742.229a1.6 1.6 0 0 1 .716.636c.11.194.18.408.206.63.038.28.025.565-.037.842a1.478 1.478 0 0 1-.923 1.088c-.252.1-.515.17-.784.207-.18.028-.361.048-.545.059-.063 0-.065 0-.065.07v1.078c0 .067 0 .067-.069.067h-.786c-.077 0-.072 0-.072-.07v-1.042c0-.087.007-.078-.08-.078h-.785c-.063 0-.063 0-.063.064v1.05c0 .08.007.076-.073.076h-.782c-.074 0-.075 0-.075-.076v-1.078c0-.025-.01-.04-.036-.036-.026.003-.038 0-.056 0h-1.717c-.03 0-.045 0-.045-.043v-.772c0-.037.015-.047.05-.045h.48c.098-.002.197-.015.293-.038a.259.259 0 0 0 .21-.214 1.62 1.62 0 0 0 .038-.386V67.86a1.69 1.69 0 0 0-.035-.365.276.276 0 0 0-.232-.228 1.88 1.88 0 0 0-.373-.034h-.362c-.063 0-.063 0-.063-.066v-.716c0-.082 0-.076.073-.076h1.703c.076 0 .076 0 .076-.075v-1.07c0-.02.013-.037.038-.035h.815c.082 0 .075-.01.075.073v1.022c0 .095 0 .085.085.085h.779c.063 0 .063 0 .063-.068V65.23c0-.016.01-.038.037-.037h.814c.081 0 .077-.012.077.08v1.062c0 .053 0 .052.054.056.28.019.557.064.828.134.152.04.3.094.44.163a1.25 1.25 0 0 1 .684.823c.09.32.096.659.016.982a1.403 1.403 0 0 1-.566.816c-.221.153-.466.27-.724.344-.02.006-.036.001-.044.026Zm-2.545 1.681v1.126c0 .06 0 .06.06.06h.407a5.9 5.9 0 0 0 .868-.04 2.09 2.09 0 0 0 .497-.127.857.857 0 0 0 .514-.565c.08-.262.093-.541.035-.81a.85.85 0 0 0-.379-.574 1.22 1.22 0 0 0-.366-.147 3.182 3.182 0 0 0-.625-.089c-.323-.019-.648 0-.973-.01-.034 0-.045.013-.042.044.003.031 0 .033 0 .049l.004 1.083Zm0-3.087v1c0 .034.007.051.046.05.35.01.7 0 1.048-.03.17-.019.338-.06.498-.122a.754.754 0 0 0 .467-.54c.054-.213.062-.436.023-.653a.735.735 0 0 0-.518-.62 2.457 2.457 0 0 0-.682-.121c-.273-.013-.547 0-.82 0h-.035a.03.03 0 0 0-.025.009.028.028 0 0 0-.007.011.029.029 0 0 0 0 .014v.055c.002.319.004.635.004.947h.001Z"
      />
      <path
        fill="#F26227"
        d="M131.959 79.33a9.002 9.002 0 0 0 9.002-9.002 9.002 9.002 0 1 0-18.004 0 9.002 9.002 0 0 0 9.002 9.002Z"
      />
      <path
        fill="#D74B27"
        d="M131.959 78.207a7.88 7.88 0 1 0 0-15.76 7.88 7.88 0 0 0 0 15.76Z"
      />
      <path
        fill="#F79421"
        d="M131.612 63.073h.709a.76.76 0 0 0 .147.01c.206.018.41.04.614.071.483.075.957.199 1.414.37a7.276 7.276 0 0 1 4.365 4.558 7.026 7.026 0 0 1 .335 1.85.223.223 0 0 0 .01.112v.588a.213.213 0 0 0-.01.112 7.03 7.03 0 0 1-.806 2.936 7.262 7.262 0 0 1-8.423 3.643 7.218 7.218 0 0 1-4.033-2.91 7.225 7.225 0 0 1-1.228-3.47 1.827 1.827 0 0 0-.022-.255V69.999a.086.086 0 0 0 .01-.055c.016-.314.054-.627.112-.936a7.088 7.088 0 0 1 .601-1.828 7.282 7.282 0 0 1 4.769-3.88c.416-.104.84-.17 1.267-.2.057-.005.114-.013.169-.027Zm-5.702 7.258a6.052 6.052 0 1 0 12.105-.004 6.052 6.052 0 0 0-12.105.004Z"
      />
      <path
        fill="#F79421"
        d="M133.784 70.116c.254.043.501.12.735.23a1.6 1.6 0 0 1 .716.637c.111.194.181.408.205.63.038.28.026.565-.036.842a1.474 1.474 0 0 1-.923 1.086c-.252.1-.516.17-.784.207a6.143 6.143 0 0 1-.545.06c-.064 0-.065 0-.065.07v1.077c0 .068 0 .07-.069.07h-.787c-.076 0-.072 0-.072-.073V73.91c0-.087 0-.078-.079-.078h-.785c-.063 0-.063 0-.063.066v1.05c0 .08.007.076-.074.076h-.774c-.074 0-.075 0-.075-.078V73.87c0-.025-.009-.038-.036-.037h-1.772c-.031 0-.046-.007-.046-.041v-.772c0-.038.015-.047.05-.047h.479c.099-.002.198-.015.294-.038a.256.256 0 0 0 .21-.213c.029-.127.041-.256.039-.385v-4.038a1.73 1.73 0 0 0-.036-.366.273.273 0 0 0-.232-.228 1.877 1.877 0 0 0-.373-.034h-.362c-.063 0-.063 0-.063-.066v-.716c0-.081 0-.077.073-.077h1.703c.076 0 .076 0 .076-.073v-1.07c0-.018.013-.038.038-.037h.815c.082 0 .075-.007.075.073v1.022c0 .096-.008.085.085.085h.779c.063 0 .063 0 .063-.066v-1.077c0-.016.011-.038.037-.037h.814c.081 0 .077-.01.077.08v1.065c0 .053 0 .053.054.056.28.02.557.065.828.135.152.04.299.094.441.162a1.243 1.243 0 0 1 .683.823c.09.321.096.66.017.984a1.397 1.397 0 0 1-.566.814c-.222.153-.466.27-.725.344-.019.001-.035-.001-.044.022Zm-2.545 1.682v1.126c0 .06 0 .06.06.06h.408c.289.007.579-.007.867-.043.17-.02.337-.063.497-.125a.862.862 0 0 0 .514-.566c.081-.262.093-.54.036-.81a.862.862 0 0 0-.38-.574 1.22 1.22 0 0 0-.366-.147 3.417 3.417 0 0 0-.624-.088c-.324-.02-.649 0-.974-.01-.033 0-.045.012-.042.045.003.032 0 .032 0 .048.003.362.003.722.004 1.084Zm0-3.087v1.001c0 .032.008.05.046.048.349.01.699 0 1.048-.03.171-.018.338-.06.498-.121a.75.75 0 0 0 .468-.538c.053-.215.061-.438.022-.656a.74.74 0 0 0-.518-.62 2.444 2.444 0 0 0-.682-.12c-.273-.014-.547 0-.82-.007h-.035c-.022 0-.034.01-.033.034v.056l.005.953h.001Z"
      />
      <path
        fill="#172027"
        d="m103.468 34.278 10.188-10.188a1.102 1.102 0 0 1 1.861.563 4.073 4.073 0 0 0 3.999 3.277 4.073 4.073 0 0 0 4.073-4.074v-4.738a4.16 4.16 0 0 0-6.183-3.638l-1.595.882a.965.965 0 0 1-1.421-1.004l.198-1.19a2.726 2.726 0 0 0-1.548-2.932 2.739 2.739 0 0 0-2.798.299c-.475.358-.82.861-.983 1.433l-.915 3.188a1.081 1.081 0 0 1-1.935.294l-1.571-2.352a2.034 2.034 0 0 0-1.482-.887 2.023 2.023 0 0 0-2.221 2.011 2.02 2.02 0 0 0 .594 1.431l1.837 1.838a1.62 1.62 0 0 1-.011 2.309c-.206.2-.462.342-.741.41a1.62 1.62 0 0 1-1.932-1.06L99.831 17a2.598 2.598 0 0 0-2.465-1.778 2.6 2.6 0 0 0-2.263 3.889l.58 1.012a1.677 1.677 0 1 1-3.093 1.192l-.896-4.024a5.35 5.35 0 0 0-10.413-.137L77 34.278"
      />
      <path
        fill="#542F5F"
        d="M48.58 34.021 38.39 23.833a1.102 1.102 0 0 0-1.859.569 4.072 4.072 0 0 1-8.066-.799v-4.742a4.162 4.162 0 0 1 6.183-3.636l1.593.882a.966.966 0 0 0 1.422-1.003l-.197-1.19a2.734 2.734 0 0 1 4.345-2.631c.475.358.82.86.984 1.432l.91 3.184a1.079 1.079 0 0 0 1.934.294l1.572-2.352a2.023 2.023 0 1 1 3.115 2.554l-1.84 1.833a1.618 1.618 0 0 0 .755 2.72 1.617 1.617 0 0 0 1.931-1.06l1.05-3.15a2.598 2.598 0 0 1 2.466-1.772 2.6 2.6 0 0 1 2.258 3.888l-.579 1.013a1.676 1.676 0 0 0 2.505 2.14c.298-.238.505-.571.588-.944l.894-4.024a5.351 5.351 0 0 1 5.221-4.192 5.35 5.35 0 0 1 5.19 4.058l4.282 17.12"
      />
      <path
        fill="#BA3D11"
        d="M46.999 94.743a43.897 43.897 0 0 0 25.173 7.938h2.034v-7.938"
      />
      <path
        fill="#A31905"
        d="M101.58 94.702a43.901 43.901 0 0 1-25.175 7.938h-2.033v-7.938"
      />
      <path
        fill="#6C1908"
        d="m74.206 90.208-3.402-3.4-24.94 7.935h28.342v-4.535Z"
      />
      <path
        fill="#3E1B11"
        d="m74.372 90.167 3.401-3.4 24.94 7.935H74.372v-4.535Z"
      />
      <path
        fill="#3D2F39"
        d="m102.258 52.51-13.733 4.975a7.528 7.528 0 0 0-4.515 9.641l.312.861a7.528 7.528 0 0 0 9.642 4.515l13.733-4.974a7.528 7.528 0 0 0 4.514-9.642l-.312-.86a7.527 7.527 0 0 0-9.641-4.515Z"
      />
      <path
        fill="#EB6447"
        d="M94.895 70.51a6.844 6.844 0 1 0 0-13.689 6.844 6.844 0 0 0 0 13.689Z"
      />
      <path
        fill="#3D2F39"
        d="m93.01 58.287-.12.044c-.56.202-.848.819-.646 1.378l3.152 8.703c.203.559.82.848 1.378.645l.122-.044c.559-.202.848-.82.645-1.378l-3.152-8.703a1.076 1.076 0 0 0-1.378-.645Z"
      />
      <path
        fill="#80191B"
        d="m40.48 67.746 13.734 4.974a7.528 7.528 0 0 0 9.641-4.515l.312-.861a7.528 7.528 0 0 0-4.514-9.641L45.92 52.728a7.528 7.528 0 0 0-9.642 4.514l-.312.861a7.528 7.528 0 0 0 4.515 9.642Z"
      />
      <path
        fill="#EB6447"
        d="M53.29 70.733a6.844 6.844 0 1 0 0-13.689 6.844 6.844 0 0 0 0 13.69Z"
      />
      <path
        fill="#3D2F39"
        d="m51.281 69.23.122.045a1.076 1.076 0 0 0 1.378-.645l3.152-8.704a1.076 1.076 0 0 0-.645-1.378l-.122-.044a1.076 1.076 0 0 0-1.378.645l-3.152 8.704a1.076 1.076 0 0 0 .645 1.378Z"
      />
      <path
        fill="#883A62"
        d="M75.792 53.034a16.417 16.417 0 0 1 16.417 16.417v18.534H59.376V69.451a16.417 16.417 0 0 1 16.416-16.417Z"
        opacity={0.7}
      />
      <path
        fill="#FA8CB2"
        d="M68.667 79.453h8.773v-14.71h-6.065a3.593 3.593 0 0 0-3.381 2.377l-2.708 7.522a3.594 3.594 0 0 0 3.381 4.811Z"
      />
      <path
        fill="#542F5F"
        d="M70.556 74.276a2.648 2.648 0 1 0 0-5.295 2.648 2.648 0 0 0 0 5.295Z"
      />
      <path
        fill="#F46461"
        d="M82.447 79.512h-6.714v-14.71h4.006a3.595 3.595 0 0 1 3.381 2.377l2.708 7.522a3.595 3.595 0 0 1-3.381 4.81Z"
      />
      <path
        fill="#172027"
        d="M80.558 74.17a2.647 2.647 0 1 0 0-5.295 2.647 2.647 0 0 0 0 5.295Z"
      />
      <path
        fill="#FFA520"
        d="M103.587 18.522H74.589l1.877 1.502a20.364 20.364 0 0 0 27.121-1.502Z"
      />
      <path
        fill="#B98D22"
        d="M103.463 18.522H74.466l1.877-1.503a20.364 20.364 0 0 1 27.12 1.503Z"
      />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace532;
