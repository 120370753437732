import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace105 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#EFE2DB" d="M150.675 0H0v150.675h150.675V0Z" />
      <path
        fill="#6C1908"
        d="M74.088 72.65a60.661 60.661 0 0 1 60.654 60.661v14.13H13.421v-14.13a60.659 60.659 0 0 1 60.667-60.66Z"
      />
      <path
        fill="#FFA520"
        d="M74.162 124.491H62.497l-48.216-74.41c-5.614-8.66.601-20.094 10.922-20.094h48.959v94.504Z"
      />
      <path
        fill="#F96020"
        d="M74.154 124.509H85.82l48.216-74.412c5.611-8.658-.604-20.092-10.922-20.092H74.154v94.504Z"
      />
      <path
        fill="#F26227"
        d="M20.827 78.892a9.002 9.002 0 1 0 0-18.005 9.002 9.002 0 0 0 0 18.005Z"
      />
      <path
        fill="#D74B27"
        d="M20.827 77.768a7.88 7.88 0 1 0 0-15.758 7.88 7.88 0 0 0 0 15.758Z"
      />
      <path
        fill="#F79421"
        d="M20.48 62.636h.709c.048.008.097.011.146.01.206.018.41.039.615.071.484.074.959.198 1.417.37a7.284 7.284 0 0 1 4.364 4.556 6.971 6.971 0 0 1 .336 1.85.218.218 0 0 0 .01.111v.588a.201.201 0 0 0-.01.11 7.04 7.04 0 0 1-.806 2.937 7.247 7.247 0 0 1-7.037 3.893 7.03 7.03 0 0 1-1.386-.25 7.213 7.213 0 0 1-4.034-2.91 7.215 7.215 0 0 1-1.227-3.47 1.836 1.836 0 0 0-.022-.254V69.551a.083.083 0 0 0 .01-.053c.016-.315.053-.628.112-.938a7.25 7.25 0 0 1 6.637-5.907c.056-.001.111-.007.166-.017Zm-5.702 7.258a6.052 6.052 0 1 0 3.731-5.598 6.045 6.045 0 0 0-3.731 5.598Z"
      />
      <path
        fill="#F79421"
        d="M22.651 69.68c.257.042.506.119.742.228a1.6 1.6 0 0 1 .716.637c.111.193.181.408.206.63.038.28.025.565-.037.841a1.478 1.478 0 0 1-.923 1.088c-.252.1-.515.17-.783.208-.181.028-.362.048-.546.058-.063 0-.064 0-.064.07v1.078c0 .068 0 .068-.07.068h-.786c-.076 0-.072 0-.072-.07v-1.043c0-.087.007-.078-.08-.078h-.784c-.064 0-.064 0-.064.065v1.05c0 .08.008.076-.073.076h-.782c-.074 0-.075 0-.075-.076v-1.078c0-.025-.009-.04-.035-.037-.027.003-.038 0-.056 0h-1.717c-.031 0-.046 0-.046-.042v-.772c0-.037.015-.047.05-.046h.48c.099-.002.197-.014.293-.038a.26.26 0 0 0 .21-.213c.029-.127.042-.257.039-.387V67.86a1.697 1.697 0 0 0-.035-.364.277.277 0 0 0-.233-.228 1.879 1.879 0 0 0-.373-.034h-.362c-.063 0-.063 0-.063-.066v-.716c0-.082 0-.076.074-.076h1.702c.076 0 .076 0 .076-.075V65.23c0-.02.014-.037.039-.036h.814c.082 0 .075-.009.075.074v1.021c0 .096 0 .086.085.086h.78c.063 0 .063 0 .063-.068V65.229c0-.016.01-.038.036-.036h.815c.08 0 .076-.012.076.08v1.062c0 .053 0 .051.054.056.28.018.557.063.828.134.152.04.3.094.441.163a1.25 1.25 0 0 1 .684.823c.09.32.095.659.016.982a1.403 1.403 0 0 1-.566.816c-.222.153-.466.269-.725.344-.019.006-.035.001-.044.026Zm-2.544 1.68v1.127c0 .06 0 .06.06.06h.407a5.9 5.9 0 0 0 .867-.041 2.09 2.09 0 0 0 .497-.126.856.856 0 0 0 .515-.565c.08-.263.092-.541.035-.81a.85.85 0 0 0-.38-.575 1.22 1.22 0 0 0-.365-.147 3.182 3.182 0 0 0-.625-.088c-.323-.019-.648 0-.973-.01-.034 0-.046.013-.043.044.003.03 0 .032 0 .049l.005 1.083Zm0-3.086v1c0 .033.007.05.045.05.35.01.7 0 1.048-.03.17-.02.338-.06.498-.122a.753.753 0 0 0 .468-.54c.054-.214.061-.436.022-.654a.735.735 0 0 0-.517-.62 2.454 2.454 0 0 0-.683-.12c-.273-.014-.546 0-.82 0h-.035a.03.03 0 0 0-.025.008.03.03 0 0 0-.007.025v.056c.003.319.004.634.004.947h.002Z"
      />
      <path
        fill="#F26227"
        d="M131.959 79.33a9.002 9.002 0 0 0 9.002-9.003 9.002 9.002 0 1 0-18.004 0 9.002 9.002 0 0 0 9.002 9.003Z"
      />
      <path
        fill="#D74B27"
        d="M131.959 78.207a7.88 7.88 0 1 0 0-15.76 7.88 7.88 0 0 0 0 15.76Z"
      />
      <path
        fill="#F79421"
        d="M131.612 63.073h.708a.766.766 0 0 0 .147.01 7.118 7.118 0 0 1 2.029.441 7.283 7.283 0 0 1 4.365 4.557 7.102 7.102 0 0 1 .335 1.851.223.223 0 0 0 .01.112v.588a.203.203 0 0 0-.01.111 7.029 7.029 0 0 1-.806 2.936 7.262 7.262 0 0 1-8.423 3.644 7.223 7.223 0 0 1-4.034-2.91 7.233 7.233 0 0 1-1.227-3.47 1.993 1.993 0 0 0-.022-.255V69.999a.093.093 0 0 0 .01-.055c.016-.315.053-.627.112-.937a7.268 7.268 0 0 1 6.637-5.908c.057-.004.113-.013.169-.026Zm-5.702 7.257a6.046 6.046 0 0 0 7.234 5.934 6.054 6.054 0 0 0 4.754-4.757 6.049 6.049 0 0 0-2.576-6.212 6.053 6.053 0 0 0-9.412 5.035Z"
      />
      <path
        fill="#F79421"
        d="M133.783 70.116c.254.043.501.12.735.229.299.134.548.357.716.638.111.193.181.407.206.63.038.28.025.565-.037.841a1.474 1.474 0 0 1-.923 1.087c-.252.1-.515.17-.784.207-.18.028-.361.049-.545.06-.063 0-.065 0-.065.07v1.077c0 .068 0 .069-.069.069h-.786c-.077 0-.072 0-.072-.072V73.91c0-.087 0-.078-.08-.078h-.785c-.063 0-.063 0-.063.066v1.05c0 .079.008.076-.073.076h-.775c-.074 0-.075 0-.075-.078V73.87c0-.025-.009-.039-.035-.037H128.5c-.031 0-.046-.007-.046-.041v-.772c0-.038.015-.047.05-.047h.48a1.38 1.38 0 0 0 .294-.038.263.263 0 0 0 .139-.073.266.266 0 0 0 .071-.14c.028-.127.041-.256.038-.386v-4.038a1.73 1.73 0 0 0-.035-.366.284.284 0 0 0-.079-.151.28.28 0 0 0-.154-.077 1.877 1.877 0 0 0-.373-.033h-.362c-.063 0-.063 0-.063-.067v-.715c0-.081 0-.077.074-.077h1.702c.076 0 .076 0 .076-.073v-1.07c0-.018.014-.039.039-.037h.814c.082 0 .075-.008.075.073v1.022c0 .095-.007.085.085.085h.779c.064 0 .064 0 .064-.066v-1.077c0-.017.01-.039.036-.037h.815c.081 0 .076-.01.076.08v1.065c0 .053 0 .053.055.056.279.019.556.064.827.135.152.04.3.094.441.162a1.243 1.243 0 0 1 .684.823c.09.32.095.66.016.983a1.397 1.397 0 0 1-.566.815c-.221.153-.466.27-.725.344-.019.001-.035-.002-.044.022Zm-2.544 1.681v1.126c0 .06 0 .06.06.06h.407c.29.008.58-.007.867-.042.17-.021.337-.063.497-.125a.858.858 0 0 0 .515-.566c.08-.263.092-.541.035-.81a.862.862 0 0 0-.379-.575 1.23 1.23 0 0 0-.366-.147 3.428 3.428 0 0 0-.625-.088c-.324-.019-.648 0-.973-.01-.034 0-.046.013-.043.045.003.033 0 .033 0 .049.003.362.003.722.005 1.083Zm0-3.087v1.001c0 .033.007.05.045.049.35.01.7 0 1.048-.03.171-.019.338-.06.499-.121a.747.747 0 0 0 .467-.538c.054-.215.061-.439.022-.656a.733.733 0 0 0-.517-.62 2.457 2.457 0 0 0-.682-.12c-.274-.014-.547 0-.821-.007h-.035c-.022 0-.034.01-.032.034v.056l.004.952h.002Z"
      />
      <path
        fill="#542F5F"
        d="M44.979 33.81v9.407a3.335 3.335 0 0 1-3.328 3.324h-3.065c-1.896 0-3.773.375-5.523 1.104a12.804 12.804 0 0 1-16.484-6.32l-.653-1.36A12.727 12.727 0 0 1 30.7 22.197l13.12 3.5H27.608a5.79 5.79 0 1 0 0 11.581h.713a5.793 5.793 0 0 0 2.98-.822l5.99-3.595a6.292 6.292 0 0 1 7.689.947v.002Z"
      />
      <path
        fill="#111C0D"
        d="M106.278 33.833v9.408a3.331 3.331 0 0 0 3.334 3.334h3.059c1.895 0 3.772.375 5.521 1.104a12.806 12.806 0 0 0 16.486-6.314l.65-1.365a12.732 12.732 0 0 0 .417-9.965 12.732 12.732 0 0 0-15.187-7.804l-13.123 3.494h16.214a5.785 5.785 0 0 1 4.095 1.696 5.787 5.787 0 0 1 1.255 6.312 5.786 5.786 0 0 1-5.35 3.574h-.707a5.797 5.797 0 0 1-2.98-.825l-5.993-3.596a6.29 6.29 0 0 0-7.687.947h-.004Z"
      />
      <path fill="#FA8CB2" d="M78.161 81.429H46.665V96.25h31.496V81.429Z" />
      <path fill="#EFE1DA" d="M78.163 83.28H50.37v11.117h27.792V83.28Z" />
      <path
        fill="#001A1B"
        d="M55.929 83.28h-1.852v11.117h1.852V83.28ZM61.487 83.28h-1.852v11.117h1.852V83.28ZM67.05 83.275h-1.853V94.39h1.852V83.275ZM72.608 83.275h-1.853V94.39h1.853V83.275Z"
      />
      <path fill="#F46461" d="M73.526 96.255h28.732V81.433H73.526v14.822Z" />
      <path fill="#EFE1DA" d="M72.599 94.403h25.954V83.287H72.599v11.116Z" />
      <path
        fill="#001A1B"
        d="M92.994 94.403h1.852V83.287h-1.852v11.116ZM87.436 94.403h1.852V83.287h-1.852v11.116ZM81.873 94.397h1.852V83.281h-1.852v11.116ZM76.315 94.397h1.852V83.281h-1.852v11.116Z"
      />
      <path
        fill="#883A62"
        d="M100.418 82.355h-51.93v6.491h51.93v-6.491Z"
        opacity={0.3}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path
        stroke="#F96020"
        strokeMiterlimit={10}
        strokeWidth={3.61}
        d="M58.237 67.129h30.48"
      />
      <path
        fill="#F96020"
        d="M53.858 83.79c5.536 0 10.024-4.488 10.024-10.024 0-5.536-4.488-10.024-10.024-10.024-5.536 0-10.024 4.488-10.024 10.024 0 5.536 4.488 10.024 10.024 10.024Z"
      />
      <path
        fill="#D74816"
        d="M53.856 82.539a8.773 8.773 0 1 0 0-17.546 8.773 8.773 0 0 0 0 17.546Z"
      />
      <path
        fill="#F96020"
        d="M95.628 84.062c5.536 0 10.024-4.488 10.024-10.024 0-5.536-4.488-10.024-10.024-10.024-5.536 0-10.024 4.488-10.024 10.024 0 5.536 4.488 10.024 10.024 10.024Z"
      />
      <path
        fill="#D74816"
        d="M95.628 82.81a8.773 8.773 0 1 0 0-17.546 8.773 8.773 0 0 0 0 17.547Z"
      />
      <path
        fill="#FFA520"
        d="m45.864 77.06 4.059 4.06 11.062-11.062-4.06-4.06-11.061 11.063ZM88.548 77.746l4.06 4.059 11.061-11.062-4.06-4.06-11.06 11.063Z"
      />
      <path
        fill="#3D2F39"
        d="m102.257 52.51-13.732 4.975a7.528 7.528 0 0 0-4.515 9.641l.312.861a7.528 7.528 0 0 0 9.642 4.515l13.732-4.974a7.527 7.527 0 0 0 4.515-9.642l-.312-.86a7.528 7.528 0 0 0-9.642-4.515Z"
      />
      <path
        fill="#EB6447"
        d="M94.894 70.51a6.845 6.845 0 1 0 0-13.69 6.845 6.845 0 0 0 0 13.69Z"
      />
      <path
        fill="#3D2F39"
        d="m93.01 58.287-.12.044c-.56.202-.848.819-.646 1.378l3.152 8.703c.203.559.82.848 1.378.645l.122-.044c.559-.202.848-.82.645-1.378l-3.152-8.703a1.076 1.076 0 0 0-1.378-.645Z"
      />
      <path
        fill="#80191B"
        d="m40.48 67.745 13.733 4.974a7.528 7.528 0 0 0 9.642-4.514l.312-.861a7.528 7.528 0 0 0-4.515-9.641L45.92 52.727a7.528 7.528 0 0 0-9.641 4.515l-.312.86a7.528 7.528 0 0 0 4.514 9.642Z"
      />
      <path
        fill="#EB6447"
        d="M53.289 70.733a6.844 6.844 0 1 0 0-13.689 6.844 6.844 0 0 0 0 13.689Z"
      />
      <path
        fill="#3D2F39"
        d="m51.28 69.23.122.045a1.076 1.076 0 0 0 1.379-.646l3.152-8.703a1.076 1.076 0 0 0-.645-1.378l-.122-.044a1.076 1.076 0 0 0-1.378.645l-3.152 8.704a1.076 1.076 0 0 0 .645 1.378Z"
      />
      <path
        fill="#4D2F47"
        d="M79.997 79.674a5.5 5.5 0 0 1-5.5-5.501v-9.849h6.132a5.803 5.803 0 0 1 5.653 7.125l-.928 3.97a5.502 5.502 0 0 1-5.357 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M79.925 77.88a3.637 3.637 0 1 0 0-7.273 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M79.875 76.984a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path
        fill="#883A62"
        d="M69.015 79.63a5.5 5.5 0 0 0 5.5-5.501V64.28h-6.133a5.803 5.803 0 0 0-5.653 7.125l.93 3.97a5.5 5.5 0 0 0 5.356 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M69.084 77.836a3.637 3.637 0 1 0 0-7.273 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M69.134 76.94a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path
        fill="#AF3271"
        d="m74.145 30.37-.051-5.233a2.308 2.308 0 0 0 2.143-1.451l4.307-10.768h2.75c6.476 0 9.894 7.673 5.56 12.495l-4.47 4.957H74.145Z"
      />
      <path
        fill="#E9632A"
        d="M74.165 20.635a2.27 2.27 0 1 0 0-4.539 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path
        fill="#E9632A"
        d="M74.165 25.173a2.27 2.27 0 1 0 0-4.539 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path
        fill="#DE2F7A"
        d="M74.377 25.137h-.182a2.308 2.308 0 0 1-2.143-1.451l-4.307-10.771h-2.749c-6.477 0-9.895 7.673-5.561 12.495l4.47 4.967h10.24"
      />
      <path fill="#4F4F8E" d="M74.494 30.37H63.905v4.538h10.589V30.37Z" />
      <path fill="#532D5C" d="M74.144 34.907h10.24V30.37h-10.24v4.537Z" />
      <path
        fill="#EB6329"
        d="M74.203 16.058a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path fill="#C84626" d="M74.203 16.134a2.27 2.27 0 0 1 0 4.54" />
      <path fill="#C84626" d="M74.203 20.596a2.27 2.27 0 1 1 0 4.538" />
      <path
        fill="#E8582C"
        d="M74.145 30.37h-10.24v.698h10.24v-.698ZM74.145 34.56h-10.24v.697h10.24v-.698Z"
      />
      <path
        fill="#F4ACC5"
        d="M74.145 31.03h-10.24v.388h10.24v-.388ZM74.145 34.171h-10.24v.388h10.24v-.388ZM74.145 32.114h-.698v1.397h.698v-1.397Z"
      />
      <path
        fill="#E9632A"
        d="m58.374 17.024-.108.161 13.615 9.076.107-.161-13.614-9.076ZM57.659 20.856l-.07.18 13.614 5.238.07-.181-13.614-5.237ZM58.681 23.987l-.034.191 11.52 2.094.035-.191-11.52-2.094Z"
      />
      <path
        fill="#C9481D"
        d="M74.144 31.068h10.24v-.698h-10.24v.698ZM74.144 35.256h10.24v-.698h-10.24v.698Z"
      />
      <path
        fill="#F28DB2"
        d="M74.144 31.416h10.24v-.388h-10.24v.388ZM74.144 34.56h10.24v-.389h-10.24v.388ZM74.144 33.513h.698v-1.397h-.698v1.397Z"
      />
      <path
        fill="#C84626"
        d="M89.917 17.024 76.303 26.1l.107.162 13.615-9.077-.108-.161ZM90.633 20.849l-13.615 5.236.07.182 13.614-5.237-.07-.181ZM89.606 23.985l-11.52 2.096.034.191 11.52-2.096-.034-.191ZM74.203 11.48a2.27 2.27 0 1 1 0 4.538"
      />
      <path
        fill="#EA6428"
        d="m79.387 7.855-5.183-5.184-5.184 5.184 5.184 5.184 5.183-5.184Z"
      />
      <path
        fill="#532D5A"
        d="m78.4 7.855-4.196-4.196-4.197 4.196 4.197 4.196L78.4 7.855Z"
      />
      <path
        fill="#BA3272"
        d="M74.203 10.123a2.27 2.27 0 1 0 0-4.539 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path fill="#BA3272" d="M74.379 0h-.35v7.679h.35v-7.68Z" />
      <path
        fill="#E9632A"
        d="m69.41 23.93-4.492-11.042h2.842l4.307 10.767a2.308 2.308 0 0 0 2.143 1.45l-.012 1.22h-1.19a3.875 3.875 0 0 1-3.597-2.394Z"
      />
      <path
        fill="#C84626"
        d="m78.954 23.92 4.493-11.042h-2.841l-4.307 10.766a2.31 2.31 0 0 1-2.145 1.451l.012 1.219h1.19a3.872 3.872 0 0 0 3.598-2.395Z"
      />
      <path fill="#F4ACC5" d="M74.03 26.18H69.49v1.397h4.538V26.18Z" />
      <path
        fill="#2D2044"
        d="M74.145 26.18v4.19a1.745 1.745 0 0 1-1.745-1.745v-.695a1.745 1.745 0 0 1 1.745-1.75Z"
      />
      <path fill="#4F4F8E" d="M74.144 26.878h-1.047v2.793h1.047v-2.793Z" />
      <path fill="#F28DB2" d="M74.261 27.578H78.8v-1.396h-4.538v1.396Z" />
      <path
        fill="#172027"
        d="M74.145 30.37v-4.19a1.745 1.745 0 0 1 1.745 1.745v.695a1.747 1.747 0 0 1-1.745 1.75Z"
      />
      <path fill="#532D5C" d="M74.107 29.671h1.047v-2.793h-1.047v2.793Z" />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace105;
