import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace614 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#A9D4EB" d="M150.675 0H0v150.675h150.675V0Z" />
      <path
        fill="#111C0D"
        d="M28.981 27.074h90.655a28.982 28.982 0 0 1 28.981 28.981v91.658H0V56.055a28.981 28.981 0 0 1 28.981-28.98Z"
      />
      <path
        fill="#4A2955"
        d="M17.634 56.588v37.346l57.33 37.194v-74.54h-57.33Z"
      />
      <path
        fill="#161F26"
        d="M51.956 23.316h45.908a27.782 27.782 0 0 1 27.783 27.783v28.644H24.173V51.099a27.783 27.783 0 0 1 27.783-27.783Z"
      />
      <path
        fill="#FA8CB2"
        d="M74.911 31.1v62.7H56.502L26.59 74.394V53.261c0-12.24 15.288-22.16 34.157-22.16h14.165Z"
      />
      <path
        fill="#161F26"
        d="M131.349 56.73v37.347L74.018 131.27V56.73h57.331Z"
      />
      <path
        fill="#F46461"
        d="M74.073 31.227v62.706h18.408l29.913-19.404V53.388c0-12.24-15.288-22.16-34.156-22.16H74.073Z"
      />
      <path
        fill="#F26227"
        d="M20.827 78.892a9.002 9.002 0 1 0 0-18.005 9.002 9.002 0 0 0 0 18.005Z"
      />
      <path
        fill="#D74B27"
        d="M20.827 77.769a7.88 7.88 0 1 0 0-15.759 7.88 7.88 0 0 0 0 15.759Z"
      />
      <path
        fill="#F79421"
        d="M20.48 62.636h.709c.048.008.097.011.147.01.205.018.41.039.614.071.484.074.959.198 1.417.37a7.284 7.284 0 0 1 4.365 4.556 6.973 6.973 0 0 1 .335 1.851.217.217 0 0 0 .01.11v.588a.2.2 0 0 0-.01.11 7.037 7.037 0 0 1-.806 2.937 7.248 7.248 0 0 1-8.423 3.643 7.213 7.213 0 0 1-4.034-2.91 7.215 7.215 0 0 1-1.227-3.47 1.838 1.838 0 0 0-.022-.254V69.551a.084.084 0 0 0 .01-.053c.016-.314.053-.628.112-.937a7.25 7.25 0 0 1 6.637-5.906 1.34 1.34 0 0 0 .166-.019Zm-5.702 7.258a6.051 6.051 0 1 0 6.049-6.058 6.045 6.045 0 0 0-6.049 6.058Z"
      />
      <path
        fill="#F79421"
        d="M22.651 69.68c.257.042.507.119.743.229a1.6 1.6 0 0 1 .716.636c.11.194.18.408.205.63.038.28.026.565-.036.842a1.48 1.48 0 0 1-.924 1.087c-.252.1-.515.17-.783.208-.18.028-.362.048-.546.059-.063 0-.064 0-.064.07v1.078c0 .067 0 .067-.07.067h-.786c-.076 0-.072 0-.072-.07v-1.043c0-.086.008-.078-.08-.078h-.784c-.063 0-.063 0-.063.065v1.05c0 .08.007.076-.074.076h-.782c-.073 0-.075 0-.075-.076v-1.078c0-.025-.009-.04-.035-.037-.027.003-.038 0-.056 0h-1.717c-.03 0-.045 0-.045-.042v-.772c0-.037.014-.047.05-.046h.479c.099-.002.197-.014.294-.038a.259.259 0 0 0 .21-.213c.028-.127.041-.257.038-.387V67.86a1.69 1.69 0 0 0-.035-.364.277.277 0 0 0-.232-.228 1.879 1.879 0 0 0-.374-.034h-.361c-.064 0-.064 0-.064-.066v-.716c0-.082 0-.076.074-.076h1.702c.077 0 .077 0 .077-.075v-1.07c0-.02.013-.037.038-.036h.814c.082 0 .075-.008.075.074v1.022c0 .095 0 .085.085.085h.78c.063 0 .063 0 .063-.068V65.23c0-.017.01-.039.037-.037h.814c.08 0 .076-.012.076.08v1.062c0 .053 0 .052.055.056a4.5 4.5 0 0 1 .827.134c.152.04.3.094.441.163a1.25 1.25 0 0 1 .684.823c.09.32.095.659.016.982a1.403 1.403 0 0 1-.566.816 2.5 2.5 0 0 1-.725.344c-.019.006-.035.001-.044.026Zm-2.544 1.681v1.126c0 .06 0 .06.06.06h.407a5.9 5.9 0 0 0 .867-.04 2.09 2.09 0 0 0 .497-.127.857.857 0 0 0 .515-.565c.08-.262.092-.541.035-.81a.85.85 0 0 0-.38-.575 1.22 1.22 0 0 0-.365-.147 3.182 3.182 0 0 0-.625-.088c-.323-.019-.648 0-.973-.01-.034 0-.046.013-.043.044.003.031 0 .032 0 .049l.005 1.083Zm0-3.087v1c0 .033.007.051.045.05.35.01.7 0 1.048-.03.171-.019.338-.06.499-.122a.755.755 0 0 0 .467-.54c.054-.213.061-.436.022-.654a.735.735 0 0 0-.517-.62 2.458 2.458 0 0 0-.682-.12c-.274-.014-.547 0-.82 0h-.036a.03.03 0 0 0-.013.001.03.03 0 0 0-.019.032v.056c.003.319.004.634.004.947h.002Z"
      />
      <path
        fill="#F26227"
        d="M131.959 79.33a9.002 9.002 0 0 0 9.002-9.002 9.002 9.002 0 1 0-18.004 0 9.002 9.002 0 0 0 9.002 9.002Z"
      />
      <path
        fill="#D74B27"
        d="M131.959 78.207a7.88 7.88 0 1 0 0-15.759 7.88 7.88 0 0 0 0 15.759Z"
      />
      <path
        fill="#F79421"
        d="M131.612 63.073h.709a.754.754 0 0 0 .147.01c.205.018.41.04.614.071.483.075.957.199 1.414.37a7.284 7.284 0 0 1 4.365 4.557 7.026 7.026 0 0 1 .335 1.851.224.224 0 0 0 .01.112v.588a.203.203 0 0 0-.01.112c-.01.233-.034.466-.071.696a7.03 7.03 0 0 1-.735 2.24 7.262 7.262 0 0 1-8.423 3.643 7.223 7.223 0 0 1-4.034-2.91 7.234 7.234 0 0 1-1.227-3.47 1.827 1.827 0 0 0-.022-.255V69.999a.086.086 0 0 0 .01-.055c.016-.314.053-.627.112-.937a7.05 7.05 0 0 1 .601-1.827 7.277 7.277 0 0 1 4.769-3.88c.416-.104.84-.17 1.267-.2.057-.005.114-.014.169-.027Zm-5.702 7.258a6.052 6.052 0 1 0 12.104-.002 6.052 6.052 0 0 0-12.104.002Z"
      />
      <path
        fill="#F79421"
        d="M133.783 70.116c.254.043.502.12.735.23.299.133.549.356.716.637.111.194.181.408.206.63.038.28.025.565-.037.842a1.463 1.463 0 0 1-.923 1.086c-.252.1-.515.17-.784.207-.18.028-.361.049-.545.06-.063 0-.065 0-.065.07v1.077c0 .068 0 .07-.069.07h-.786c-.077 0-.072 0-.072-.073V73.91c0-.087 0-.078-.08-.078h-.784c-.064 0-.064 0-.064.066v1.05c0 .08.008.076-.073.076h-.775c-.073 0-.075 0-.075-.078V73.87c0-.025-.009-.038-.035-.037H128.5c-.031 0-.046-.007-.046-.041v-.772c0-.038.015-.047.05-.047h.48a1.38 1.38 0 0 0 .294-.038.263.263 0 0 0 .139-.073.266.266 0 0 0 .071-.14c.028-.127.041-.256.038-.385v-4.039a1.666 1.666 0 0 0-.035-.365.277.277 0 0 0-.232-.228 1.887 1.887 0 0 0-.374-.034h-.361c-.064 0-.064 0-.064-.066v-.716c0-.081 0-.077.074-.077h1.702c.077 0 .077 0 .077-.073v-1.07c0-.018.013-.039.038-.037h.814c.082 0 .075-.007.075.073v1.022c0 .096-.007.085.085.085h.779c.064 0 .064 0 .064-.066v-1.077c0-.017.01-.039.036-.037h.815c.081 0 .076-.01.076.08v1.065c0 .053 0 .053.055.056.279.02.556.064.827.135.152.04.3.094.441.162a1.258 1.258 0 0 1 .684.823c.09.321.095.66.016.984a1.397 1.397 0 0 1-.566.814 2.46 2.46 0 0 1-.725.344c-.019.001-.035-.002-.044.022Zm-2.544 1.682v1.126c0 .06 0 .06.06.06h.407c.29.007.58-.007.867-.043a2.08 2.08 0 0 0 .497-.125.857.857 0 0 0 .515-.566c.08-.262.092-.541.035-.81a.846.846 0 0 0-.379-.574 1.22 1.22 0 0 0-.366-.147 3.416 3.416 0 0 0-.625-.089c-.323-.019-.648 0-.973-.01-.034 0-.046.013-.043.046.003.032 0 .032 0 .048.003.362.003.722.005 1.084Zm0-3.087v1.001c0 .032.007.05.045.048.35.01.7 0 1.048-.03.171-.019.338-.06.499-.121a.747.747 0 0 0 .467-.538c.054-.215.061-.438.022-.656a.732.732 0 0 0-.517-.62 2.46 2.46 0 0 0-.682-.12c-.274-.014-.547 0-.821-.007h-.035c-.022 0-.034.01-.032.034v.056l.004.953h.002Z"
      />
      <path
        fill="#172027"
        d="m103.467 34.279 10.189-10.189a1.103 1.103 0 0 1 1.861.563 4.068 4.068 0 0 0 3.998 3.277 4.074 4.074 0 0 0 4.074-4.074v-4.738a4.16 4.16 0 0 0-4.11-4.161 4.163 4.163 0 0 0-2.073.523l-1.595.882a.965.965 0 0 1-1.421-1.004l.198-1.189a2.734 2.734 0 0 0-2.699-3.186 2.733 2.733 0 0 0-2.63 1.985l-.916 3.189a1.07 1.07 0 0 1-.873.763 1.08 1.08 0 0 1-1.061-.47l-1.571-2.351a2.034 2.034 0 0 0-1.483-.888 2.023 2.023 0 0 0-2.22 2.011 2.037 2.037 0 0 0 .593 1.431l1.838 1.838a1.62 1.62 0 0 1-.011 2.309c-.207.2-.463.342-.742.41a1.617 1.617 0 0 1-1.931-1.06L99.831 17a2.598 2.598 0 0 0-2.466-1.777 2.6 2.6 0 0 0-2.262 3.888l.58 1.012a1.677 1.677 0 1 1-3.094 1.193l-.895-4.025a5.35 5.35 0 0 0-10.413-.137L77 34.28"
      />
      <path
        fill="#542F5F"
        d="m48.58 34.022-10.19-10.19a1.1 1.1 0 0 0-1.859.57 4.072 4.072 0 0 1-8.066-.798V18.86a4.161 4.161 0 0 1 6.183-3.636l1.593.882a.965.965 0 0 0 1.422-1.003l-.197-1.19a2.736 2.736 0 0 1 5.329-1.199l.91 3.184a1.078 1.078 0 0 0 1.934.294l1.572-2.352a2.025 2.025 0 0 1 3.706 1.123c0 .536-.212 1.051-.592 1.43l-1.839 1.834a1.616 1.616 0 0 0 .755 2.72 1.617 1.617 0 0 0 1.931-1.06l1.05-3.15a2.599 2.599 0 0 1 3.764-1.426 2.598 2.598 0 0 1 .96 3.542l-.579 1.013a1.676 1.676 0 0 0 2.505 2.14c.298-.238.505-.571.588-.944l.894-4.024a5.35 5.35 0 0 1 8.515-3.056 5.351 5.351 0 0 1 1.897 2.922l4.28 17.12"
      />
      <path fill="#172027" d="M90.524 87.424H60.12v20.281h30.405V87.424Z" />
      <path
        fill="#EFE1DA"
        d="M65.07 87.337v13.946l-2.607-3.047a15.896 15.896 0 0 1-3.806-10.33v-.622l6.413.053Z"
      />
      <path
        fill="#DBB9BE"
        d="M85.069 87.378v13.939l2.606-3.047a15.894 15.894 0 0 0 3.814-10.332v-.62l-6.42.06Z"
      />
      <path
        fill="#4D2F47"
        d="M91.691 103.412v15.879l-4.914-9.527v28.584l-8.19-15.881-3.277 19.056v-34.936"
      />
      <path
        fill="#883A62"
        d="M59.035 103.306v15.88l4.915-9.528v28.584l8.19-15.879 3.277 19.056v-34.936"
      />
      <path
        stroke="#070707"
        strokeMiterlimit={10}
        strokeWidth={2.707}
        d="M117.328 67.674a18.186 18.186 0 0 1-5.414 12.951 18.187 18.187 0 0 1-13.021 5.242c-9.996-.13-17.953-8.464-17.953-18.463a5.793 5.793 0 0 0-5.793-5.793h-.273M32.42 67.674a18.194 18.194 0 0 0 18.438 18.193c9.995-.13 17.951-8.464 17.951-18.463a5.792 5.792 0 0 1 5.795-5.793h.27"
      />
      <path
        fill="#8E97C4"
        d="M32.42 67.674h36.39a18.194 18.194 0 0 1-36.39 0ZM80.77 67.507h36.389a18.195 18.195 0 0 1-36.388 0Z"
        opacity={0.57}
        style={{
          mixBlendMode: "color-dodge",
        }}
      />
      <path
        fill="#FFF8DA"
        d="M51.215 72.18c6.137 0 11.111-4.975 11.111-11.112 0-6.137-4.974-11.112-11.111-11.112-6.137 0-11.112 4.975-11.112 11.112 0 6.137 4.975 11.112 11.112 11.112Z"
      />
      <path
        fill="#ABA2CA"
        d="M98.128 72.18c6.137 0 11.112-4.975 11.112-11.112 0-6.137-4.975-11.112-11.112-11.112-6.136 0-11.111 4.975-11.111 11.112 0 6.137 4.975 11.112 11.111 11.112Z"
      />
      <path
        fill="#2C1F41"
        d="M51.215 67.24a6.172 6.172 0 1 0 0-12.344 6.172 6.172 0 0 0 0 12.345Z"
      />
      <path
        fill="#172027"
        d="M98.128 67.24a6.173 6.173 0 1 0 0-12.345 6.173 6.173 0 0 0 0 12.346Z"
      />
      <path
        fill="#F9C1C0"
        d="m68.286 61.72-3.73 15.546h9.945v-17.85M64.556 77.266h9.945v2.304l-9.945-2.304Z"
      />
      <path
        fill="#883A62"
        d="M64.556 77.266c1.618 1.876 4.428 3.48 8.058 4.6l1.887.588V79.57l-9.945-2.304Z"
      />
      <path fill="#F489AE" d="m80.701 61.691 3.73 15.549h-9.946V59.388" />
      <path fill="#F489AE" d="M84.43 77.24h-9.945v2.303l9.946-2.303Z" />
      <path
        fill="#4D2F47"
        d="M84.428 77.24c-1.617 1.874-4.429 3.478-8.057 4.598l-1.889.588v-2.883l9.946-2.303Z"
      />
      <path
        fill="#DF4624"
        d="m97.632 35.391 10.961-13.806v4.313c0 3.056-2.425 5.91-6.468 7.604l-4.493 1.89Z"
      />
      <path
        fill="#F96020"
        d="M53.186 35.227 42.23 21.422v4.313c0 3.055 2.424 5.908 6.468 7.603l4.488 1.889Z"
      />
      <path
        fill="#BA3272"
        d="M71.658 28.25V9.848l-1.294.815c-7.357 4.633-7.357 12.145 0 16.778l1.294.809Z"
      />
      <path
        fill="#8E97C4"
        d="M71.658 28.25 57.044 16.746v6.903l14.614 11.504"
      />
      <path
        fill="#BA3272"
        d="M57.043 23.649V2.939l-4.386 5.525c-4.323 5.446-6.574 11.451-6.574 17.54v6.849h23.426"
      />
      <path
        fill="#532D5A"
        d="M79.16 28.415v-18.41l1.29.817c7.35 4.632 7.35 12.145 0 16.777l-1.29.816Z"
      />
      <path fill="#4F4F8E" d="m79.16 28.415 14.614-11.506v6.903L79.16 35.316" />
      <path
        fill="#532D5A"
        d="M93.776 23.814V3.104L98.16 8.63c4.324 5.439 6.575 11.451 6.575 17.54v6.847H81.534"
      />
      <path
        fill="#F96020"
        d="m71.658 28.25-10.96-13.806v4.314c0 3.055 2.424 5.908 6.46 7.603l4.5 1.889Z"
      />
      <path
        fill="#DF4624"
        d="M79.16 28.415 90.12 14.609v4.313c0 3.056-2.424 5.909-6.468 7.602l-4.494 1.89Z"
      />
      <path
        fill="#F96020"
        d="M75.507 35.997a8.03 8.03 0 1 0 0-16.061 8.03 8.03 0 0 0 0 16.061Z"
      />
      <path
        fill="#D74816"
        d="M75.505 34.996a7.03 7.03 0 1 0 0-14.06 7.03 7.03 0 0 0 0 14.06Z"
      />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace614;
