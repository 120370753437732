import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace292 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#3D4B8A" d="M150.675 0H0v150.675h150.675V0Z" />
      <path fill="#A02A15" d="M120.641 69.09H30.155v79.847h90.486V69.09Z" />
      <path
        fill="#DE2F7A"
        d="M120.224 144.463H30.26v4.474h89.964v-4.474ZM120.224 138.869H30.26v4.475h89.964v-4.475ZM120.224 133.274H30.26v4.475h89.964v-4.475ZM120.224 127.681H30.26v4.475h89.964v-4.475ZM120.224 122.088H30.26v4.475h89.964v-4.475ZM120.224 116.494H30.26v4.475h89.964v-4.475ZM120.224 110.901H30.26v4.475h89.964v-4.475ZM120.224 105.306H30.26v4.475h89.964v-4.475ZM120.224 99.713H30.26v4.474h89.964v-4.474ZM120.224 94.12H30.26v4.474h89.964V94.12ZM120.224 88.526H30.26v4.475h89.964v-4.475ZM120.224 82.933H30.26v4.474h89.964v-4.474Z"
      />
      <path
        fill="#7814BC"
        d="M120.696 74.868H74.794v74.069h45.902V74.868Z"
        opacity={0.34}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path
        fill="#DBB9BE"
        d="M74.125 20.595V125.85L20.196 92.805V45.073l53.929-24.479Z"
      />
      <path
        fill="#BCAFB1"
        d="M74.192 20.58v105.256l53.929-33.045V45.058L74.192 20.58Z"
      />
      <path
        fill="#F26227"
        d="M20.827 78.892a9.002 9.002 0 1 0 0-18.005 9.002 9.002 0 0 0 0 18.005Z"
      />
      <path
        fill="#D74B27"
        d="M20.827 77.769a7.88 7.88 0 1 0 0-15.759 7.88 7.88 0 0 0 0 15.759Z"
      />
      <path
        fill="#F79421"
        d="M20.48 62.637h.709c.048.007.097.01.147.01.205.017.41.038.614.07.484.074.959.198 1.417.37a7.286 7.286 0 0 1 4.365 4.556 6.974 6.974 0 0 1 .335 1.851.217.217 0 0 0 .01.11v.588a.2.2 0 0 0-.01.11 7.036 7.036 0 0 1-.806 2.937 7.247 7.247 0 0 1-7.037 3.893 7.033 7.033 0 0 1-1.386-.25 7.214 7.214 0 0 1-4.034-2.91 7.216 7.216 0 0 1-1.227-3.47 1.835 1.835 0 0 0-.022-.254v-.696a.084.084 0 0 0 .01-.053 6.94 6.94 0 0 1 .112-.938 7.25 7.25 0 0 1 6.637-5.906 1.34 1.34 0 0 0 .166-.018Zm-5.702 7.257a6.051 6.051 0 1 0 6.049-6.058 6.045 6.045 0 0 0-6.049 6.058Z"
      />
      <path
        fill="#F79421"
        d="M22.651 69.68c.257.042.507.12.743.229.298.134.548.356.716.636.11.194.18.408.205.63.038.28.026.565-.036.842a1.478 1.478 0 0 1-.924 1.088c-.252.1-.515.169-.783.207-.18.028-.362.048-.545.059-.064 0-.065 0-.065.07v1.078c0 .067 0 .067-.07.067h-.786c-.076 0-.072 0-.072-.07v-1.043c0-.086.008-.077-.08-.077h-.784c-.063 0-.063 0-.063.064v1.05c0 .08.007.076-.074.076h-.782c-.073 0-.075 0-.075-.076v-1.078c0-.025-.009-.04-.035-.036-.026.002-.038 0-.056 0h-1.717c-.03 0-.045 0-.045-.043v-.772c0-.037.014-.047.05-.045h.479c.099-.002.197-.015.294-.039a.258.258 0 0 0 .21-.213c.028-.127.041-.256.038-.386V67.86a1.691 1.691 0 0 0-.035-.365.276.276 0 0 0-.232-.228 1.872 1.872 0 0 0-.374-.034h-.361c-.064 0-.064 0-.064-.066v-.716c0-.082 0-.076.074-.076h1.702c.077 0 .077 0 .077-.075v-1.07c0-.02.013-.037.038-.035h.814c.083 0 .075-.01.075.073v1.022c0 .095 0 .085.085.085h.78c.063 0 .063 0 .063-.068V65.23c0-.016.01-.038.037-.037h.814c.08 0 .076-.012.076.08v1.062c0 .053 0 .052.055.056.28.019.556.064.827.134.152.04.3.094.441.163a1.25 1.25 0 0 1 .684.823c.09.32.095.659.016.982a1.402 1.402 0 0 1-.566.816 2.5 2.5 0 0 1-.725.344c-.019.006-.035.001-.044.026Zm-2.544 1.681v1.126c0 .06 0 .06.06.06h.407a5.9 5.9 0 0 0 .867-.04 2.09 2.09 0 0 0 .497-.127.858.858 0 0 0 .515-.565c.08-.262.092-.541.035-.81a.85.85 0 0 0-.38-.574 1.223 1.223 0 0 0-.365-.147 3.19 3.19 0 0 0-.625-.089c-.323-.019-.648 0-.973-.01-.034 0-.046.013-.043.044.003.031 0 .033 0 .049l.005 1.083Zm0-3.087v1c0 .034.007.051.045.05.35.01.7 0 1.048-.03.171-.019.338-.06.499-.122a.755.755 0 0 0 .467-.54c.054-.213.061-.436.022-.653a.735.735 0 0 0-.517-.62 2.453 2.453 0 0 0-.682-.121c-.274-.013-.547 0-.82 0h-.036a.03.03 0 0 0-.024.009.029.029 0 0 0-.008.025v.055c.003.319.004.635.004.947h.002Z"
      />
      <path
        fill="#F26227"
        d="M131.959 79.33a9.002 9.002 0 0 0 9.002-9.002 9.002 9.002 0 1 0-18.004 0 9.002 9.002 0 0 0 9.002 9.002Z"
      />
      <path
        fill="#D74B27"
        d="M131.959 78.207a7.88 7.88 0 1 0 0-15.759 7.88 7.88 0 0 0 0 15.759Z"
      />
      <path
        fill="#F79421"
        d="M131.612 63.073h.709a.754.754 0 0 0 .147.01c.205.018.41.04.614.071.483.075.957.199 1.414.37a7.284 7.284 0 0 1 4.365 4.558 7.026 7.026 0 0 1 .335 1.85.223.223 0 0 0 .01.112v.588a.203.203 0 0 0-.01.112 7.03 7.03 0 0 1-.806 2.936 7.262 7.262 0 0 1-8.423 3.643 7.223 7.223 0 0 1-4.034-2.91 7.234 7.234 0 0 1-1.227-3.47 1.827 1.827 0 0 0-.022-.255V69.999a.093.093 0 0 0 .01-.055c.016-.314.053-.627.112-.936a7.05 7.05 0 0 1 .601-1.828 7.277 7.277 0 0 1 4.769-3.88c.416-.104.84-.17 1.267-.2a1.08 1.08 0 0 0 .169-.027Zm-5.702 7.258a6.05 6.05 0 1 0 6.049-6.053 6.045 6.045 0 0 0-6.049 6.053Z"
      />
      <path
        fill="#F79421"
        d="M133.783 70.116c.254.043.502.12.735.23.299.134.549.356.716.637.111.194.181.408.206.63.038.28.025.566-.037.842a1.464 1.464 0 0 1-.923 1.086c-.252.1-.515.17-.784.207-.18.028-.361.049-.545.06-.063 0-.065 0-.065.07v1.077c0 .068 0 .07-.069.07h-.786c-.077 0-.072 0-.072-.073V73.91c0-.087 0-.078-.08-.078h-.784c-.064 0-.064 0-.064.066v1.05c0 .08.008.076-.073.076h-.775c-.073 0-.075 0-.075-.078V73.87c0-.025-.009-.038-.035-.037H128.5c-.031 0-.046-.007-.046-.04v-.773c0-.038.015-.047.05-.047h.48a1.38 1.38 0 0 0 .294-.038.264.264 0 0 0 .139-.073.266.266 0 0 0 .071-.14c.028-.127.041-.256.038-.385v-4.038a1.667 1.667 0 0 0-.035-.366.277.277 0 0 0-.232-.228 1.887 1.887 0 0 0-.374-.034h-.361c-.064 0-.064 0-.064-.066v-.716c0-.08 0-.077.074-.077h1.702c.077 0 .077 0 .077-.073v-1.07c0-.018.013-.038.038-.037h.814c.082 0 .075-.007.075.074v1.021c0 .096-.007.085.085.085h.779c.064 0 .064 0 .064-.066v-1.077c0-.016.01-.038.036-.037h.815c.081 0 .076-.01.076.08v1.065c0 .053 0 .053.055.056.279.02.556.065.827.135.152.04.3.094.441.162a1.259 1.259 0 0 1 .684.823c.09.321.095.66.016.984a1.397 1.397 0 0 1-.566.814c-.221.153-.466.27-.725.344-.019.002-.035-.001-.044.022Zm-2.544 1.682v1.126c0 .06 0 .06.06.06h.407c.29.007.58-.007.867-.043a2.08 2.08 0 0 0 .497-.125.855.855 0 0 0 .515-.566c.08-.262.092-.54.035-.81a.846.846 0 0 0-.379-.574 1.22 1.22 0 0 0-.366-.147 3.407 3.407 0 0 0-.625-.088c-.323-.02-.648 0-.973-.01-.034 0-.046.013-.043.045.003.032 0 .032 0 .048.003.362.003.722.005 1.084Zm0-3.087v1.001c0 .032.007.05.045.048.35.01.7 0 1.048-.03.171-.018.338-.06.499-.121a.748.748 0 0 0 .467-.538c.054-.215.061-.438.022-.656a.733.733 0 0 0-.517-.62 2.46 2.46 0 0 0-.682-.12c-.274-.014-.547 0-.821-.007h-.035c-.022 0-.034.01-.032.034v.056l.004.953h.002Z"
      />
      <path
        fill="#542F5F"
        d="M44.98 33.81v9.408a3.335 3.335 0 0 1-3.329 3.324h-3.065a14.36 14.36 0 0 0-5.523 1.103 12.804 12.804 0 0 1-16.484-6.32l-.653-1.359A12.728 12.728 0 0 1 30.7 22.196l13.12 3.501H27.608a5.79 5.79 0 1 0 0 11.58h.713c1.049.002 2.079-.282 2.98-.821l5.991-3.596a6.291 6.291 0 0 1 7.688.947v.003Z"
      />
      <path
        fill="#111C0D"
        d="M106.278 33.834v9.408a3.331 3.331 0 0 0 3.334 3.334h3.059c1.895 0 3.772.375 5.521 1.104a12.806 12.806 0 0 0 16.486-6.314l.65-1.365a12.732 12.732 0 0 0 .417-9.965 12.725 12.725 0 0 0-15.187-7.805l-13.123 3.494h16.214a5.785 5.785 0 0 1 4.095 1.697 5.786 5.786 0 0 1 1.695 4.095 5.772 5.772 0 0 1-1.695 4.095 5.787 5.787 0 0 1-4.095 1.695h-.707c-1.05 0-2.08-.285-2.98-.824l-5.993-3.596a6.29 6.29 0 0 0-7.687.947h-.004Z"
      />
      <path
        fill="#4A2955"
        d="M87.275 111.119v7.363l-4.09-4.418v13.252l-6.821-7.363-2.729 8.836v-16.198"
      />
      <path
        fill="#161F26"
        d="M59.886 110.892v7.362l4.091-4.417v13.252l6.821-7.362 2.728 8.834v-16.197"
      />
      <path
        fill="#883A62"
        d="M34.078 81.71c6.882 19.095 22.264 32.393 40.144 32.393s33.263-13.298 40.144-32.393H34.078Z"
      />
      <path
        fill="#001A1B"
        d="M36.851 85.1c6.307 15.141 20.404 25.695 36.784 25.695s30.482-10.541 36.786-25.695h-73.57Z"
      />
      <path
        fill="#EFE1DA"
        d="M93.755 84.654v16.197l-8.185-17.67M81.478 84.654v7.362l-2.728-8.835M77.385 84.654v5.89h-2.727v-5.89"
      />
      <path
        fill="#DBB9BE"
        d="m89.663 108.214-8.185-8.835v11.779M78.75 111.158v-10.307h-4.092v11.78"
      />
      <path
        fill="#EFE1DA"
        d="M54.565 84.688v16.198l8.185-17.67M66.842 84.688v7.363l2.729-8.834M70.935 84.688v5.89h2.728v-5.89"
      />
      <path
        fill="#DBB9BE"
        d="m58.657 108.248 8.185-8.835v11.779M69.57 111.192v-10.306h4.093v11.779"
      />
      <path
        fill="#883A62"
        d="M74.157 114.031c17.878 0 33.263-13.299 40.144-32.395H34.013c6.882 19.096 22.267 32.395 40.144 32.395Zm-.01-3.88c-14.71 0-27.372-10.024-33.034-24.416h66.068c-5.663 14.392-18.324 24.416-33.034 24.416Z"
      />
      <path
        stroke="#001A1B"
        strokeMiterlimit={10}
        strokeWidth={0.902}
        d="M74.115 111.333c-15.73 0-29.262-11.172-35.317-27.205h70.632c-6.048 16.038-19.59 27.205-35.316 27.205Z"
      />
      <path
        fill="#DBB9BE"
        d="m87.114 65.571 2.56-3.407a14.07 14.07 0 0 1 19.06-3.265l3.234 2.155-24.854 4.517Z"
      />
      <path
        fill="#EFE1DA"
        d="m111.847 60.968-2.557 3.408a14.06 14.06 0 0 1-11.256 5.628c-2.777.001-5.493-.822-7.804-2.363l-3.234-2.155 24.851-4.518Z"
      />
      <path
        fill="#4F4F8E"
        d="M98.534 70.215a6.777 6.777 0 1 0 0-13.554 6.777 6.777 0 0 0 0 13.554Z"
      />
      <path
        fill="#001A1B"
        d="M98.534 67.957a4.519 4.519 0 1 0 0-9.038 4.519 4.519 0 0 0 0 9.038Z"
      />
      <path
        fill="#4A2955"
        d="M84.854 54.277h33.885l-4.516-6.778h-12.096a21.58 21.58 0 0 0-11.103 3.07l-6.17 3.708Z"
      />
      <path fill="#EFE1DA" d="M102.924 60.29h-3.15v3.15h3.15v-3.15Z" />
      <path
        fill="#DBB9BE"
        d="m65.015 65.433-2.556-3.408a14.07 14.07 0 0 0-19.06-3.264l-3.234 2.155 24.85 4.517Z"
      />
      <path
        fill="#EFE1DA"
        d="m40.282 60.83 2.555 3.41a14.074 14.074 0 0 0 19.068 3.263l3.234-2.155-24.856-4.518Z"
      />
      <path
        fill="#4F4F8E"
        d="M53.595 70.076a6.777 6.777 0 1 0 0-13.553 6.777 6.777 0 0 0 0 13.553Z"
      />
      <path
        fill="#001A1B"
        d="M53.595 67.819a4.519 4.519 0 1 0 0-9.038 4.519 4.519 0 0 0 0 9.038Z"
      />
      <path
        fill="#4A2955"
        d="M67.275 54.137H33.388l4.519-6.776h12.095c3.911 0 7.75 1.063 11.103 3.075l6.17 3.701Z"
      />
      <path fill="#EFE1DA" d="M49.205 63.3h3.15v-3.15h-3.15v3.15Z" />
      <path
        fill="#F55B20"
        d="M74.264 65.64v14.983a5.685 5.685 0 0 1-5.586-6.701l1.506-8.282h4.08Z"
      />
      <path
        fill="#883A62"
        d="M74.12 65.64v14.984a5.684 5.684 0 0 0 5.586-6.702L78.2 65.64h-4.08Z"
      />
      <path fill="#542F5F" d="M74.382 12.936H53.214v19.845h21.168V12.936Z" />
      <path fill="#172027" d="M95.55 12.936H74.382v19.845H95.55V12.936Z" />
      <path fill="#172027" d="M112.749 31.458H37.338v2.646h75.411v-2.646Z" />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace292;
