import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace586 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#FEC233" d="M150.675 0H0v150.675h150.675V0Z" />
      <path
        fill="#6C1908"
        d="M74.088 72.65a60.66 60.66 0 0 1 60.654 60.661v14.13H13.422v-14.13a60.66 60.66 0 0 1 60.666-60.66Z"
      />
      <path
        fill="#8E97C4"
        d="M74.676 23.226h-11.63A30.1 30.1 0 0 0 36.35 39.44L14.87 80.733l33.18 38.42a35.185 35.185 0 0 0 26.626 12.186V23.226Z"
      />
      <path
        fill="#4F4F8E"
        d="M74.676 23.226h11.63A30.1 30.1 0 0 1 113.01 39.44l21.472 41.294-33.18 38.42a35.183 35.183 0 0 1-26.627 12.186V23.226Z"
      />
      <path
        fill="#B2BCDF"
        d="m54.65 84.129-24.557 24.557.34.339 24.556-24.557-.339-.34ZM57.52 84.447 37.669 104.3l.34.338 19.851-19.852-.338-.339ZM56.086 88.273l-13.794 13.793.34.339 13.793-13.793-.34-.339ZM64.377 87.635 37.43 114.584l.339.339 26.948-26.949-.339-.339ZM59.114 98.32l-18.258 18.258.339.339 18.258-18.258-.339-.339ZM65.015 98.959 44.844 119.13l.339.339 20.171-20.172-.339-.338ZM43.965 67.227l-26.23 26.23.338.34 26.23-26.231-.338-.34ZM31.314 76.093l-.346-.333c.941-.973 9.216-9.526 9.872-9.85l.216.428c-.484.26-6.092 5.978-9.742 9.755ZM41.735 61.804l-8.69 8.691.338.34 8.69-8.692-.338-.339ZM40.458 57.181 21.722 75.917l.339.339L40.797 57.52l-.339-.339ZM38.386 55.267l-6.219 6.219.339.339 6.219-6.22-.339-.338Z"
      />
      <path
        fill="#7272AE"
        d="m94.494 84.089-.338.339 24.556 24.557.339-.339-24.557-24.557ZM91.625 84.409l-.339.339 19.852 19.852.339-.339-19.852-19.852ZM93.06 88.236l-.339.339 13.794 13.793.338-.338L93.06 88.236ZM84.767 87.598l-.339.338 26.949 26.949.339-.339-26.949-26.948ZM90.03 98.281l-.34.339 18.259 18.258.339-.339L90.03 98.281ZM84.13 98.92l-.339.339 20.172 20.171.339-.339L84.13 98.92ZM105.179 67.187l-.339.339 26.231 26.23.338-.338-26.23-26.23ZM117.832 76.054c-3.65-3.778-9.261-9.495-9.741-9.76l.214-.427c.656.327 8.931 8.88 9.873 9.849l-.346.338ZM107.411 61.767l-.339.339 8.691 8.69.339-.338-8.691-8.691ZM108.687 57.142l-.339.339 18.736 18.736.339-.339-18.736-18.736ZM110.76 55.228l-.339.339 6.219 6.219.339-.34-6.219-6.218Z"
      />
      <path
        fill="#DF2D2C"
        d="M19.817 49.561h31.192v26.46H19.817A10.584 10.584 0 0 1 9.233 65.437v-5.292a10.584 10.584 0 0 1 10.584-10.584Z"
      />
      <path
        fill="#C12E58"
        d="M129.333 76.021h-21.729v-26.46h21.729a10.581 10.581 0 0 1 10.584 10.584v5.292a10.584 10.584 0 0 1-10.584 10.584Z"
      />
      <path
        fill="#A02960"
        d="M25.109 59.484a4.63 4.63 0 0 0-9.261 0v6.615a4.63 4.63 0 0 0 9.261 0v-6.615Z"
      />
      <path
        fill="#4A2955"
        d="M134.036 59.484a4.63 4.63 0 0 0-9.261 0v6.615a4.63 4.63 0 1 0 9.261 0v-6.615Z"
        opacity={0.45}
      />
      <path
        fill="#BA3D11"
        d="M46.999 94.743a43.888 43.888 0 0 0 25.173 7.938h2.034v-7.938"
      />
      <path
        fill="#A31905"
        d="M101.58 94.702a43.894 43.894 0 0 1-25.175 7.938h-2.033v-7.938"
      />
      <path
        fill="#6C1908"
        d="m74.206 90.208-3.402-3.4-24.94 7.935h28.342v-4.535Z"
      />
      <path
        fill="#3E1B11"
        d="m74.372 90.167 3.401-3.4 24.94 7.935H74.372v-4.535Z"
      />
      <path
        stroke="#070707"
        strokeMiterlimit={10}
        strokeWidth={2.707}
        d="M117.328 67.674a18.186 18.186 0 0 1-5.414 12.951 18.184 18.184 0 0 1-13.021 5.242c-9.996-.13-17.953-8.464-17.953-18.463a5.793 5.793 0 0 0-5.793-5.793h-.273M32.42 67.674a18.194 18.194 0 0 0 18.438 18.193c9.995-.13 17.951-8.464 17.951-18.463a5.792 5.792 0 0 1 5.795-5.793h.27"
      />
      <path
        fill="#8E97C4"
        d="M32.42 67.674h36.39a18.194 18.194 0 0 1-36.39 0ZM80.77 67.507h36.389a18.195 18.195 0 0 1-36.388 0Z"
        opacity={0.57}
        style={{
          mixBlendMode: "color-dodge",
        }}
      />
      <path
        fill="#3D2F39"
        d="m102.258 52.511-13.733 4.974a7.528 7.528 0 0 0-4.515 9.642l.312.86a7.528 7.528 0 0 0 9.642 4.515l13.733-4.974a7.528 7.528 0 0 0 4.514-9.642l-.312-.86a7.527 7.527 0 0 0-9.641-4.515Z"
      />
      <path
        fill="#EB6447"
        d="M94.894 70.51a6.844 6.844 0 1 0 0-13.689 6.844 6.844 0 0 0 0 13.689Z"
      />
      <path
        fill="#3D2F39"
        d="m93.01 58.287-.12.044c-.56.202-.848.82-.646 1.378l3.152 8.703c.203.56.82.848 1.378.646l.122-.044c.559-.203.848-.82.645-1.379l-3.152-8.703a1.076 1.076 0 0 0-1.378-.645Z"
      />
      <path
        fill="#80191B"
        d="m40.48 67.746 13.734 4.974a7.528 7.528 0 0 0 9.641-4.515l.312-.86a7.528 7.528 0 0 0-4.514-9.642L45.92 52.729a7.528 7.528 0 0 0-9.642 4.514l-.312.861a7.528 7.528 0 0 0 4.515 9.642Z"
      />
      <path
        fill="#EB6447"
        d="M53.289 70.733a6.844 6.844 0 1 0 0-13.688 6.844 6.844 0 0 0 0 13.688Z"
      />
      <path
        fill="#3D2F39"
        d="m51.28 69.231.123.044a1.076 1.076 0 0 0 1.378-.645l3.152-8.703a1.076 1.076 0 0 0-.645-1.379l-.122-.044a1.076 1.076 0 0 0-1.378.646l-3.152 8.703a1.076 1.076 0 0 0 .645 1.378Z"
      />
      <path
        fill="#883A62"
        d="M75.792 53.035a16.417 16.417 0 0 1 16.417 16.417v18.534H59.376V69.452a16.417 16.417 0 0 1 16.416-16.417Z"
        opacity={0.7}
      />
      <path
        fill="#FA8CB2"
        d="M68.667 79.454h8.773v-14.71h-6.065a3.592 3.592 0 0 0-3.381 2.377l-2.708 7.522a3.594 3.594 0 0 0 3.38 4.81Z"
      />
      <path
        fill="#542F5F"
        d="M70.556 74.276a2.647 2.647 0 1 0 0-5.294 2.647 2.647 0 0 0 0 5.294Z"
      />
      <path
        fill="#F46461"
        d="M82.447 79.513h-6.714v-14.71h4.006a3.595 3.595 0 0 1 3.381 2.376l2.708 7.522a3.595 3.595 0 0 1-3.381 4.812Z"
      />
      <path
        fill="#172027"
        d="M80.558 74.17a2.647 2.647 0 1 0 0-5.294 2.647 2.647 0 0 0 0 5.295Z"
      />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace586;
