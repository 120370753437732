import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace608 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#FDC4FF" d="M150.675 0H0v150.675h150.675V0Z" />
      <path fill="#BA3D11" d="M124.803 66.54H23.698v81.168h101.105V66.541Z" />
      <path fill="#542F5F" d="M101.677 66.319H45.483v81.389h56.194v-81.39Z" />
      <path fill="#172027" d="M102.165 66.319H73.553v81.389h28.612v-81.39Z" />
      <path
        fill="#172027"
        d="M131.377 33.134v25.225a17.786 17.786 0 0 1-17.787 17.787h-4.806l-6.966 26.098a21.918 21.918 0 0 1-21.178 16.27h-5.76v-85.38"
      />
      <path
        fill="#542F5F"
        d="M18.967 33.14V57.77a17.787 17.787 0 0 0 17.787 17.787h4.807l6.96 26.102a21.918 21.918 0 0 0 21.187 16.267h5.76V33.14"
      />
      <path
        fill="#F26227"
        d="M20.827 78.892a9.002 9.002 0 1 0 0-18.005 9.002 9.002 0 0 0 0 18.005Z"
      />
      <path
        fill="#D74B27"
        d="M20.827 77.769a7.88 7.88 0 1 0 0-15.759 7.88 7.88 0 0 0 0 15.759Z"
      />
      <path
        fill="#F79421"
        d="M20.48 62.636h.709c.048.008.097.011.147.01.205.018.41.039.614.071.484.074.959.198 1.417.37a7.286 7.286 0 0 1 4.365 4.556 6.982 6.982 0 0 1 .335 1.851.218.218 0 0 0 .01.11v.588a.202.202 0 0 0-.01.11 7.04 7.04 0 0 1-.806 2.937 7.247 7.247 0 0 1-7.037 3.893 7.03 7.03 0 0 1-1.386-.25 7.213 7.213 0 0 1-4.034-2.91 7.217 7.217 0 0 1-1.227-3.47 1.835 1.835 0 0 0-.022-.254V69.551a.084.084 0 0 0 .01-.052c.016-.315.053-.629.112-.938a7.25 7.25 0 0 1 6.637-5.906 1.34 1.34 0 0 0 .166-.019Zm-5.702 7.258a6.053 6.053 0 1 0 3.731-5.598 6.045 6.045 0 0 0-3.731 5.598Z"
      />
      <path
        fill="#F79421"
        d="M22.651 69.68c.257.042.507.119.743.229.298.134.548.356.716.636.11.194.18.408.205.63.038.28.026.565-.036.842a1.48 1.48 0 0 1-.924 1.087c-.252.1-.515.17-.783.208-.18.028-.362.048-.546.059-.063 0-.064 0-.064.07v1.078c0 .067 0 .067-.07.067h-.786c-.076 0-.072 0-.072-.07v-1.043c0-.086.008-.078-.08-.078h-.784c-.063 0-.063 0-.063.065v1.05c0 .08.007.076-.074.076h-.782c-.073 0-.075 0-.075-.076v-1.078c0-.025-.009-.04-.035-.037-.027.003-.038 0-.056 0h-1.717c-.03 0-.045 0-.045-.042v-.772c0-.037.014-.047.05-.046h.479c.099-.001.197-.014.294-.038a.259.259 0 0 0 .21-.213 1.61 1.61 0 0 0 .038-.386V67.86a1.69 1.69 0 0 0-.035-.365.277.277 0 0 0-.232-.228 1.879 1.879 0 0 0-.374-.034h-.361c-.064 0-.064 0-.064-.066v-.716c0-.082 0-.076.074-.076h1.702c.077 0 .077 0 .077-.075v-1.07c0-.02.013-.037.038-.036h.814c.082 0 .075-.008.075.074v1.022c0 .095 0 .085.085.085h.78c.063 0 .063 0 .063-.068V65.23c0-.017.01-.039.037-.037h.814c.08 0 .076-.012.076.08v1.062c0 .053 0 .052.055.056.28.019.556.063.827.134.152.04.3.094.441.163a1.25 1.25 0 0 1 .684.823c.09.32.095.659.016.982a1.402 1.402 0 0 1-.566.816 2.5 2.5 0 0 1-.725.344c-.019.006-.035.001-.044.026Zm-2.544 1.681v1.126c0 .06 0 .06.06.06h.407a5.9 5.9 0 0 0 .867-.04c.17-.022.338-.065.497-.127a.857.857 0 0 0 .515-.565c.08-.262.092-.541.035-.81a.85.85 0 0 0-.38-.575 1.22 1.22 0 0 0-.365-.147 3.182 3.182 0 0 0-.625-.088c-.323-.019-.648 0-.973-.01-.034 0-.046.013-.043.044.003.031 0 .032 0 .049l.005 1.083Zm0-3.087v1c0 .033.007.051.045.05.35.01.7 0 1.048-.03.171-.019.338-.06.499-.122a.755.755 0 0 0 .467-.54c.054-.213.061-.436.022-.654a.735.735 0 0 0-.517-.62 2.454 2.454 0 0 0-.682-.12c-.274-.014-.547 0-.82 0h-.036a.03.03 0 0 0-.013.001.03.03 0 0 0-.018.02.029.029 0 0 0-.001.013v.055c.003.319.004.634.004.947h.002Z"
      />
      <path
        fill="#F26227"
        d="M131.959 79.33a9.002 9.002 0 0 0 9.002-9.002 9.002 9.002 0 1 0-18.004 0 9.002 9.002 0 0 0 9.002 9.002Z"
      />
      <path
        fill="#D74B27"
        d="M131.959 78.207a7.879 7.879 0 0 0 7.879-7.88 7.879 7.879 0 1 0-15.758 0 7.879 7.879 0 0 0 7.879 7.88Z"
      />
      <path
        fill="#F79421"
        d="M131.612 63.073h.709a.754.754 0 0 0 .147.01c.205.018.41.04.614.071.483.075.957.199 1.414.37a7.283 7.283 0 0 1 4.365 4.557 7.026 7.026 0 0 1 .335 1.851.223.223 0 0 0 .01.112v.588a.203.203 0 0 0-.01.112c-.01.233-.034.466-.071.696a7.029 7.029 0 0 1-.735 2.24 7.262 7.262 0 0 1-8.423 3.643 7.223 7.223 0 0 1-4.034-2.91 7.233 7.233 0 0 1-1.227-3.47 1.828 1.828 0 0 0-.022-.255V69.999a.086.086 0 0 0 .01-.055c.016-.314.053-.627.112-.937a7.051 7.051 0 0 1 .601-1.827 7.28 7.28 0 0 1 4.769-3.88c.416-.104.84-.17 1.267-.2.057-.005.114-.014.169-.027Zm-5.702 7.258a6.051 6.051 0 1 0 12.103-.002 6.051 6.051 0 0 0-12.103.002Z"
      />
      <path
        fill="#F79421"
        d="M133.783 70.116c.254.043.502.12.735.23.299.133.549.356.716.637.111.194.181.408.206.63.038.28.025.565-.037.842a1.463 1.463 0 0 1-.923 1.086c-.252.1-.515.17-.783.207a6.088 6.088 0 0 1-.546.06c-.063 0-.064 0-.064.07v1.077c0 .068-.001.07-.07.07h-.786c-.076 0-.072 0-.072-.073V73.91c0-.087 0-.078-.079-.078h-.785c-.064 0-.064 0-.064.066v1.05c0 .08.008.076-.073.076h-.775c-.073 0-.075 0-.075-.078V73.87c0-.025-.009-.038-.035-.037H128.5c-.031 0-.045-.007-.045-.041v-.772c0-.038.014-.047.05-.047h.479a1.38 1.38 0 0 0 .294-.038.26.26 0 0 0 .21-.213c.028-.127.041-.256.038-.385v-4.039a1.666 1.666 0 0 0-.035-.365.277.277 0 0 0-.232-.228 1.88 1.88 0 0 0-.374-.034h-.361c-.064 0-.064 0-.064-.066v-.716c0-.081 0-.077.074-.077h1.702c.077 0 .077 0 .077-.073v-1.07c0-.018.013-.039.038-.037h.814c.083 0 .075-.007.075.073v1.022c0 .096-.007.085.085.085h.78c.063 0 .063 0 .063-.066v-1.077c0-.017.01-.039.037-.037h.814c.081 0 .076-.01.076.08v1.065c0 .053 0 .053.055.056.279.02.556.064.827.135.152.04.3.094.441.162a1.258 1.258 0 0 1 .684.823c.09.321.095.66.016.984a1.393 1.393 0 0 1-.566.814 2.46 2.46 0 0 1-.725.344c-.019.001-.035-.002-.044.022Zm-2.544 1.682v1.126c0 .06 0 .06.06.06h.407c.29.007.58-.007.867-.043a2.08 2.08 0 0 0 .497-.125.857.857 0 0 0 .515-.566c.08-.262.092-.541.035-.81a.842.842 0 0 0-.379-.575 1.22 1.22 0 0 0-.366-.146 3.416 3.416 0 0 0-.625-.089c-.323-.019-.648 0-.973-.01-.034 0-.046.013-.043.046.003.032 0 .032 0 .048.003.362.003.722.005 1.084Zm0-3.087v1.001c0 .032.007.05.045.048.35.01.7 0 1.048-.03.171-.019.339-.06.499-.121a.747.747 0 0 0 .467-.538c.054-.215.061-.438.022-.656a.732.732 0 0 0-.517-.62 2.454 2.454 0 0 0-.682-.12c-.274-.014-.547 0-.821-.007h-.035c-.022 0-.034.01-.032.034v.056l.004.953h.002Z"
      />
      <path
        fill="#BA3D11"
        d="m44.33 40.412-.974-3.789c-2.499-9.735-14.272-13.574-22.035-7.188L5.886 42.14l22.44 5.465 10.692-8.796 2.94 3.568"
      />
      <path
        fill="#A31905"
        d="m105.314 40.522.974-3.788c2.499-9.736 14.273-13.574 22.036-7.189l15.442 12.706-22.438 5.464-10.702-8.804-2.94 3.568"
      />
      <path
        fill="#4A2955"
        d="M87.275 111.119v7.363l-4.09-4.417v13.252l-6.821-7.364-2.729 8.837v-16.198"
      />
      <path
        fill="#161F26"
        d="M59.886 110.892v7.362l4.091-4.417v13.252l6.821-7.362 2.728 8.835v-16.198"
      />
      <path
        fill="#883A62"
        d="M34.078 81.71c6.882 19.096 22.264 32.393 40.144 32.393s33.263-13.297 40.144-32.393H34.078Z"
      />
      <path
        fill="#001A1B"
        d="M36.851 85.1c6.307 15.141 20.404 25.695 36.784 25.695s30.482-10.541 36.786-25.695h-73.57Z"
      />
      <path
        fill="#EFE1DA"
        d="M93.755 84.654v16.197l-8.185-17.67M81.478 84.654v7.362l-2.729-8.835M77.385 84.654v5.89h-2.727v-5.89"
      />
      <path
        fill="#DBB9BE"
        d="m89.663 108.214-8.185-8.835v11.779M78.75 111.159v-10.308h-4.092v11.781"
      />
      <path
        fill="#EFE1DA"
        d="M54.565 84.688v16.198l8.185-17.67M66.843 84.688v7.364l2.728-8.835M70.935 84.688v5.89h2.728v-5.89"
      />
      <path
        fill="#DBB9BE"
        d="m58.657 108.248 8.185-8.835v11.78M69.57 111.192v-10.306h4.093v11.779"
      />
      <path
        fill="#883A62"
        d="M74.157 114.031c17.878 0 33.263-13.299 40.144-32.394H34.013c6.882 19.095 22.267 32.394 40.144 32.394Zm-.01-3.879c-14.71 0-27.372-10.024-33.034-24.417h66.068c-5.663 14.393-18.324 24.417-33.034 24.417Z"
      />
      <path
        stroke="#001A1B"
        strokeMiterlimit={10}
        strokeWidth={0.902}
        d="M74.115 111.333c-15.73 0-29.262-11.172-35.317-27.205h70.632c-6.048 16.038-19.59 27.205-35.316 27.205Z"
      />
      <path
        fill="#DBB9BE"
        d="m87.114 65.57 2.56-3.407a14.069 14.069 0 0 1 19.06-3.264l3.234 2.154-24.854 4.518Z"
      />
      <path
        fill="#EFE1DA"
        d="m111.846 60.968-2.556 3.408a14.06 14.06 0 0 1-11.256 5.628c-2.778 0-5.493-.822-7.804-2.363l-3.234-2.156 24.85-4.517Z"
      />
      <path
        fill="#4F4F8E"
        d="M98.534 70.214a6.777 6.777 0 1 0 0-13.553 6.777 6.777 0 0 0 0 13.553Z"
      />
      <path
        fill="#001A1B"
        d="M98.534 67.957a4.519 4.519 0 1 0 0-9.038 4.519 4.519 0 0 0 0 9.038Z"
      />
      <path
        fill="#4A2955"
        d="M84.854 54.277h33.885l-4.516-6.778h-12.096a21.58 21.58 0 0 0-11.103 3.069l-6.17 3.709Z"
      />
      <path fill="#EFE1DA" d="M102.924 60.289h-3.15v3.15h3.15v-3.15Z" />
      <path
        fill="#DBB9BE"
        d="m65.015 65.433-2.556-3.408a14.072 14.072 0 0 0-11.256-5.628c-2.778 0-5.494.822-7.804 2.363l-3.234 2.155 24.85 4.518Z"
      />
      <path
        fill="#EFE1DA"
        d="m40.282 60.83 2.555 3.409a14.074 14.074 0 0 0 19.068 3.263l3.234-2.155-24.857-4.517Z"
      />
      <path
        fill="#4F4F8E"
        d="M53.595 70.076a6.777 6.777 0 1 0 0-13.553 6.777 6.777 0 0 0 0 13.553Z"
      />
      <path
        fill="#001A1B"
        d="M53.595 67.818a4.519 4.519 0 1 0 0-9.037 4.519 4.519 0 0 0 0 9.037Z"
      />
      <path
        fill="#4A2955"
        d="M67.275 54.137H33.388l4.519-6.777h12.095c3.911 0 7.749 1.063 11.103 3.076l6.17 3.701Z"
      />
      <path fill="#EFE1DA" d="M49.205 63.3h3.15v-3.15h-3.15v3.15Z" />
      <path
        fill="#303636"
        d="M70.33 77.369a1.452 1.452 0 1 0 0-2.905 1.452 1.452 0 0 0 0 2.905Z"
      />
      <path
        fill="#EFE1DA"
        d="M73.982 76.4h-.536a3.604 3.604 0 0 0-7.203 0h-.537a4.14 4.14 0 1 1 8.282 0h-.006Z"
      />
      <path
        fill="#303636"
        d="M78.68 77.287a1.452 1.452 0 1 0 0-2.905 1.452 1.452 0 0 0 0 2.905Z"
      />
      <path
        fill="#EFE1DA"
        d="M75.023 76.318h.538a3.605 3.605 0 0 1 7.203 0h.538a4.142 4.142 0 0 0-8.284 0h.005Z"
      />
      <path
        fill="#F96020"
        d="M73.206 7.35h1.323v19.845H53.361A19.845 19.845 0 0 1 73.206 7.35Z"
      />
      <path
        fill="#D74816"
        d="M74.529 7.35h1.323a19.845 19.845 0 0 1 19.845 19.845H74.529V7.35Z"
      />
      <path fill="#FFA520" d="M92.904 25.872H57.477v2.646h35.427v-2.646Z" />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace608;
