import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace568 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#F9C1C0" d="M150.675 0H0v150.675h150.675V0Z" />
      <path fill="#383838" d="M104.968 100.02H44.013v46.995h60.955V100.02Z" />
      <path
        fill="#7814BC"
        d="M104.821 100.735H73.784v46.28h31.037v-46.28Z"
        opacity={0.64}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path
        fill="#DF2D2C"
        d="M30.66 147.015H20.223v-13.23c0-13.818 11.242-25.064 25.063-25.064v10.437a14.646 14.646 0 0 0-14.626 14.627v13.23Z"
      />
      <path
        fill="#C12E58"
        d="M128.635 147.015h-10.437v-13.23a14.645 14.645 0 0 0-14.626-14.627v-10.437c13.818 0 25.063 11.244 25.063 25.064v13.23Z"
      />
      <path
        fill="#383838"
        d="M46.61 107.325h-6.616v15.876h6.615v-15.876ZM108.79 107.325h-6.615v15.876h6.615v-15.876Z"
      />
      <path
        fill="#F96020"
        d="M73.832 17.91v121.609l-50.906-38.18V46.191l50.906-28.282Z"
      />
      <path
        fill="#DF4624"
        d="M73.897 17.893v121.609l50.906-38.179V46.174L73.897 17.893Z"
      />
      <path
        fill="#883A62"
        d="M53.589 30.247 35.067 17.899l-4.911 6.139a10.911 10.911 0 0 0 2.468 15.895l8.615 5.743"
      />
      <path
        fill="#4D2F47"
        d="M94.728 30.198 113.25 17.85l4.912 6.14a10.9 10.9 0 0 1 2.262 8.49 10.911 10.911 0 0 1-4.729 7.404l-8.617 5.745"
      />
      <path
        fill="#542F5F"
        d="M75.429 110.093V50.834L35.922 90.341h19.753a19.751 19.751 0 0 1 19.754 19.752Z"
      />
      <path
        fill="#F0E49A"
        d="M35.922 90.34h14.815L75.43 65.65V50.833L35.922 90.341Z"
      />
      <path
        fill="#542F5F"
        d="M68.021 68.119a2.47 2.47 0 1 0 0-4.94 2.47 2.47 0 0 0 0 4.94Z"
      />
      <path
        fill="#172027"
        d="M75.429 110.093V50.834l39.506 39.507H95.181a19.752 19.752 0 0 0-19.752 19.752Z"
      />
      <path
        fill="#E9642A"
        d="M114.935 90.34H100.12L75.429 65.65V50.833l39.506 39.507Z"
      />
      <path
        fill="#172027"
        d="M82.836 68.119a2.47 2.47 0 1 0 0-4.94 2.47 2.47 0 0 0 0 4.94Z"
      />
      <path
        fill="#DBB9BE"
        d="m87.114 65.571 2.56-3.407a14.07 14.07 0 0 1 19.061-3.265l3.234 2.155-24.855 4.517Z"
      />
      <path
        fill="#EFE1DA"
        d="m111.847 60.968-2.557 3.408a14.06 14.06 0 0 1-11.256 5.628c-2.777 0-5.493-.822-7.804-2.363l-3.234-2.155 24.851-4.518Z"
      />
      <path
        fill="#4F4F8E"
        d="M98.534 70.215a6.777 6.777 0 1 0 0-13.554 6.777 6.777 0 0 0 0 13.553Z"
      />
      <path
        fill="#001A1B"
        d="M98.534 67.957a4.519 4.519 0 1 0 0-9.038 4.519 4.519 0 0 0 0 9.038Z"
      />
      <path
        fill="#4A2955"
        d="M84.854 54.277h33.885l-4.516-6.778h-12.096a21.58 21.58 0 0 0-11.103 3.07l-6.17 3.708Z"
      />
      <path fill="#EFE1DA" d="M102.924 60.29h-3.15v3.15h3.15v-3.15Z" />
      <path
        fill="#DBB9BE"
        d="m65.015 65.433-2.556-3.408a14.07 14.07 0 0 0-19.06-3.264l-3.234 2.154 24.85 4.518Z"
      />
      <path
        fill="#EFE1DA"
        d="m40.282 60.83 2.555 3.41a14.075 14.075 0 0 0 19.068 3.263l3.234-2.156-24.856-4.517Z"
      />
      <path
        fill="#4F4F8E"
        d="M53.595 70.076a6.777 6.777 0 1 0 0-13.553 6.777 6.777 0 0 0 0 13.553Z"
      />
      <path
        fill="#001A1B"
        d="M53.595 67.819a4.519 4.519 0 1 0 0-9.038 4.519 4.519 0 0 0 0 9.038Z"
      />
      <path
        fill="#4A2955"
        d="M67.275 54.137H33.388l4.519-6.776h12.095c3.911 0 7.75 1.063 11.103 3.075l6.17 3.701Z"
      />
      <path fill="#EFE1DA" d="M49.205 63.3h3.15v-3.15h-3.15v3.15Z" />
      <path
        fill="#F55B20"
        d="M74.264 65.64v14.984a5.683 5.683 0 0 1-5.586-6.702l1.506-8.282h4.08Z"
      />
      <path
        fill="#883A62"
        d="M74.12 65.64v14.984a5.683 5.683 0 0 0 5.587-6.702L78.2 65.64h-4.08Z"
      />
      <path
        fill="#FFA520"
        d="M103.586 18.522H74.589l1.877 1.503a20.365 20.365 0 0 0 27.12-1.503Z"
      />
      <path
        fill="#B98D22"
        d="M103.463 18.522H74.466l1.877-1.502a20.364 20.364 0 0 1 27.12 1.502Z"
      />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace568;
