import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace593 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#FDC4FF" d="M150.675 0H0v150.675h150.675V0Z" />
      <path fill="#383838" d="M104.968 100.02H44.013v46.995h60.955V100.02Z" />
      <path
        fill="#7814BC"
        d="M104.821 100.735H73.784v46.28h31.037v-46.28Z"
        opacity={0.64}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path
        fill="#DF2D2C"
        d="M30.66 147.015H20.223v-13.23c0-13.818 11.242-25.064 25.063-25.064v10.437a14.646 14.646 0 0 0-14.626 14.627v13.23Z"
      />
      <path
        fill="#C12E58"
        d="M128.635 147.015h-10.437v-13.23a14.645 14.645 0 0 0-14.626-14.627v-10.437c13.818 0 25.063 11.244 25.063 25.064v13.23Z"
      />
      <path
        fill="#383838"
        d="M46.61 107.325h-6.616v15.876h6.615v-15.876ZM108.79 107.325h-6.615v15.876h6.615v-15.876Z"
      />
      <path
        fill="#DF2D2C"
        d="M74.62 21.912H63.235a29.466 29.466 0 0 0-26.14 15.867L16.072 78.204l32.487 37.61a34.432 34.432 0 0 0 26.06 11.929V21.912Z"
      />
      <path
        fill="#C12E58"
        d="M74.62 21.912h11.384a29.467 29.467 0 0 1 26.141 15.867l21.021 40.425-32.487 37.608a34.436 34.436 0 0 1-26.059 11.931V21.912Z"
      />
      <path
        fill="#F26227"
        d="M20.827 78.892a9.002 9.002 0 1 0 0-18.004 9.002 9.002 0 0 0 0 18.004Z"
      />
      <path
        fill="#D74B27"
        d="M20.827 77.77a7.88 7.88 0 1 0 0-15.76 7.88 7.88 0 0 0 0 15.76Z"
      />
      <path
        fill="#F79421"
        d="M20.48 62.637h.709c.048.007.097.01.146.01.206.018.41.038.615.07.484.075.959.198 1.417.37a7.286 7.286 0 0 1 4.364 4.557 6.973 6.973 0 0 1 .336 1.85.218.218 0 0 0 .01.11v.589a.201.201 0 0 0-.01.11 7.036 7.036 0 0 1-.806 2.937 7.248 7.248 0 0 1-8.423 3.643 7.214 7.214 0 0 1-4.034-2.911 7.218 7.218 0 0 1-1.227-3.47 1.822 1.822 0 0 0-.022-.254v-.696a.084.084 0 0 0 .01-.053c.016-.315.053-.628.112-.938a7.25 7.25 0 0 1 6.637-5.907 1.34 1.34 0 0 0 .166-.017Zm-5.702 7.257a6.053 6.053 0 1 0 3.731-5.598 6.046 6.046 0 0 0-3.731 5.598Z"
      />
      <path
        fill="#F79421"
        d="M22.651 69.68c.257.042.506.12.742.229a1.6 1.6 0 0 1 .716.636c.111.194.181.408.206.63.038.28.026.566-.037.842a1.478 1.478 0 0 1-.923 1.088c-.252.1-.515.17-.783.207-.181.028-.362.049-.546.059-.063 0-.064 0-.064.07v1.078c0 .067 0 .067-.07.067h-.786c-.076 0-.072 0-.072-.07v-1.042c0-.087.008-.078-.08-.078h-.784c-.063 0-.063 0-.063.064v1.05c0 .08.007.076-.074.076h-.782c-.074 0-.075 0-.075-.076v-1.078c0-.025-.009-.04-.035-.036-.027.003-.038 0-.056 0h-1.717c-.03 0-.046 0-.046-.043v-.772c0-.036.015-.047.05-.045h.48c.099-.002.197-.015.294-.038a.259.259 0 0 0 .21-.214c.028-.126.04-.256.038-.386V67.86a1.691 1.691 0 0 0-.035-.365.276.276 0 0 0-.233-.228 1.879 1.879 0 0 0-.373-.033h-.361c-.064 0-.064 0-.064-.067v-.716c0-.082 0-.076.074-.076h1.702c.076 0 .076 0 .076-.075v-1.07c0-.02.014-.037.039-.035h.814c.082 0 .075-.01.075.073v1.022c0 .095 0 .085.085.085h.78c.063 0 .063 0 .063-.068V65.23c0-.016.01-.038.036-.037h.815c.08 0 .076-.012.076.08v1.062c0 .053 0 .052.055.056a4.5 4.5 0 0 1 .827.134c.152.04.3.095.441.163a1.248 1.248 0 0 1 .684.823c.09.32.095.66.016.982a1.402 1.402 0 0 1-.566.816c-.222.153-.466.27-.725.344-.019.006-.035.002-.044.027Zm-2.544 1.681v1.126c0 .06 0 .06.06.06h.407a5.9 5.9 0 0 0 .867-.04 2.09 2.09 0 0 0 .497-.127.857.857 0 0 0 .515-.564c.08-.263.092-.542.035-.81a.85.85 0 0 0-.38-.575 1.22 1.22 0 0 0-.365-.147 3.182 3.182 0 0 0-.625-.088c-.323-.02-.648 0-.973-.01-.034 0-.046.012-.043.043.003.031 0 .033 0 .049l.005 1.083Zm0-3.087v1c0 .034.007.051.045.05.35.01.7 0 1.048-.03.17-.019.338-.06.499-.121a.755.755 0 0 0 .467-.54c.054-.214.061-.437.022-.654a.735.735 0 0 0-.517-.62 2.455 2.455 0 0 0-.682-.121c-.274-.013-.547 0-.82 0h-.036a.029.029 0 0 0-.024.009.03.03 0 0 0-.008.025v.056c.003.318.004.634.004.946h.002Z"
      />
      <path
        fill="#F26227"
        d="M131.959 79.33a9.002 9.002 0 0 0 9.002-9.002 9.002 9.002 0 1 0-18.004 0 9.002 9.002 0 0 0 9.002 9.002Z"
      />
      <path
        fill="#D74B27"
        d="M131.959 78.207a7.879 7.879 0 0 0 7.879-7.879 7.879 7.879 0 1 0-15.758 0 7.88 7.88 0 0 0 7.879 7.88Z"
      />
      <path
        fill="#F79421"
        d="M131.612 63.074h.709a.768.768 0 0 0 .147.01 7.118 7.118 0 0 1 2.028.441 7.282 7.282 0 0 1 4.365 4.557 7.026 7.026 0 0 1 .335 1.85.223.223 0 0 0 .01.112v.588a.213.213 0 0 0-.01.112 6.03 6.03 0 0 1-.071.697 7.028 7.028 0 0 1-.735 2.239 7.261 7.261 0 0 1-8.423 3.644 7.218 7.218 0 0 1-4.033-2.91 7.225 7.225 0 0 1-1.228-3.47 1.826 1.826 0 0 0-.022-.256V70a.086.086 0 0 0 .01-.055c.016-.314.054-.627.112-.936a7.088 7.088 0 0 1 .601-1.827 7.282 7.282 0 0 1 4.769-3.881c.416-.103.84-.17 1.267-.2a1.05 1.05 0 0 0 .169-.026Zm-5.702 7.257a6.052 6.052 0 1 0 12.105-.004 6.052 6.052 0 0 0-12.105.004Z"
      />
      <path
        fill="#F79421"
        d="M133.784 70.116c.254.043.501.12.735.23.298.134.548.356.715.637.112.194.182.408.206.63.038.28.026.566-.036.842a1.476 1.476 0 0 1-.924 1.086c-.252.1-.515.17-.783.208a6.162 6.162 0 0 1-.546.06c-.063 0-.064 0-.064.069v1.077c0 .068 0 .07-.069.07h-.787c-.076 0-.072 0-.072-.073V73.91c0-.087 0-.078-.079-.078h-.785c-.063 0-.063 0-.063.066v1.05c0 .08.007.076-.074.076h-.775c-.073 0-.074 0-.074-.078V73.87c0-.025-.009-.038-.036-.037H128.5c-.03 0-.045-.007-.045-.04v-.773c0-.038.015-.047.05-.047h.479c.099-.002.198-.015.294-.038a.259.259 0 0 0 .21-.213c.028-.126.041-.256.038-.385v-4.038a1.667 1.667 0 0 0-.035-.366.276.276 0 0 0-.232-.228 1.877 1.877 0 0 0-.373-.034h-.362c-.063 0-.063 0-.063-.066v-.716c0-.08 0-.076.073-.076h1.702c.077 0 .077 0 .077-.074v-1.07c0-.018.013-.038.038-.037h.815c.082 0 .074-.007.074.074v1.021c0 .096-.007.086.086.086h.779c.063 0 .063 0 .063-.067v-1.077c0-.016.01-.038.037-.037h.814c.081 0 .077-.01.077.081v1.064c0 .053 0 .053.054.056.279.02.557.065.828.135.152.04.299.094.441.162a1.253 1.253 0 0 1 .683.823c.09.321.096.66.016.984a1.393 1.393 0 0 1-.566.814c-.221.153-.466.27-.724.344-.019.002-.036-.001-.044.022Zm-2.545 1.682v1.126c0 .06 0 .06.06.06h.407c.29.007.58-.007.868-.042.17-.022.337-.064.497-.125a.86.86 0 0 0 .514-.566c.08-.263.093-.542.035-.81a.844.844 0 0 0-.379-.575 1.222 1.222 0 0 0-.366-.147 3.416 3.416 0 0 0-.625-.088c-.323-.02-.648 0-.973-.01-.034 0-.045.013-.042.045.003.032 0 .032 0 .048.003.362.003.722.004 1.084Zm0-3.087v1.001c0 .032.007.05.046.048.349.01.699 0 1.048-.029.17-.02.338-.06.498-.122a.753.753 0 0 0 .467-.538c.054-.215.062-.438.023-.656a.74.74 0 0 0-.518-.62 2.444 2.444 0 0 0-.682-.12c-.273-.014-.547 0-.82-.006h-.036c-.022 0-.033.01-.032.033v.056l.005.953h.001Z"
      />
      <path
        fill="#542F5F"
        d="M44.98 33.81v9.408a3.336 3.336 0 0 1-3.329 3.324h-3.065a14.36 14.36 0 0 0-5.523 1.104 12.804 12.804 0 0 1-16.484-6.321l-.653-1.358A12.727 12.727 0 0 1 30.7 22.197l13.121 3.5H27.607a5.79 5.79 0 1 0 0 11.58h.713c1.05.002 2.079-.282 2.98-.82l5.991-3.596a6.292 6.292 0 0 1 7.688.946v.003Z"
      />
      <path
        fill="#111C0D"
        d="M106.278 33.834v9.408a3.336 3.336 0 0 0 3.334 3.334h3.059c1.896 0 3.772.375 5.522 1.104a12.805 12.805 0 0 0 16.486-6.314l.65-1.365a12.73 12.73 0 0 0-6.479-17.171 12.726 12.726 0 0 0-8.292-.599l-13.123 3.494h16.214a5.794 5.794 0 0 1 5.35 3.576 5.793 5.793 0 0 1-3.133 7.566 5.791 5.791 0 0 1-2.217.44h-.707a5.793 5.793 0 0 1-2.979-.824l-5.994-3.596a6.29 6.29 0 0 0-7.686.947h-.005Z"
      />
      <path fill="#FA8CB2" d="M78.161 81.43H46.665V96.25h31.496V81.43Z" />
      <path fill="#EFE1DA" d="M78.163 83.282H50.37v11.116h27.792V83.282Z" />
      <path
        fill="#001A1B"
        d="M55.93 83.282h-1.853v11.116h1.852V83.282ZM61.487 83.282h-1.852v11.116h1.852V83.282ZM67.05 83.276h-1.852v11.116h1.852V83.276ZM72.608 83.276h-1.853v11.116h1.853V83.276Z"
      />
      <path fill="#F46461" d="M73.526 96.256h28.732V81.434H73.527v14.822Z" />
      <path fill="#EFE1DA" d="M72.599 94.404h25.954V83.288H72.6v11.116Z" />
      <path
        fill="#001A1B"
        d="M92.994 94.404h1.852V83.288h-1.852v11.116ZM87.436 94.404h1.852V83.288h-1.852v11.116ZM81.873 94.398h1.852V83.282h-1.852v11.116ZM76.315 94.398h1.852V83.282h-1.852v11.116Z"
      />
      <path
        fill="#883A62"
        d="M100.419 82.356h-51.93v6.491h51.93v-6.491Z"
        opacity={0.3}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path
        stroke="#F96020"
        strokeMiterlimit={10}
        strokeWidth={3.61}
        d="M58.237 67.13h30.48"
      />
      <path
        fill="#F96020"
        d="M53.858 83.79c5.536 0 10.024-4.488 10.024-10.024 0-5.536-4.488-10.024-10.024-10.024-5.536 0-10.024 4.488-10.024 10.024 0 5.536 4.488 10.024 10.024 10.024Z"
      />
      <path
        fill="#D74816"
        d="M53.856 82.54a8.773 8.773 0 1 0 0-17.547 8.773 8.773 0 0 0 0 17.546Z"
      />
      <path
        fill="#F96020"
        d="M95.628 84.062c5.536 0 10.024-4.488 10.024-10.024 0-5.536-4.488-10.024-10.024-10.024-5.536 0-10.024 4.488-10.024 10.024 0 5.536 4.488 10.024 10.024 10.024Z"
      />
      <path
        fill="#D74816"
        d="M95.628 82.811a8.773 8.773 0 1 0 0-17.546 8.773 8.773 0 0 0 0 17.546Z"
      />
      <path
        fill="#FFA520"
        d="m45.864 77.06 4.06 4.06 11.061-11.062L56.926 66 45.864 77.061ZM88.549 77.746l4.059 4.059 11.061-11.062-4.059-4.059L88.55 77.746Z"
      />
      <path
        fill="#3D2F39"
        d="m102.258 52.511-13.733 4.974a7.528 7.528 0 0 0-4.515 9.642l.312.86a7.528 7.528 0 0 0 9.642 4.515l13.733-4.974a7.528 7.528 0 0 0 4.514-9.642l-.312-.86a7.527 7.527 0 0 0-9.641-4.515Z"
      />
      <path
        fill="#EB6447"
        d="M94.895 70.51a6.844 6.844 0 1 0 0-13.689 6.844 6.844 0 0 0 0 13.689Z"
      />
      <path
        fill="#3D2F39"
        d="m93.011 58.287-.122.044c-.558.202-.847.82-.645 1.378l3.152 8.703c.203.56.82.848 1.379.646l.121-.044c.559-.203.848-.82.645-1.379l-3.152-8.703a1.076 1.076 0 0 0-1.378-.645Z"
      />
      <path
        fill="#80191B"
        d="m40.48 67.746 13.734 4.974a7.528 7.528 0 0 0 9.641-4.515l.312-.86a7.528 7.528 0 0 0-4.514-9.642L45.92 52.729a7.528 7.528 0 0 0-9.642 4.514l-.312.861a7.528 7.528 0 0 0 4.515 9.642Z"
      />
      <path
        fill="#EB6447"
        d="M53.29 70.733a6.844 6.844 0 1 0 0-13.688 6.844 6.844 0 0 0 0 13.688Z"
      />
      <path
        fill="#3D2F39"
        d="m51.28 69.231.123.044a1.076 1.076 0 0 0 1.378-.645l3.152-8.703a1.076 1.076 0 0 0-.645-1.379l-.122-.044a1.076 1.076 0 0 0-1.378.646l-3.152 8.703a1.076 1.076 0 0 0 .645 1.378Z"
      />
      <path
        fill="#883A62"
        d="M75.792 53.035a16.417 16.417 0 0 1 16.417 16.417v18.534H59.376V69.452a16.417 16.417 0 0 1 16.416-16.417Z"
        opacity={0.7}
      />
      <path
        fill="#FA8CB2"
        d="M68.667 79.454h8.773v-14.71h-6.066a3.592 3.592 0 0 0-3.38 2.377l-2.708 7.522a3.595 3.595 0 0 0 3.38 4.81Z"
      />
      <path
        fill="#542F5F"
        d="M70.556 74.276a2.647 2.647 0 1 0 0-5.294 2.647 2.647 0 0 0 0 5.294Z"
      />
      <path
        fill="#F46461"
        d="M82.447 79.513h-6.714v-14.71h4.006a3.595 3.595 0 0 1 3.38 2.376l2.709 7.522a3.595 3.595 0 0 1-3.382 4.812Z"
      />
      <path
        fill="#172027"
        d="M80.558 74.17a2.647 2.647 0 1 0 0-5.294 2.647 2.647 0 0 0 0 5.294Z"
      />
      <path
        fill="#AF3271"
        d="m74.145 30.37-.051-5.233a2.307 2.307 0 0 0 2.143-1.45l4.307-10.768h2.75c6.476 0 9.894 7.673 5.56 12.495l-4.47 4.956H74.145Z"
      />
      <path
        fill="#E9632A"
        d="M74.165 20.636a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path
        fill="#E9632A"
        d="M74.165 25.174a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path
        fill="#DE2F7A"
        d="M74.378 25.137h-.183a2.308 2.308 0 0 1-2.143-1.45l-4.307-10.771h-2.749c-6.477 0-9.894 7.673-5.56 12.495l4.47 4.967h10.24"
      />
      <path fill="#4F4F8E" d="M74.494 30.37H63.905v4.538h10.589V30.37Z" />
      <path fill="#532D5C" d="M74.144 34.908h10.24V30.37h-10.24v4.538Z" />
      <path
        fill="#EB6329"
        d="M74.203 16.059a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path fill="#C84626" d="M74.203 16.135a2.27 2.27 0 1 1 0 4.54" />
      <path
        fill="#C84626"
        d="M74.203 20.596a2.267 2.267 0 0 1 2.327 2.27 2.27 2.27 0 0 1-2.327 2.268"
      />
      <path
        fill="#E8582C"
        d="M74.145 30.37h-10.24v.699h10.24v-.699ZM74.145 34.56h-10.24v.698h10.24v-.698Z"
      />
      <path
        fill="#F4ACC5"
        d="M74.145 31.03h-10.24v.389h10.24v-.389ZM74.145 34.172h-10.24v.388h10.24v-.388ZM74.145 32.115h-.698v1.397h.698v-1.397Z"
      />
      <path
        fill="#E9632A"
        d="m58.374 17.024-.108.162 13.615 9.076.107-.161-13.614-9.077ZM57.658 20.856l-.07.181 13.615 5.238.07-.182-13.614-5.237ZM58.682 23.988l-.035.191 11.52 2.094.036-.192-11.521-2.093Z"
      />
      <path
        fill="#C9481D"
        d="M74.144 31.069h10.24v-.698h-10.24v.698ZM74.144 35.257h10.24v-.699h-10.24v.699Z"
      />
      <path
        fill="#F28DB2"
        d="M74.144 31.417h10.24v-.388h-10.24v.388ZM74.144 34.56h10.24v-.388h-10.24v.388ZM74.144 33.513h.698v-1.396h-.698v1.396Z"
      />
      <path
        fill="#C84626"
        d="m89.917 17.024-13.614 9.077.107.161 13.615-9.076-.108-.162ZM90.633 20.85l-13.615 5.236.07.181 13.614-5.237-.07-.18ZM89.606 23.986l-11.52 2.095.034.192 11.52-2.096-.034-.191ZM74.203 11.48a2.269 2.269 0 1 1 0 4.539"
      />
      <path
        fill="#EA6428"
        d="m79.388 7.856-5.184-5.184-5.184 5.184 5.184 5.184 5.184-5.184Z"
      />
      <path
        fill="#532D5A"
        d="m78.4 7.856-4.196-4.197-4.197 4.197 4.197 4.196L78.4 7.856Z"
      />
      <path
        fill="#BA3272"
        d="M74.203 10.124a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path fill="#BA3272" d="M74.379 0h-.35v7.68h.35V0Z" />
      <path
        fill="#E9632A"
        d="m69.41 23.932-4.492-11.043h2.842l4.307 10.766a2.308 2.308 0 0 0 2.143 1.451l-.012 1.219h-1.19a3.875 3.875 0 0 1-3.597-2.393Z"
      />
      <path
        fill="#C84626"
        d="m78.954 23.92 4.494-11.041h-2.842l-4.307 10.766a2.309 2.309 0 0 1-2.145 1.451l.012 1.219h1.19a3.872 3.872 0 0 0 3.598-2.395Z"
      />
      <path fill="#F4ACC5" d="M74.03 26.181H69.49v1.396h4.538v-1.396Z" />
      <path
        fill="#2D2044"
        d="M74.145 26.181v4.19a1.745 1.745 0 0 1-1.745-1.745v-.696a1.743 1.743 0 0 1 1.745-1.749Z"
      />
      <path fill="#4F4F8E" d="M74.144 26.879h-1.047v2.793h1.047v-2.793Z" />
      <path fill="#F28DB2" d="M74.262 27.579H78.8v-1.397h-4.538v1.397Z" />
      <path
        fill="#172027"
        d="M74.145 30.37v-4.19a1.745 1.745 0 0 1 1.745 1.746v.695a1.743 1.743 0 0 1-1.745 1.75Z"
      />
      <path fill="#532D5C" d="M74.107 29.672h1.047V26.88h-1.047v2.793Z" />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace593;
