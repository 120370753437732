import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace109 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#F8F3C1" d="M150.675 0H0v150.675h150.675V0Z" />
      <path fill="#A02A15" d="M120.641 69.09H30.155v79.847h90.486V69.09Z" />
      <path
        fill="#DE2F7A"
        d="M120.224 144.462H30.26v4.475h89.964v-4.475ZM120.224 138.869H30.26v4.475h89.964v-4.475ZM120.224 133.274H30.26v4.475h89.964v-4.475ZM120.224 127.681H30.26v4.474h89.964v-4.474ZM120.224 122.088H30.26v4.474h89.964v-4.474ZM120.224 116.494H30.26v4.475h89.964v-4.475ZM120.224 110.901H30.26v4.474h89.964v-4.474ZM120.224 105.306H30.26v4.475h89.964v-4.475ZM120.224 99.713H30.26v4.474h89.964v-4.474ZM120.224 94.12H30.26v4.474h89.964v-4.475ZM120.224 88.526H30.26V93h89.964v-4.475ZM120.224 82.933H30.26v4.474h89.964v-4.474Z"
      />
      <path
        fill="#7814BC"
        d="M120.696 74.868H74.793v74.069h45.903V74.868Z"
        opacity={0.34}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path
        fill="#727FBD"
        d="M74.842 22.594c27.565 0 51.234 22.344 51.234 49.91v34.785H74.239"
      />
      <path
        fill="#AAAECC"
        d="M76.813 22.557H75.49c-27.564 0-49.908 22.345-49.908 49.914v34.818h50.245"
      />
      <path
        fill="#727FBD"
        d="M75.827 126.009h14.582a43.327 43.327 0 0 0 35.663-18.72H75.827v18.72Z"
      />
      <path
        fill="#AAAECC"
        d="M75.827 126.009H61.242a43.32 43.32 0 0 1-35.66-18.72h50.245v18.72Z"
      />
      <path
        fill="#F26227"
        d="M20.827 78.892a9.002 9.002 0 1 0 0-18.005 9.002 9.002 0 0 0 0 18.005Z"
      />
      <path
        fill="#D74B27"
        d="M20.827 77.768a7.88 7.88 0 1 0 0-15.758 7.88 7.88 0 0 0 0 15.758Z"
      />
      <path
        fill="#F79421"
        d="M20.48 62.636h.709c.048.008.097.011.147.01.205.018.41.039.614.071.484.074.959.198 1.417.37a7.284 7.284 0 0 1 4.365 4.556 6.973 6.973 0 0 1 .335 1.85.217.217 0 0 0 .01.111v.588a.2.2 0 0 0-.01.11 7.037 7.037 0 0 1-.806 2.937 7.248 7.248 0 0 1-8.423 3.643 7.213 7.213 0 0 1-4.034-2.91 7.215 7.215 0 0 1-1.227-3.47 1.838 1.838 0 0 0-.022-.254V69.551a.083.083 0 0 0 .01-.053c.016-.315.053-.628.112-.938a7.25 7.25 0 0 1 6.637-5.907 1.34 1.34 0 0 0 .166-.017Zm-5.702 7.258a6.051 6.051 0 1 0 6.049-6.058 6.045 6.045 0 0 0-6.049 6.058Z"
      />
      <path
        fill="#F79421"
        d="M22.651 69.68c.257.042.507.119.743.228a1.6 1.6 0 0 1 .716.637c.11.194.18.408.205.63.038.28.026.565-.036.841a1.48 1.48 0 0 1-.924 1.088c-.252.1-.515.17-.783.208-.18.028-.362.048-.546.058-.063 0-.064 0-.064.071v1.078c0 .067 0 .067-.07.067h-.786c-.076 0-.072 0-.072-.07v-1.043c0-.086.008-.078-.08-.078h-.784c-.063 0-.063 0-.063.065v1.05c0 .08.007.076-.074.076h-.782c-.073 0-.075 0-.075-.076v-1.078c0-.025-.009-.04-.035-.037-.027.003-.038 0-.056 0h-1.717c-.03 0-.045 0-.045-.042v-.772c0-.037.014-.047.05-.046h.479c.099-.002.197-.014.294-.038a.259.259 0 0 0 .21-.213 1.61 1.61 0 0 0 .038-.387V67.86a1.69 1.69 0 0 0-.035-.364.277.277 0 0 0-.232-.228 1.879 1.879 0 0 0-.374-.034h-.361c-.064 0-.064 0-.064-.066v-.716c0-.082 0-.076.074-.076h1.702c.077 0 .077 0 .077-.075V65.23c0-.02.013-.037.038-.036h.814c.082 0 .075-.009.075.074v1.021c0 .096 0 .086.085.086h.78c.063 0 .063 0 .063-.068V65.229c0-.016.01-.038.037-.036h.814c.08 0 .076-.012.076.08v1.062c0 .053 0 .051.055.056a4.5 4.5 0 0 1 .827.134c.152.04.3.094.441.163a1.25 1.25 0 0 1 .684.823c.09.32.095.659.016.982a1.402 1.402 0 0 1-.566.816 2.5 2.5 0 0 1-.725.344c-.019.006-.035.001-.044.026Zm-2.544 1.68v1.127c0 .06 0 .06.06.06h.407a5.9 5.9 0 0 0 .867-.041 2.09 2.09 0 0 0 .497-.126.857.857 0 0 0 .515-.565c.08-.263.092-.541.035-.81a.85.85 0 0 0-.38-.575 1.22 1.22 0 0 0-.365-.147 3.182 3.182 0 0 0-.625-.088c-.323-.019-.648 0-.973-.01-.034 0-.046.013-.043.044.003.03 0 .032 0 .049l.005 1.083Zm0-3.086v1c0 .033.007.051.045.05.35.01.7 0 1.048-.03.171-.02.338-.06.499-.122a.754.754 0 0 0 .467-.54c.054-.213.061-.436.022-.654a.735.735 0 0 0-.517-.62 2.454 2.454 0 0 0-.682-.12c-.274-.014-.547 0-.82 0h-.036a.03.03 0 0 0-.032.033v.056c.003.319.004.634.004.947h.002Z"
      />
      <path
        fill="#F26227"
        d="M131.959 79.33a9.002 9.002 0 0 0 9.002-9.003 9.002 9.002 0 0 0-9.002-9.002 9.002 9.002 0 0 0-9.002 9.002 9.002 9.002 0 0 0 9.002 9.003Z"
      />
      <path
        fill="#D74B27"
        d="M131.959 78.207a7.879 7.879 0 0 0 7.879-7.88 7.879 7.879 0 1 0-15.758 0 7.88 7.88 0 0 0 7.879 7.88Z"
      />
      <path
        fill="#F79421"
        d="M131.612 63.073h.709a.754.754 0 0 0 .147.01 7.118 7.118 0 0 1 2.028.441 7.283 7.283 0 0 1 4.365 4.557 7.026 7.026 0 0 1 .335 1.851.223.223 0 0 0 .01.112v.588a.203.203 0 0 0-.01.111 7.029 7.029 0 0 1-.806 2.936 7.262 7.262 0 0 1-8.423 3.644 7.222 7.222 0 0 1-4.034-2.91 7.233 7.233 0 0 1-1.227-3.47 1.826 1.826 0 0 0-.022-.255V69.999a.086.086 0 0 0 .01-.055c.016-.315.053-.627.112-.937a7.051 7.051 0 0 1 .601-1.827 7.28 7.28 0 0 1 4.769-3.88c.416-.104.84-.171 1.267-.2.057-.005.114-.014.169-.027Zm-5.702 7.257a6.051 6.051 0 1 0 12.103-.002 6.051 6.051 0 0 0-12.103.002Z"
      />
      <path
        fill="#F79421"
        d="M133.783 70.116c.254.043.502.12.735.229.299.134.549.357.716.638.111.193.182.407.206.63.038.28.025.565-.037.841a1.463 1.463 0 0 1-.923 1.087c-.252.1-.515.17-.783.207a6.124 6.124 0 0 1-.546.06c-.063 0-.064 0-.064.07v1.077c0 .068 0 .069-.07.069h-.786c-.076 0-.072 0-.072-.072V73.91c0-.087 0-.078-.079-.078h-.785c-.064 0-.064 0-.064.066v1.05c0 .079.008.076-.073.076h-.775c-.073 0-.075 0-.075-.078V73.87c0-.025-.009-.039-.035-.037H128.5c-.031 0-.045-.007-.045-.041v-.772c0-.038.014-.047.05-.047h.479a1.38 1.38 0 0 0 .294-.038.26.26 0 0 0 .21-.213c.028-.127.041-.256.038-.386v-4.038a1.666 1.666 0 0 0-.035-.366.277.277 0 0 0-.232-.228 1.88 1.88 0 0 0-.374-.033h-.361c-.064 0-.064 0-.064-.067v-.715c0-.081 0-.077.074-.077h1.702c.077 0 .077 0 .077-.073v-1.07c0-.018.013-.039.038-.037h.814c.083 0 .075-.008.075.073v1.022c0 .095-.007.085.085.085h.78c.063 0 .063 0 .063-.066v-1.077c0-.017.01-.039.037-.037h.814c.081 0 .076-.01.076.08v1.065c0 .053 0 .053.055.056.279.019.556.064.827.135.152.04.3.094.441.162a1.247 1.247 0 0 1 .684.823c.09.32.095.66.016.983a1.393 1.393 0 0 1-.566.815c-.221.153-.466.27-.725.344-.019.001-.035-.002-.044.022Zm-2.544 1.681v1.126c0 .06 0 .06.06.06h.407c.29.008.58-.007.868-.042.17-.021.337-.063.496-.125a.858.858 0 0 0 .515-.566c.08-.263.092-.541.035-.81a.842.842 0 0 0-.379-.575 1.22 1.22 0 0 0-.366-.147 3.416 3.416 0 0 0-.625-.088c-.323-.019-.648 0-.973-.01-.034 0-.046.013-.043.045.003.033 0 .033 0 .049.003.362.003.722.005 1.083Zm0-3.087v1.001c0 .033.007.05.045.049.35.01.7 0 1.048-.03.171-.019.339-.06.499-.121a.747.747 0 0 0 .467-.538c.054-.215.061-.439.022-.656a.733.733 0 0 0-.517-.62 2.45 2.45 0 0 0-.682-.12c-.274-.014-.547 0-.82-.007h-.036c-.022 0-.034.01-.032.034v.056l.004.952h.002Z"
      />
      <path
        fill="#BA3D11"
        d="m44.33 40.411-.974-3.788c-2.499-9.735-14.272-13.574-22.035-7.188L5.886 42.139l22.44 5.465 10.692-8.796 2.94 3.567"
      />
      <path
        fill="#A31905"
        d="m105.314 40.522.974-3.788c2.499-9.736 14.272-13.574 22.036-7.189l15.442 12.706-22.438 5.464-10.702-8.804-2.94 3.567"
      />
      <path
        fill="#4A2955"
        d="M87.275 111.119v7.363l-4.09-4.417v13.252l-6.821-7.364-2.729 8.837v-16.198"
      />
      <path
        fill="#161F26"
        d="M59.886 110.892v7.362l4.091-4.417v13.252l6.821-7.362 2.729 8.835v-16.198"
      />
      <path
        fill="#883A62"
        d="M34.078 81.71c6.882 19.095 22.264 32.393 40.144 32.393s33.263-13.298 40.144-32.393H34.078Z"
      />
      <path
        fill="#001A1B"
        d="M36.851 85.1c6.307 15.141 20.404 25.695 36.784 25.695s30.482-10.541 36.786-25.695h-73.57Z"
      />
      <path
        fill="#EFE1DA"
        d="M93.755 84.654v16.197l-8.185-17.67M81.478 84.654v7.362l-2.728-8.835M77.385 84.654v5.89h-2.727v-5.89"
      />
      <path
        fill="#DBB9BE"
        d="m89.663 108.214-8.185-8.835v11.779M78.75 111.159v-10.308h-4.092v11.781"
      />
      <path
        fill="#EFE1DA"
        d="M54.565 84.688v16.198l8.185-17.67M66.842 84.688v7.363l2.729-8.834M70.935 84.688v5.89h2.728v-5.89"
      />
      <path
        fill="#DBB9BE"
        d="m58.657 108.248 8.185-8.835v11.779M69.57 111.192v-10.306h4.093v11.779"
      />
      <path
        fill="#883A62"
        d="M74.157 114.031c17.878 0 33.263-13.299 40.144-32.395H34.013c6.882 19.096 22.267 32.395 40.144 32.395Zm-.01-3.88c-14.71 0-27.372-10.023-33.034-24.416h66.068c-5.663 14.393-18.324 24.416-33.034 24.416Z"
      />
      <path
        stroke="#001A1B"
        strokeMiterlimit={10}
        strokeWidth={0.902}
        d="M74.115 111.333c-15.73 0-29.262-11.172-35.317-27.205h70.632c-6.048 16.038-19.59 27.205-35.316 27.205Z"
      />
      <path
        stroke="#F96020"
        strokeMiterlimit={10}
        strokeWidth={3.61}
        d="M58.237 67.129h30.48"
      />
      <path
        fill="#F96020"
        d="M53.858 83.79c5.536 0 10.024-4.488 10.024-10.024 0-5.536-4.488-10.024-10.024-10.024-5.536 0-10.024 4.488-10.024 10.024 0 5.536 4.488 10.024 10.024 10.024Z"
      />
      <path
        fill="#D74816"
        d="M53.856 82.539a8.773 8.773 0 1 0 0-17.546 8.773 8.773 0 0 0 0 17.546Z"
      />
      <path
        fill="#F96020"
        d="M95.628 84.062c5.536 0 10.024-4.488 10.024-10.024 0-5.536-4.488-10.024-10.024-10.024-5.536 0-10.024 4.488-10.024 10.024 0 5.536 4.488 10.024 10.024 10.024Z"
      />
      <path
        fill="#D74816"
        d="M95.628 82.81a8.773 8.773 0 1 0 0-17.546 8.773 8.773 0 0 0 0 17.547Z"
      />
      <path
        fill="#FFA520"
        d="m45.864 77.06 4.059 4.06 11.062-11.062-4.06-4.06-11.061 11.063ZM88.548 77.746l4.06 4.059 11.061-11.062-4.06-4.06-11.06 11.063Z"
      />
      <path
        fill="#DBB9BE"
        d="m87.114 65.57 2.56-3.407a14.069 14.069 0 0 1 19.06-3.265l3.234 2.155-24.855 4.518Z"
      />
      <path
        fill="#EFE1DA"
        d="m111.846 60.968-2.556 3.407a14.069 14.069 0 0 1-19.06 3.265l-3.234-2.155 24.85-4.517Z"
      />
      <path
        fill="#4F4F8E"
        d="M98.534 70.214a6.777 6.777 0 1 0 0-13.553 6.777 6.777 0 0 0 0 13.553Z"
      />
      <path
        fill="#001A1B"
        d="M98.534 67.956a4.52 4.52 0 1 0 0-9.039 4.52 4.52 0 0 0 0 9.04Z"
      />
      <path
        fill="#4A2955"
        d="M84.854 54.277h33.885l-4.516-6.779h-12.096a21.58 21.58 0 0 0-11.103 3.07l-6.17 3.709Z"
      />
      <path fill="#EFE1DA" d="M102.923 60.289h-3.15v3.15h3.15v-3.15Z" />
      <path
        fill="#DBB9BE"
        d="m65.015 65.433-2.556-3.408a14.07 14.07 0 0 0-19.06-3.265l-3.234 2.155 24.85 4.518Z"
      />
      <path
        fill="#EFE1DA"
        d="m40.282 60.83 2.555 3.409a14.074 14.074 0 0 0 19.068 3.263l3.234-2.155-24.857-4.517Z"
      />
      <path
        fill="#4F4F8E"
        d="M53.595 70.076a6.777 6.777 0 1 0 0-13.553 6.777 6.777 0 0 0 0 13.553Z"
      />
      <path
        fill="#001A1B"
        d="M53.594 67.818a4.519 4.519 0 1 0 0-9.037 4.519 4.519 0 0 0 0 9.037Z"
      />
      <path
        fill="#4A2955"
        d="M67.274 54.137H33.388l4.519-6.777h12.095c3.911 0 7.749 1.063 11.103 3.075l6.17 3.702Z"
      />
      <path fill="#EFE1DA" d="M49.205 63.3h3.15v-3.15h-3.15v3.15Z" />
      <path
        fill="#4D2F47"
        d="M79.997 79.674a5.5 5.5 0 0 1-5.5-5.501v-9.849h6.132a5.803 5.803 0 0 1 5.652 7.125l-.927 3.97a5.503 5.503 0 0 1-5.357 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M79.925 77.88a3.637 3.637 0 1 0 0-7.273 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M79.875 76.984a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path
        fill="#883A62"
        d="M69.015 79.63a5.5 5.5 0 0 0 5.5-5.501V64.28h-6.134a5.803 5.803 0 0 0-5.652 7.125l.93 3.97a5.5 5.5 0 0 0 5.356 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M69.084 77.836a3.637 3.637 0 1 0 0-7.273 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M69.134 76.94a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace109;
