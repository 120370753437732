import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace308 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#3A6A7E" d="M150.675 0H0v150.675h150.675V0Z" />
      <path fill="#A02A15" d="M120.642 69.09H30.156v79.847h90.486V69.09Z" />
      <path
        fill="#DE2F7A"
        d="M120.224 144.463H30.26v4.474h89.964v-4.474ZM120.224 138.869H30.26v4.475h89.964v-4.475ZM120.224 133.274H30.26v4.475h89.964v-4.475ZM120.224 127.681H30.26v4.475h89.964v-4.475ZM120.224 122.088H30.26v4.475h89.964v-4.475ZM120.224 116.494H30.26v4.475h89.964v-4.475ZM120.224 110.901H30.26v4.475h89.964v-4.475ZM120.224 105.306H30.26v4.475h89.964v-4.475ZM120.224 99.713H30.26v4.474h89.964v-4.474ZM120.224 94.12H30.26v4.474h89.964V94.12ZM120.224 88.526H30.26v4.475h89.964v-4.475ZM120.224 82.933H30.26v4.474h89.964v-4.474Z"
      />
      <path
        fill="#7814BC"
        d="M120.696 74.868H74.794v74.069h45.902V74.868Z"
        opacity={0.34}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path
        fill="#DBB9BE"
        d="M74.125 20.595V125.85L20.196 92.805V45.073l53.929-24.478Z"
      />
      <path
        fill="#BCAFB1"
        d="M74.192 20.58v105.256l53.929-33.045V45.058L74.192 20.58Z"
      />
      <path
        fill="#F26227"
        d="M20.827 78.892a9.002 9.002 0 1 0 0-18.005 9.002 9.002 0 0 0 0 18.005Z"
      />
      <path
        fill="#D74B27"
        d="M20.827 77.769a7.88 7.88 0 1 0 0-15.759 7.88 7.88 0 0 0 0 15.759Z"
      />
      <path
        fill="#F79421"
        d="M20.48 62.637h.709c.048.007.097.01.147.01.205.017.41.038.614.07.484.074.959.198 1.417.37a7.284 7.284 0 0 1 4.365 4.556 6.973 6.973 0 0 1 .335 1.851.217.217 0 0 0 .01.11v.588a.2.2 0 0 0-.01.11 7.036 7.036 0 0 1-.806 2.937 7.248 7.248 0 0 1-8.423 3.643 7.214 7.214 0 0 1-4.034-2.91 7.216 7.216 0 0 1-1.227-3.47 1.838 1.838 0 0 0-.022-.254v-.696a.084.084 0 0 0 .01-.053c.016-.315.053-.629.112-.938a7.25 7.25 0 0 1 6.637-5.906 1.34 1.34 0 0 0 .166-.018Zm-5.702 7.257a6.051 6.051 0 1 0 6.049-6.058 6.045 6.045 0 0 0-6.049 6.058Z"
      />
      <path
        fill="#F79421"
        d="M22.651 69.68c.257.042.507.12.743.229a1.6 1.6 0 0 1 .716.636c.11.194.18.408.205.63.038.28.026.565-.036.842a1.48 1.48 0 0 1-.924 1.088c-.252.1-.515.169-.783.207-.18.028-.362.048-.546.059-.063 0-.064 0-.064.07v1.078c0 .067 0 .067-.07.067h-.786c-.076 0-.072 0-.072-.07v-1.043c0-.086.008-.077-.08-.077h-.784c-.063 0-.063 0-.063.064v1.05c0 .08.007.076-.074.076h-.782c-.073 0-.075 0-.075-.076v-1.078c0-.025-.009-.04-.035-.036-.027.002-.038 0-.056 0h-1.717c-.03 0-.045 0-.045-.043v-.772c0-.037.014-.047.05-.045h.479c.099-.002.197-.015.294-.039a.26.26 0 0 0 .21-.213c.028-.127.041-.256.038-.386V67.86a1.69 1.69 0 0 0-.035-.365.277.277 0 0 0-.232-.228 1.879 1.879 0 0 0-.374-.034h-.361c-.064 0-.064 0-.064-.066v-.716c0-.082 0-.076.074-.076h1.702c.077 0 .077 0 .077-.075v-1.07c0-.02.013-.037.038-.036h.814c.082 0 .075-.008.075.074v1.022c0 .095 0 .085.085.085h.78c.063 0 .063 0 .063-.068V65.23c0-.016.01-.038.037-.037h.814c.08 0 .076-.012.076.08v1.062c0 .053 0 .052.055.056a4.5 4.5 0 0 1 .827.134c.152.04.3.094.441.163a1.25 1.25 0 0 1 .684.823c.09.32.095.659.016.982a1.403 1.403 0 0 1-.566.816 2.5 2.5 0 0 1-.725.344c-.019.006-.035.001-.044.026Zm-2.544 1.681v1.126c0 .06 0 .06.06.06h.407a5.9 5.9 0 0 0 .867-.04 2.09 2.09 0 0 0 .497-.127.856.856 0 0 0 .515-.565c.08-.262.092-.541.035-.81a.85.85 0 0 0-.38-.574 1.22 1.22 0 0 0-.365-.147 3.182 3.182 0 0 0-.625-.089c-.323-.019-.648 0-.973-.01-.034 0-.046.013-.043.044.003.031 0 .032 0 .049l.005 1.083Zm0-3.087v1c0 .034.007.051.045.05.35.01.7 0 1.048-.03.171-.019.338-.06.499-.122a.755.755 0 0 0 .467-.54c.054-.213.061-.436.022-.653a.735.735 0 0 0-.517-.62 2.458 2.458 0 0 0-.682-.121c-.274-.013-.547 0-.82 0h-.036a.03.03 0 0 0-.013.001.03.03 0 0 0-.019.032v.056c.003.319.004.635.004.947h.002Z"
      />
      <path
        fill="#F26227"
        d="M131.959 79.33a9.002 9.002 0 0 0 9.002-9.002 9.002 9.002 0 1 0-18.004 0 9.002 9.002 0 0 0 9.002 9.002Z"
      />
      <path
        fill="#D74B27"
        d="M131.959 78.207a7.879 7.879 0 0 0 7.879-7.88 7.879 7.879 0 1 0-15.758 0 7.88 7.88 0 0 0 7.879 7.88Z"
      />
      <path
        fill="#F79421"
        d="M131.612 63.073h.709a.754.754 0 0 0 .147.01c.205.018.41.04.614.071.483.075.957.199 1.414.37a7.284 7.284 0 0 1 4.365 4.558 7.026 7.026 0 0 1 .335 1.85.224.224 0 0 0 .01.112v.588a.203.203 0 0 0-.01.112 7.03 7.03 0 0 1-.806 2.936 7.262 7.262 0 0 1-8.423 3.643 7.223 7.223 0 0 1-4.034-2.91 7.234 7.234 0 0 1-1.227-3.47 1.827 1.827 0 0 0-.022-.255V69.999a.086.086 0 0 0 .01-.055c.016-.314.053-.627.112-.936a7.05 7.05 0 0 1 .601-1.828 7.277 7.277 0 0 1 4.769-3.88c.416-.104.84-.17 1.267-.2.057-.005.114-.013.169-.027Zm-5.702 7.258a6.05 6.05 0 1 0 6.049-6.053 6.045 6.045 0 0 0-6.049 6.053Z"
      />
      <path
        fill="#F79421"
        d="M133.783 70.116c.254.043.502.12.735.23.299.134.549.356.716.637.111.194.182.408.206.63.038.28.025.566-.037.842a1.463 1.463 0 0 1-.923 1.086c-.252.1-.515.17-.783.207a6.125 6.125 0 0 1-.546.06c-.063 0-.064 0-.064.07v1.077c0 .068 0 .07-.07.07h-.786c-.076 0-.072 0-.072-.073V73.91c0-.087 0-.078-.079-.078h-.785c-.064 0-.064 0-.064.066v1.05c0 .08.008.076-.073.076h-.775c-.073 0-.075 0-.075-.078V73.87c0-.025-.009-.038-.035-.037H128.5c-.031 0-.045-.007-.045-.04v-.773c0-.038.014-.047.05-.047h.479a1.38 1.38 0 0 0 .294-.038.26.26 0 0 0 .21-.213c.028-.127.041-.256.038-.385v-4.038a1.667 1.667 0 0 0-.035-.366.277.277 0 0 0-.232-.228 1.88 1.88 0 0 0-.374-.034h-.361c-.064 0-.064 0-.064-.066v-.716c0-.08 0-.077.074-.077h1.702c.077 0 .077 0 .077-.073v-1.07c0-.018.013-.038.038-.037h.814c.083 0 .075-.007.075.074v1.021c0 .096-.007.085.085.085h.78c.063 0 .063 0 .063-.066v-1.077c0-.016.01-.038.037-.037h.814c.081 0 .076-.01.076.08v1.065c0 .053 0 .053.055.056.279.02.556.065.827.135.152.04.3.094.441.162a1.248 1.248 0 0 1 .684.823c.09.321.095.66.016.984a1.393 1.393 0 0 1-.566.814c-.221.153-.466.27-.725.344-.019.002-.035-.001-.044.022Zm-2.544 1.682v1.126c0 .06 0 .06.06.06h.407c.29.007.58-.007.868-.043.17-.02.337-.063.496-.124a.857.857 0 0 0 .515-.566c.08-.263.092-.542.035-.81a.842.842 0 0 0-.379-.575 1.22 1.22 0 0 0-.366-.147 3.416 3.416 0 0 0-.625-.088c-.323-.02-.648 0-.973-.01-.034 0-.046.013-.043.045.003.032 0 .032 0 .048.003.362.003.722.005 1.084Zm0-3.087v1.001c0 .032.007.05.045.048.35.01.7 0 1.048-.03.171-.018.339-.06.499-.121a.747.747 0 0 0 .467-.538c.054-.215.061-.438.022-.656a.733.733 0 0 0-.517-.62 2.454 2.454 0 0 0-.682-.12c-.274-.014-.547 0-.82-.007h-.036c-.022 0-.034.01-.032.034v.056l.004.953h.002Z"
      />
      <path
        fill="#172027"
        d="m103.467 34.279 10.189-10.189a1.104 1.104 0 0 1 1.861.563 4.068 4.068 0 0 0 3.998 3.277 4.074 4.074 0 0 0 4.074-4.074V19.12a4.16 4.16 0 0 0-6.183-3.639l-1.595.882a.965.965 0 0 1-1.421-1.004l.198-1.189a2.734 2.734 0 0 0-2.699-3.185 2.733 2.733 0 0 0-2.63 1.984l-.916 3.189a1.072 1.072 0 0 1-.873.764 1.079 1.079 0 0 1-1.061-.47l-1.571-2.352a2.036 2.036 0 0 0-1.483-.887 2.022 2.022 0 0 0-1.627 3.441l1.838 1.838a1.62 1.62 0 0 1-.011 2.31c-.207.2-.463.341-.742.41a1.616 1.616 0 0 1-1.931-1.06l-1.051-3.152a2.598 2.598 0 0 0-2.466-1.777 2.598 2.598 0 0 0-2.262 3.888l.58 1.013a1.677 1.677 0 1 1-3.094 1.192l-.895-4.025a5.35 5.35 0 0 0-10.413-.137L77 34.28"
      />
      <path
        fill="#542F5F"
        d="M48.58 34.022 38.39 23.833a1.101 1.101 0 0 0-1.859.569 4.072 4.072 0 0 1-8.066-.798v-4.742a4.161 4.161 0 0 1 6.183-3.637l1.593.882a.966.966 0 0 0 1.422-1.003l-.197-1.19a2.736 2.736 0 0 1 5.329-1.198l.91 3.184a1.08 1.08 0 0 0 1.934.293l1.572-2.352a2.026 2.026 0 0 1 3.114-.308 2.026 2.026 0 0 1 0 2.862l-1.839 1.833a1.616 1.616 0 0 0 .755 2.72 1.617 1.617 0 0 0 1.931-1.06l1.05-3.15a2.598 2.598 0 0 1 2.466-1.772 2.6 2.6 0 0 1 2.258 3.888l-.579 1.013a1.676 1.676 0 0 0 2.505 2.14c.298-.238.505-.571.588-.943l.894-4.025a5.35 5.35 0 0 1 8.515-3.056 5.351 5.351 0 0 1 1.897 2.922l4.28 17.121"
      />
      <path
        fill="#BA3D11"
        d="M46.999 94.743a43.89 43.89 0 0 0 25.173 7.938h2.034v-7.938"
      />
      <path
        fill="#A31905"
        d="M101.58 94.702a43.901 43.901 0 0 1-25.175 7.938h-2.033v-7.938"
      />
      <path
        fill="#6C1908"
        d="m74.206 90.208-3.402-3.4-24.94 7.935h28.342v-4.535Z"
      />
      <path
        fill="#3E1B11"
        d="m74.372 90.167 3.401-3.4 24.94 7.935H74.372v-4.535Z"
      />
      <path fill="#E1D4D1" d="M59.79 78.123 48.504 66.835H59.69" />
      <path fill="#AEA4B2" d="m89.59 78.123 11.289-11.288H89.532" />
      <path fill="#959ACC" d="M113.787 60.711a12.53 12.53 0 1 0-25.06 0" />
      <path
        fill="#727FBD"
        d="M98.249 70.241a9.524 9.524 0 1 0 0-19.048 9.524 9.524 0 0 0 0 19.048Z"
      />
      <path
        fill="#213F7D"
        d="M98.089 64.517a3.8 3.8 0 1 0 0-7.601 3.8 3.8 0 0 0 0 7.6Z"
      />
      <path fill="#213F7D" d="m95.375 58.003-5.429 4.343h5.429" />
      <path
        fill="#131640"
        d="M98.089 63.432a2.715 2.715 0 1 0 0-5.43 2.715 2.715 0 0 0 0 5.43Z"
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M85.5 63.07v-8.872a15.187 15.187 0 0 1 15.186-15.187h2.859"
        opacity={0.49}
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path fill="#D0BAC3" d="M35.594 60.711a12.532 12.532 0 0 1 25.062 0" />
      <path
        fill="#AAAECC"
        d="M51.132 70.241a9.524 9.524 0 1 0 0-19.048 9.524 9.524 0 0 0 0 19.048Z"
      />
      <path fill="#727FBD" d="m53.784 58.003 5.429 4.343h-5.429" />
      <path
        fill="#727FBD"
        d="M51.07 64.517a3.8 3.8 0 1 0 0-7.6 3.8 3.8 0 0 0 0 7.6Z"
      />
      <path
        fill="#213F7D"
        d="M51.07 63.432a2.715 2.715 0 1 0 0-5.43 2.715 2.715 0 0 0 0 5.43Z"
      />
      <path
        fill="#EFF3F9"
        d="M49.97 60.82a.645.645 0 1 0 0-1.29.645.645 0 0 0 0 1.29Z"
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M63.445 62.891v-8.873a15.185 15.185 0 0 0-15.18-15.187h-2.864"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M37.35 60.664a11.591 11.591 0 1 1 23.183 0"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M39.578 60.774a10.393 10.393 0 0 1 14.396-9.712 10.39 10.39 0 0 1 6.386 9.712"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M43.643 60.733a8.397 8.397 0 1 1 16.793 0"
        opacity={0.58}
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M111.877 60.81a11.592 11.592 0 1 0-23.185 0"
        opacity={0.49}
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M109.647 60.92a10.39 10.39 0 0 0-10.39-10.268A10.391 10.391 0 0 0 88.865 60.92"
        opacity={0.49}
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M105.583 60.88a8.396 8.396 0 0 0-16.794 0M84.163 62.916v-9.505a16.267 16.267 0 0 1 16.269-16.267h3.063"
        opacity={0.49}
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M81.216 62.999V52.065a18.712 18.712 0 0 1 18.71-18.71h3.528"
        opacity={0.49}
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M64.789 62.834v-9.507A16.266 16.266 0 0 0 48.522 37.06h-3.064"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M67.737 62.916V51.981a18.712 18.712 0 0 0-18.71-18.71H45.5"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        fill="#EFF3F9"
        d="M96.932 60.81a.645.645 0 1 0 0-1.291.645.645 0 0 0 0 1.29Z"
      />
      <path
        fill="#4D2F47"
        d="M79.997 79.674a5.5 5.5 0 0 1-5.5-5.5v-9.85h6.132a5.804 5.804 0 0 1 5.653 7.125l-.928 3.97a5.502 5.502 0 0 1-5.357 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M79.925 77.88a3.637 3.637 0 1 0 0-7.273 3.637 3.637 0 0 0 0 7.274Z"
      />
      <path
        fill="#3D2F39"
        d="M79.875 76.984a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path
        fill="#883A62"
        d="M69.015 79.63a5.5 5.5 0 0 0 5.5-5.5v-9.85h-6.134a5.803 5.803 0 0 0-5.652 7.125l.93 3.97a5.5 5.5 0 0 0 5.356 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M69.084 77.837a3.637 3.637 0 1 0 0-7.274 3.637 3.637 0 0 0 0 7.274Z"
      />
      <path
        fill="#3D2F39"
        d="M69.134 76.94a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path fill="#542F5F" d="M74.382 12.936H53.214v19.845h21.168V12.936Z" />
      <path fill="#172027" d="M95.55 12.936H74.382v19.845H95.55V12.936Z" />
      <path fill="#172027" d="M112.749 31.458h-75.41v2.646h75.41v-2.646Z" />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace308;
