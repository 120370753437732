const BitcoinFacesTextLogo = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 583 274"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_85_3835)">
        <mask
          id="mask0_85_3835"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="583"
          height="274"
        >
          <path d="M583 0H0V274H583V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_85_3835)">
          <path
            d="M55.2878 104.565H-0.097168V2.90461H51.3526C69.2798 2.90461 82.3973 14.8134 82.3973 30.9339C82.3973 39.0668 79.0451 46.0378 72.0491 50.9756C82.2516 55.1873 87.3528 64.482 87.3528 74.5028C87.3528 91.2043 73.7981 104.565 55.2878 104.565ZM29.0528 25.1248V42.8428H43.4821C48.4376 42.8428 52.3728 38.9216 52.3728 33.9838C52.3728 29.046 48.4376 25.1248 43.4821 25.1248H29.0528ZM29.0528 62.8845V82.3453H47.5631C52.9558 82.3453 57.3283 77.9884 57.3283 72.6149C57.3283 67.2414 52.9558 62.8845 47.5631 62.8845H29.0528ZM130.551 104.565H101.401V2.90461H130.551V104.565ZM199.183 104.565H170.033V29.3364H143.069V2.90461H226.147V29.3364H199.183V104.565ZM285.183 106.889C253.993 106.889 229.215 83.3619 229.215 53.735C229.215 24.1081 253.993 0.580933 285.183 0.580933C290.43 0.580933 298.884 1.30708 307.046 5.22829V36.3074C301.507 31.5149 293.928 27.8841 285.183 27.8841C270.754 27.8841 259.24 39.3573 259.24 53.735C259.24 68.1127 270.754 79.5859 285.183 79.5859C293.928 79.5859 301.507 75.9551 307.046 71.1626V102.242C298.884 106.163 290.43 106.889 285.183 106.889ZM372.563 106.889C341.664 106.889 317.178 83.3619 317.178 53.735C317.178 24.1081 341.664 0.580933 372.563 0.580933C403.462 0.580933 427.948 24.1081 427.948 53.735C427.948 83.3619 403.462 106.889 372.563 106.889ZM372.563 79.5859C386.992 79.5859 398.506 68.1127 398.506 53.735C398.506 39.3573 386.992 27.8841 372.563 27.8841C358.134 27.8841 346.619 39.3573 346.619 53.735C346.619 68.1127 358.134 79.5859 372.563 79.5859ZM471.441 104.565H442.291V2.90461H471.441V104.565ZM518.647 104.565H489.497V45.8926C489.497 20.6226 509.756 0.580933 535.262 0.580933C560.768 0.580933 581.027 20.6226 581.027 45.8926V104.565H551.877V44.4403C551.877 35.1456 544.59 27.8841 535.262 27.8841C525.934 27.8841 518.647 35.1456 518.647 44.4403V104.565Z"
            fill="black"
          />
          <path
            d="M37.9289 270.611H-0.451904V136.758H93.3892V171.56H37.9289V192.976H85.905V227.013H37.9289V270.611ZM146.767 270.611H108.386V193.359C108.386 158.748 134.869 133.698 168.644 133.698C203.571 133.698 228.902 158.748 228.902 193.359V270.611H190.521V236.001H146.767V270.611ZM146.767 190.299V202.346H190.521V190.299C190.521 177.87 181.118 168.5 168.644 168.5C156.171 168.5 146.767 177.87 146.767 190.299ZM318.758 273.671C277.691 273.671 245.067 242.693 245.067 203.685C245.067 164.676 277.691 133.698 318.758 133.698C325.667 133.698 336.797 134.655 347.544 139.818V180.738C340.251 174.428 330.272 169.648 318.758 169.648C299.76 169.648 284.599 184.754 284.599 203.685C284.599 222.615 299.76 237.722 318.758 237.722C330.272 237.722 340.251 232.941 347.544 226.631V267.552C336.797 272.715 325.667 273.671 318.758 273.671ZM435.535 168.118C420.374 168.118 408.476 175.767 403.103 188.005H456.452V218.217H402.527C407.709 231.029 419.991 239.251 435.535 239.251C446.09 239.251 457.412 235.045 465.28 227.205V266.022C458.371 270.038 448.392 273.671 434.767 273.671C390.437 273.671 360.884 243.076 360.884 203.685C360.884 164.294 390.437 133.698 434.767 133.698C448.392 133.698 458.371 137.332 465.28 141.347V180.165C457.412 172.325 446.09 168.118 435.535 168.118ZM528.776 273.288C513.618 273.288 499.033 269.846 487.135 264.684V227.969C497.882 235.618 516.878 241.355 529.544 241.355C539.717 241.355 543.361 237.148 543.361 232.368C543.361 226.822 538.566 224.336 530.889 222.042L517.072 217.835C500.758 212.863 483.68 201.39 483.68 178.253C483.68 152.82 502.871 134.272 534.728 134.272C549.696 134.272 562.551 136.949 572.341 140.2V174.428C564.665 170.795 549.885 166.206 537.987 166.206C528.392 166.206 523.213 169.648 523.213 174.237C523.213 179.782 530.695 182.268 538.566 184.754L549.696 188.196C570.422 194.506 582.893 207.318 582.893 228.734C582.893 256.079 562.167 273.288 528.776 273.288Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_85_3835">
          <rect width="583" height="274" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BitcoinFacesTextLogo;
