import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace581 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#FC532D" d="M150.675 0H0v150.675h150.675V0Z" />
      <path
        fill="#111C0D"
        d="M28.981 27.074h90.655a28.982 28.982 0 0 1 28.981 28.982v91.657H0V56.056a28.981 28.981 0 0 1 28.981-28.982Z"
      />
      <path
        fill="#DBB9BE"
        d="M74.125 20.595V125.85L20.196 92.806V45.073l53.929-24.478Z"
      />
      <path
        fill="#BCAFB1"
        d="M74.192 20.58v105.257l53.929-33.046V45.059L74.192 20.58Z"
      />
      <path
        fill="#F96020"
        d="M75.928 147.659a9.117 9.117 0 1 0 0-18.234 9.117 9.117 0 0 0 0 18.234Z"
      />
      <path
        fill="#D74816"
        d="M75.928 146.521a7.98 7.98 0 1 0 0-15.96 7.98 7.98 0 0 0 0 15.96Z"
      />
      <path
        fill="#FFA520"
        d="m68.657 141.538 3.692 3.692 10.06-10.061-3.691-3.692-10.061 10.061Z"
      />
      <path
        fill="#F96020"
        d="M75.11 148.239c5.387 0 9.755-4.367 9.755-9.755 0-5.387-4.368-9.755-9.755-9.755-5.388 0-9.755 4.368-9.755 9.755a9.755 9.755 0 0 0 9.755 9.755Z"
      />
      <path
        fill="#D74816"
        d="M75.108 147.022a8.538 8.538 0 1 0 0-17.076 8.538 8.538 0 0 0 0 17.076Z"
      />
      <path
        fill="#FF9317"
        d="M74.733 130.624h.77a.77.77 0 0 0 .165.012c.222.019.441.041.665.076.523.08 1.037.214 1.533.4a7.898 7.898 0 0 1 4.729 4.932 7.697 7.697 0 0 1 .37 2.009.242.242 0 0 0 .012.121v.633a.226.226 0 0 0-.012.121 7.651 7.651 0 0 1-.869 3.183 7.876 7.876 0 0 1-9.129 3.946 7.806 7.806 0 0 1-4.37-3.154 7.792 7.792 0 0 1-1.33-3.759 2.498 2.498 0 0 0-.023-.276v-.754a.116.116 0 0 0 .01-.059 7.806 7.806 0 0 1 .772-2.995 7.898 7.898 0 0 1 5.167-4.204c.45-.112.91-.185 1.373-.217.056-.001.112-.006.167-.015Zm-6.174 7.864a6.56 6.56 0 0 0 7.84 6.43 6.554 6.554 0 0 0 2.36-11.885 6.564 6.564 0 0 0-9.706 2.945 6.553 6.553 0 0 0-.498 2.51h.004Z"
      />
      <path
        fill="#FF9317"
        d="M77.085 138.255c.279.046.55.13.806.248.322.146.593.387.775.691.12.21.196.442.223.682.04.304.027.613-.04.913a1.603 1.603 0 0 1-1 1.176 3.714 3.714 0 0 1-.847.225 7.035 7.035 0 0 1-.588.065c-.07 0-.07 0-.07.075v1.167c0 .073 0 .075-.074.075h-.855c-.082 0-.077 0-.077-.078v-1.129c0-.094.007-.084-.086-.084H74.4c-.068 0-.068 0-.07.071v1.136c0 .087 0 .084-.079.084h-.844c-.08 0-.08 0-.08-.084v-1.167c0-.021-.01-.043-.039-.04h-1.925c-.036 0-.05-.009-.05-.046v-.836c0-.04.016-.051.053-.051h.52c.106-.003.21-.016.313-.04a.28.28 0 0 0 .228-.231c.031-.137.045-.278.043-.419V136.282c0-.133-.014-.265-.04-.395a.294.294 0 0 0-.251-.247 1.96 1.96 0 0 0-.405-.036h-.39c-.07 0-.07 0-.07-.072v-.774c0-.089-.009-.083.08-.084h1.844c.084 0 .084 0 .086-.08v-1.159c0-.03.013-.042.04-.039h.883c.09 0 .082-.008.082.08v1.107c0 .103-.007.091.094.091h.846c.067 0 .067 0 .069-.072v-1.167c0-.03.01-.042.04-.039h.881c.087 0 .084-.013.084.086v1.152c0 .057 0 .057.057.062.304.02.605.069.899.147.162.043.32.102.47.176a1.353 1.353 0 0 1 .735.891c.098.347.104.714.018 1.064-.09.359-.308.673-.613.882-.24.166-.505.292-.785.372-.01.005-.028.002-.039.027Zm-2.757 1.823v1.22c0 .064 0 .066.064.066h.441c.314.007.628-.008.94-.046.184-.023.365-.068.538-.137a.92.92 0 0 0 .558-.611c.088-.286.1-.59.039-.882a.928.928 0 0 0-.412-.623 1.332 1.332 0 0 0-.39-.15 3.522 3.522 0 0 0-.677-.096c-.352-.02-.703 0-1.054-.012-.038 0-.05.015-.047.049.003.034 0 .035 0 .053v1.169Zm0-3.346v1.085c0 .036 0 .054.05.054.378.01.757-.001 1.135-.034.184-.02.366-.064.539-.131a.811.811 0 0 0 .507-.588c.059-.229.07-.468.032-.702a.805.805 0 0 0-.563-.674 2.701 2.701 0 0 0-.735-.129c-.294-.015-.588 0-.882 0h-.03c-.025 0-.037.012-.036.037.002.025 0 .04 0 .06l-.017 1.022ZM67.59 131.811a2.054 2.054 0 1 0 0-4.108 2.054 2.054 0 0 0 0 4.108Z"
      />
      <path
        fill="#FF9317"
        d="M64.51 128.73a2.054 2.054 0 1 0-.001-4.108 2.054 2.054 0 0 0 0 4.108Z"
      />
      <path
        fill="#FF9317"
        d="M61.43 125.65a2.054 2.054 0 1 0-.001-4.108 2.054 2.054 0 0 0 0 4.108Z"
      />
      <path
        fill="#FF9317"
        d="M58.349 122.569a2.054 2.054 0 1 0-.001-4.108 2.054 2.054 0 0 0 0 4.108Z"
      />
      <path
        fill="#F96020"
        d="M82.992 131.811a2.054 2.054 0 1 0-.001-4.108 2.054 2.054 0 0 0 0 4.108ZM86.073 128.73a2.054 2.054 0 1 0 0-4.108 2.054 2.054 0 0 0 0 4.108ZM89.153 125.65a2.054 2.054 0 1 0-.001-4.108 2.054 2.054 0 0 0 0 4.108ZM92.234 122.569a2.054 2.054 0 1 0-.001-4.108 2.054 2.054 0 0 0 0 4.108Z"
      />
      <path
        fill="#883A62"
        d="M53.589 30.247 35.067 17.899l-4.911 6.139a10.91 10.91 0 0 0 2.468 15.895l8.615 5.743"
      />
      <path
        fill="#4D2F47"
        d="M94.728 30.198 113.25 17.85l4.912 6.14a10.9 10.9 0 0 1 2.262 8.49 10.911 10.911 0 0 1-4.729 7.404l-8.617 5.745"
      />
      <path
        fill="#BA3D11"
        d="M46.999 94.743a43.888 43.888 0 0 0 25.174 7.938h2.033v-7.938"
      />
      <path
        fill="#A31905"
        d="M101.58 94.702a43.892 43.892 0 0 1-25.175 7.938h-2.033v-7.938"
      />
      <path
        fill="#6C1908"
        d="m74.206 90.208-3.402-3.4-24.94 7.935h28.342v-4.535Z"
      />
      <path
        fill="#3E1B11"
        d="m74.372 90.167 3.401-3.4 24.941 7.935H74.372v-4.535Z"
      />
      <path
        stroke="#070707"
        strokeMiterlimit={10}
        strokeWidth={2.707}
        d="M117.328 67.674a18.186 18.186 0 0 1-5.414 12.951 18.187 18.187 0 0 1-13.021 5.242c-9.996-.13-17.953-8.464-17.953-18.463a5.793 5.793 0 0 0-5.793-5.793h-.273M32.421 67.674a18.192 18.192 0 0 0 18.437 18.193c9.996-.13 17.951-8.464 17.951-18.463a5.792 5.792 0 0 1 5.795-5.793h.27"
      />
      <path
        fill="#8E97C4"
        d="M32.421 67.674h36.388a18.194 18.194 0 0 1-36.388 0ZM80.77 67.507h36.389a18.195 18.195 0 0 1-36.388 0Z"
        opacity={0.57}
        style={{
          mixBlendMode: "color-dodge",
        }}
      />
      <path
        fill="#001A1B"
        d="M53.445 78.72c7.605 0 13.77-6.165 13.77-13.77 0-7.606-6.165-13.772-13.77-13.772-7.606 0-13.771 6.166-13.771 13.771 0 7.606 6.165 13.771 13.77 13.771Z"
      />
      <path
        fill="#EFE1DA"
        d="M53.445 73.474a8.525 8.525 0 1 0 0-17.05 8.525 8.525 0 0 0 0 17.05Z"
      />
      <path
        fill="#ED7E29"
        d="M53.445 70.851a5.902 5.902 0 1 0 0-11.804 5.902 5.902 0 0 0 0 11.804Z"
      />
      <path fill="#EFE1DA" d="M54.1 57.81v5.244l4.59-4.589" />
      <path
        stroke="#883A62"
        strokeMiterlimit={10}
        strokeWidth={1.203}
        d="M53.476 74.786c5.445 0 9.86-4.414 9.86-9.859s-4.415-9.86-9.86-9.86c-5.445 0-9.86 4.415-9.86 9.86 0 5.445 4.415 9.86 9.86 9.86Z"
      />
      <path
        fill="#001A1B"
        d="M95.394 78.294c7.606 0 13.771-6.166 13.771-13.771 0-7.606-6.165-13.771-13.77-13.771-7.606 0-13.772 6.165-13.772 13.77 0 7.606 6.166 13.772 13.771 13.772Z"
      />
      <path
        fill="#EFE1DA"
        d="M95.394 73.047a8.525 8.525 0 1 0 0-17.05 8.525 8.525 0 0 0 0 17.05Z"
      />
      <path
        fill="#ED7E29"
        d="M95.394 70.425a5.902 5.902 0 1 0 0-11.803 5.902 5.902 0 0 0 0 11.803Z"
      />
      <path fill="#EFE1DA" d="M96.05 57.383v5.245l4.591-4.59" />
      <path
        stroke="#883A62"
        strokeMiterlimit={10}
        strokeWidth={1.203}
        d="M95.425 74.36c5.445 0 9.859-4.414 9.859-9.86 0-5.444-4.414-9.858-9.859-9.858s-9.86 4.414-9.86 9.859 4.415 9.86 9.86 9.86Z"
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.203}
        d="M81.435 63.551v-7.69a13.17 13.17 0 0 1 13.17-13.17h2.478"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.203}
        d="M80.27 63.504v-8.246a14.112 14.112 0 0 1 14.112-14.112h2.656"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.203}
        d="M77.713 63.57v-9.474a16.225 16.225 0 0 1 16.226-16.233h3.055M67.577 63.551v-7.69a13.17 13.17 0 0 0-13.17-13.17h-2.475"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.203}
        d="M68.743 63.504v-8.246a14.112 14.112 0 0 0-14.112-14.112H51.98"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.203}
        d="M71.3 63.57v-9.474a16.225 16.225 0 0 0-16.226-16.233h-3.055"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        fill="#001A1B"
        d="m105.868 55.806 12.619 10.096h-12.619M43.372 55.806 30.754 65.902h12.618"
      />
      <path
        fill="#F55B20"
        d="M74.264 65.64v14.984a5.683 5.683 0 0 1-5.586-6.702l1.506-8.282h4.08Z"
      />
      <path
        fill="#883A62"
        d="M74.12 65.64v14.984a5.683 5.683 0 0 0 5.587-6.702L78.2 65.64h-4.08Z"
      />
      <path fill="#542F5F" d="M74.382 12.936H53.214v19.845h21.168V12.936Z" />
      <path fill="#172027" d="M95.55 12.936H74.382v19.845H95.55V12.936Z" />
      <path fill="#172027" d="M112.749 31.458h-75.41v2.646h75.41v-2.646Z" />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace581;
