import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace517 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#A9D4EB" d="M150.675 0H0v150.675h150.675V0Z" />
      <path fill="#A02A15" d="M120.642 69.09H30.156v79.847h90.486V69.09Z" />
      <path
        fill="#DE2F7A"
        d="M120.224 144.462H30.26v4.475h89.964v-4.475ZM120.224 138.869H30.26v4.475h89.964v-4.475ZM120.224 133.274H30.26v4.475h89.964v-4.475ZM120.224 127.681H30.26v4.475h89.964v-4.475ZM120.224 122.088H30.26v4.474h89.964v-4.474ZM120.224 116.494H30.26v4.475h89.964v-4.475ZM120.224 110.901H30.26v4.474h89.964v-4.474ZM120.224 105.306H30.26v4.475h89.964v-4.475ZM120.224 99.713H30.26v4.474h89.964v-4.474ZM120.224 94.12H30.26v4.474h89.964v-4.475ZM120.224 88.526H30.26v4.475h89.964v-4.475ZM120.224 82.933H30.26v4.474h89.964v-4.474Z"
      />
      <path
        fill="#7814BC"
        d="M120.696 74.868H74.794v74.069h45.902V74.868Z"
        opacity={0.34}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path
        fill="#DBB9BE"
        d="M74.125 20.595V125.85L20.196 92.805V45.073l53.929-24.478Z"
      />
      <path
        fill="#BCAFB1"
        d="M74.192 20.58v105.256l53.929-33.045V45.058L74.192 20.58Z"
      />
      <path
        fill="#542F5F"
        d="M44.98 33.81v9.408a3.335 3.335 0 0 1-3.329 3.323h-3.065c-1.896 0-3.773.375-5.523 1.104a12.804 12.804 0 0 1-16.484-6.32l-.653-1.359A12.727 12.727 0 0 1 30.7 22.196l13.12 3.5H27.608a5.79 5.79 0 1 0 0 11.581h.713a5.795 5.795 0 0 0 2.98-.821l5.991-3.596a6.292 6.292 0 0 1 7.688.947v.003Z"
      />
      <path
        fill="#111C0D"
        d="M106.278 33.833v9.408a3.331 3.331 0 0 0 3.334 3.334h3.059c1.895 0 3.772.375 5.521 1.104a12.806 12.806 0 0 0 16.486-6.313l.65-1.366a12.732 12.732 0 0 0 .417-9.965 12.725 12.725 0 0 0-15.187-7.804l-13.123 3.494h16.214a5.785 5.785 0 0 1 4.095 1.696 5.786 5.786 0 0 1 1.695 4.096 5.772 5.772 0 0 1-1.695 4.094 5.79 5.79 0 0 1-4.095 1.696h-.707c-1.05 0-2.08-.285-2.98-.825l-5.993-3.595a6.29 6.29 0 0 0-7.687.946h-.004Z"
      />
      <path fill="#FA8CB2" d="M78.161 81.43H46.665V96.25h31.496V81.43Z" />
      <path fill="#EFE1DA" d="M78.163 83.281H50.37v11.116h27.792V83.281Z" />
      <path
        fill="#001A1B"
        d="M55.93 83.281h-1.853v11.116h1.852V83.281ZM61.487 83.281h-1.852v11.116h1.852V83.281ZM67.05 83.275h-1.853v11.116h1.853V83.275ZM72.608 83.275h-1.853v11.116h1.853V83.275Z"
      />
      <path fill="#F46461" d="M73.526 96.256h28.732V81.434H73.527v14.822Z" />
      <path fill="#EFE1DA" d="M72.599 94.404h25.954V83.287H72.6v11.117Z" />
      <path
        fill="#001A1B"
        d="M92.994 94.404h1.852V83.287h-1.852v11.117ZM87.436 94.404h1.852V83.287h-1.852v11.117ZM81.873 94.398h1.852V83.28h-1.852v11.117ZM76.315 94.398h1.852V83.28h-1.852v11.117Z"
      />
      <path
        fill="#883A62"
        d="M100.419 82.355H48.488v6.492h51.931v-6.492Z"
        opacity={0.3}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path
        stroke="#F96020"
        strokeMiterlimit={10}
        strokeWidth={3.61}
        d="M58.709 66.77h33.703"
      />
      <path
        fill="#F96020"
        d="M52.317 85.192c6.122 0 11.084-4.962 11.084-11.084 0-6.121-4.962-11.084-11.084-11.084-6.121 0-11.083 4.963-11.083 11.084 0 6.122 4.962 11.084 11.083 11.084Z"
      />
      <path
        fill="#D74816"
        d="M52.317 83.81c5.358 0 9.701-4.344 9.701-9.701 0-5.358-4.343-9.7-9.7-9.7-5.358 0-9.701 4.342-9.701 9.7 0 5.357 4.343 9.7 9.7 9.7Z"
      />
      <path
        fill="#F96020"
        d="M98.502 85.494c6.121 0 11.084-4.963 11.084-11.084 0-6.122-4.963-11.084-11.084-11.084-6.121 0-11.084 4.962-11.084 11.084 0 6.121 4.962 11.084 11.084 11.084Z"
      />
      <path
        fill="#D74816"
        d="M98.502 84.11c5.357 0 9.7-4.343 9.7-9.7 0-5.358-4.343-9.7-9.7-9.7-5.358 0-9.7 4.342-9.7 9.7 0 5.357 4.342 9.7 9.7 9.7Z"
      />
      <path
        fill="#FFA520"
        d="m43.479 77.753 4.488 4.488 12.23-12.23-4.488-4.488-12.23 12.23ZM90.672 78.509l4.489 4.488 12.23-12.23-4.489-4.489-12.23 12.23Z"
      />
      <path
        fill="#F96020"
        d="M99.074 86.742c6.549 0 11.858-5.31 11.858-11.859 0-6.55-5.309-11.858-11.858-11.858-6.55 0-11.859 5.309-11.859 11.858 0 6.55 5.31 11.859 11.859 11.859Z"
      />
      <path
        fill="#D74816"
        d="M99.074 85.261c5.731 0 10.378-4.646 10.378-10.378s-4.647-10.378-10.378-10.378c-5.732 0-10.379 4.646-10.379 10.378s4.647 10.378 10.379 10.378Z"
      />
      <path
        fill="#FF9317"
        d="M98.617 65.328h.934c.067.012.134.016.202.015.27.023.541.051.808.092.637.098 1.261.261 1.864.487a9.609 9.609 0 0 1 5.749 5.996 9.29 9.29 0 0 1 .45 2.437c-.007.05-.003.1.014.147v.77a.306.306 0 0 0-.014.146 9.313 9.313 0 0 1-1.055 3.87 9.579 9.579 0 0 1-7.843 5.144 9.689 9.689 0 0 1-3.253-.348 9.505 9.505 0 0 1-5.312-3.834 9.513 9.513 0 0 1-1.617-4.57 2.599 2.599 0 0 0-.028-.337V74.43a.135.135 0 0 0 .013-.072c.021-.414.07-.826.147-1.233.153-.835.42-1.645.792-2.407a9.594 9.594 0 0 1 6.282-5.111 9.714 9.714 0 0 1 1.668-.265c.066.002.133-.003.198-.013Zm-7.51 9.555a7.972 7.972 0 1 0 15.943-.014 7.972 7.972 0 0 0-15.944.02v-.006Z"
      />
      <path
        fill="#FF9317"
        d="M101.471 74.605c.339.056.668.158.979.303a2.1 2.1 0 0 1 .943.84c.145.255.238.537.272.829.047.369.031.744-.049 1.108a1.947 1.947 0 0 1-1.216 1.432 4.487 4.487 0 0 1-1.029.273 7.803 7.803 0 0 1-.718.078c-.084 0-.086 0-.086.093v1.418c0 .09 0 .091-.091.091h-1.029c-.101 0-.097 0-.097-.094v-1.373c0-.113.01-.101-.103-.101h-1.029c-.084 0-.084 0-.084.085v1.382c0 .106 0 .101-.098.101h-1.03c-.098 0-.098 0-.098-.101V79.55c0-.034-.011-.053-.048-.05a.577.577 0 0 1-.072 0h-2.205a.444.444 0 0 0-.063 0c-.043 0-.06-.01-.06-.055v-1.018c0-.048.019-.062.064-.062h.632c.128-.002.256-.019.38-.05a.346.346 0 0 0 .279-.28c.036-.167.053-.338.05-.509v-5.314a2.323 2.323 0 0 0-.047-.482.362.362 0 0 0-.306-.294 2.386 2.386 0 0 0-.491-.044h-.476c-.084 0-.084 0-.086-.087v-.944c0-.107-.008-.1.099-.1H96.8c.102 0 .102 0 .102-.098V68.753c0-.025.016-.048.05-.047h1.071c.11 0 .1-.012.1.096v1.346c0 .125-.01.112.113.112h1.03c.082 0 .082 0 .082-.088v-1.419c0-.02.014-.05.048-.048h1.072c.107 0 .103-.016.103.104v1.401c0 .07 0 .07.07.075.369.024.734.082 1.091.175.197.053.389.125.573.215a1.65 1.65 0 0 1 .901 1.084c.118.423.125.868.021 1.294-.109.437-.374.82-.745 1.075a3.263 3.263 0 0 1-.956.452c-.017-.001-.038-.006-.056.025Zm-3.351 2.216v1.483c0 .08 0 .08.08.08h.54c.382.01.763-.009 1.142-.055.224-.027.444-.082.654-.165a1.138 1.138 0 0 0 .68-.745c.105-.346.121-.713.045-1.067a1.123 1.123 0 0 0-.5-.757 1.637 1.637 0 0 0-.482-.196 4.292 4.292 0 0 0-.822-.116c-.427-.025-.855 0-1.281-.013-.046 0-.06.017-.058.059a.466.466 0 0 1 0 .064l.002 1.428Zm0-4.066V74.072c0 .044.008.067.06.066.46.013.921 0 1.38-.04.225-.025.445-.079.656-.16a.997.997 0 0 0 .616-.71c.07-.282.081-.575.031-.861a.985.985 0 0 0-.684-.82 3.271 3.271 0 0 0-.898-.157c-.36-.017-.72 0-1.08-.008h-.036c-.03 0-.045.014-.044.044v1.323l-.001.006Z"
      />
      <path
        fill="#F96020"
        d="M51.322 85.898c6.55 0 11.859-5.31 11.859-11.859s-5.31-11.858-11.859-11.858c-6.55 0-11.858 5.31-11.858 11.858 0 6.55 5.309 11.859 11.858 11.859Z"
      />
      <path
        fill="#D74816"
        d="M51.32 84.418c5.733 0 10.38-4.647 10.38-10.379 0-5.731-4.647-10.378-10.38-10.378-5.73 0-10.377 4.647-10.377 10.379 0 5.731 4.646 10.378 10.378 10.378Z"
      />
      <path
        fill="#FF9317"
        d="M50.865 64.484h.935c.066.012.134.016.201.014.27.023.54.051.809.094.636.097 1.26.26 1.862.486a9.587 9.587 0 0 1 5.75 5.995 9.254 9.254 0 0 1 .45 2.439c-.007.049-.003.1.014.147v.77a.274.274 0 0 0-.013.147 8.324 8.324 0 0 1-.093.919 9.24 9.24 0 0 1-.964 2.95 9.571 9.571 0 0 1-7.843 5.145 9.682 9.682 0 0 1-3.25-.34 9.5 9.5 0 0 1-5.317-3.844 9.48 9.48 0 0 1-1.617-4.569 2.328 2.328 0 0 0-.03-.336V73.587a.132.132 0 0 0 .014-.07c.021-.415.07-.827.147-1.234a9.564 9.564 0 0 1 8.742-7.782c.068-.001.136-.007.203-.017Zm-7.51 9.555a7.971 7.971 0 1 0 15.942-.02 7.971 7.971 0 0 0-15.942.026v-.006Z"
      />
      <path
        fill="#FF9317"
        d="M53.724 73.76c.339.057.668.159.98.303.392.177.72.47.942.84.146.254.239.536.271.828.05.37.033.746-.048 1.11a1.944 1.944 0 0 1-1.216 1.432c-.33.132-.676.223-1.029.272a7.364 7.364 0 0 1-.718.08c-.084 0-.084 0-.086.09v1.415c0 .09 0 .09-.09.09H51.7c-.1 0-.096 0-.096-.095v-1.373c0-.114.01-.103-.104-.103h-1.029c-.082 0-.084 0-.084.086v1.383c0 .104.01.101-.097.101h-1.029c-.098 0-.098 0-.098-.103v-1.418c0-.025-.012-.052-.049-.049h-2.347c-.041 0-.06-.01-.06-.055v-1.016c0-.049.019-.062.066-.062h.632c.127-.003.255-.02.379-.049a.344.344 0 0 0 .278-.28c.037-.167.054-.338.051-.509v-5.311c.001-.162-.015-.323-.048-.48a.362.362 0 0 0-.304-.295 2.415 2.415 0 0 0-.493-.044h-.476c-.083 0-.084 0-.084-.088v-.942c0-.108-.01-.1.097-.1h2.244c.102 0 .102 0 .104-.097V67.899c0-.025.016-.048.05-.047h1.071c.109 0 .1-.01.1.097v1.345c0 .127-.009.112.115.112h1.024c.083 0 .083 0 .084-.087V67.9c0-.022.013-.05.049-.047h1.071c.106 0 .102-.014.102.106v1.4c0 .07 0 .069.07.074.37.025.735.084 1.092.177.198.053.39.125.572.215a1.642 1.642 0 0 1 .902 1.083c.118.422.125.869.02 1.295-.109.437-.374.82-.745 1.075a3.312 3.312 0 0 1-.954.452c-.018.005-.04 0-.052.031Zm-3.351 2.217v1.483c0 .078 0 .08.08.08h.54c.382.01.763-.009 1.142-.055.224-.028.444-.084.654-.166a1.133 1.133 0 0 0 .68-.744 2.12 2.12 0 0 0 .045-1.067 1.12 1.12 0 0 0-.5-.757 1.592 1.592 0 0 0-.48-.196 4.314 4.314 0 0 0-.824-.116c-.426-.025-.854-.007-1.282-.014-.045 0-.06.017-.055.06.004.043 0 .043 0 .063v1.429Zm0-4.066V73.228c0 .044.009.066.059.066.46.013.922 0 1.381-.041.224-.024.444-.078.654-.159a.995.995 0 0 0 .618-.71c.07-.282.08-.576.03-.863a.973.973 0 0 0-.684-.817 3.166 3.166 0 0 0-.897-.157c-.36-.018-.72-.008-1.08-.01h-.037c-.03 0-.044.016-.044.046v1.328Z"
      />
      <path
        fill="#FFA520"
        d="M92.85 71.112c5.519 0 9.993-4.474 9.993-9.992 0-5.52-4.474-9.994-9.993-9.994-5.52 0-9.993 4.474-9.993 9.994 0 5.518 4.474 9.992 9.993 9.992Z"
      />
      <path
        fill="#001A1B"
        d="M92.85 67.115a5.996 5.996 0 1 0 0-11.992 5.996 5.996 0 0 0 0 11.992Z"
      />
      <path
        fill="#F7D53A"
        d="M56.873 71.112c5.519 0 9.993-4.474 9.993-9.992 0-5.52-4.474-9.994-9.993-9.994-5.52 0-9.993 4.474-9.993 9.994 0 5.518 4.474 9.992 9.993 9.992Z"
      />
      <path
        fill="#361A11"
        d="M56.873 67.115a5.996 5.996 0 1 0 0-11.992 5.996 5.996 0 0 0 0 11.992Z"
      />
      <path
        fill="#631809"
        d="m68.867 49.127 2.293 1.529a8.313 8.313 0 0 1 3.704 6.92c0 6.64-7.401 10.6-12.927 6.917L38.886 49.127"
      />
      <path
        fill="#361A11"
        d="m80.859 49.127-2.293 1.529a8.314 8.314 0 0 0-3.702 6.92c0 6.64 7.402 10.6 12.927 6.917l23.047-15.366"
      />
      <path
        stroke="#631809"
        strokeMiterlimit={10}
        strokeWidth={0.902}
        d="M42.965 61.299a14.217 14.217 0 1 0 28.431 0"
      />
      <path
        stroke="#631809"
        strokeMiterlimit={10}
        strokeWidth={0.902}
        d="M57.173 78.102a14.217 14.217 0 0 0 14.216-14.215"
      />
      <path
        fill="#361A11"
        d="M93.151 76.087a14.473 14.473 0 0 1-14.457-14.458h.483a13.974 13.974 0 1 0 27.948 0h.484a14.475 14.475 0 0 1-14.458 14.458Z"
      />
      <path
        fill="#361A11"
        d="M93.151 78.673a14.472 14.472 0 0 1-14.457-14.456h.483A13.988 13.988 0 0 0 93.151 78.19v.482Z"
      />
      <path
        fill="#883A62"
        d="M75.792 53.034a16.417 16.417 0 0 1 16.417 16.417v18.534H59.376V69.451a16.417 16.417 0 0 1 16.416-16.417Z"
        opacity={0.7}
      />
      <path
        fill="#FA8CB2"
        d="M68.667 79.453h8.773v-14.71h-6.066a3.592 3.592 0 0 0-3.38 2.377l-2.708 7.522a3.595 3.595 0 0 0 3.38 4.811Z"
      />
      <path
        fill="#542F5F"
        d="M70.555 74.276a2.647 2.647 0 1 0 0-5.295 2.647 2.647 0 0 0 0 5.295Z"
      />
      <path
        fill="#F46461"
        d="M82.446 79.512h-6.713v-14.71h4.006a3.594 3.594 0 0 1 3.38 2.377l2.708 7.522a3.594 3.594 0 0 1-3.38 4.811Z"
      />
      <path
        fill="#172027"
        d="M80.558 74.17a2.648 2.648 0 1 0 0-5.295 2.648 2.648 0 0 0 0 5.295Z"
      />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace517;
