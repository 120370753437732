import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace115 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#EFE2DB" d="M150.675 0H0v150.675h150.675V0Z" />
      <path fill="#A02A15" d="M120.642 69.09H30.156v79.847h90.486V69.089Z" />
      <path
        fill="#DE2F7A"
        d="M120.224 144.462H30.26v4.475h89.964v-4.475ZM120.224 138.869H30.26v4.475h89.964v-4.475ZM120.224 133.274H30.26v4.475h89.964v-4.475ZM120.224 127.681H30.26v4.474h89.964v-4.474ZM120.224 122.088H30.26v4.474h89.964v-4.474ZM120.224 116.494H30.26v4.475h89.964v-4.475ZM120.224 110.901H30.26v4.474h89.964v-4.474ZM120.224 105.306H30.26v4.475h89.964v-4.475ZM120.224 99.713H30.26v4.474h89.964v-4.474ZM120.224 94.12H30.26v4.474h89.964v-4.475ZM120.224 88.526H30.26V93h89.964v-4.475ZM120.224 82.932H30.26v4.475h89.964v-4.475Z"
      />
      <path
        fill="#7814BC"
        d="M120.696 74.868H74.794v74.069h45.902V74.868Z"
        opacity={0.34}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path
        fill="#4A2955"
        d="M17.634 56.588v37.346l57.33 37.194v-74.54h-57.33Z"
      />
      <path
        fill="#161F26"
        d="M51.956 23.316h45.908a27.786 27.786 0 0 1 27.783 27.783v28.644H24.173V51.099a27.783 27.783 0 0 1 27.783-27.783Z"
      />
      <path
        fill="#FA8CB2"
        d="M74.911 31.1v62.7H56.503L26.59 74.394V53.261c0-12.24 15.288-22.16 34.156-22.16h14.165Z"
      />
      <path
        fill="#161F26"
        d="M131.349 56.73v37.347L74.018 131.27V56.73h57.331Z"
      />
      <path
        fill="#F46461"
        d="M74.073 31.227v62.706h18.408l29.913-19.404V53.387c0-12.239-15.288-22.16-34.156-22.16H74.073Z"
      />
      <path
        fill="#F96020"
        d="M75.928 147.658a9.117 9.117 0 1 0 0-18.234 9.117 9.117 0 0 0 0 18.234Z"
      />
      <path
        fill="#D74816"
        d="M75.928 146.52a7.979 7.979 0 1 0 0-15.958 7.979 7.979 0 1 0 0 15.958Z"
      />
      <path
        fill="#FFA520"
        d="m68.657 141.537 3.692 3.692 10.06-10.06-3.691-3.693-10.061 10.061Z"
      />
      <path
        fill="#F96020"
        d="M75.11 148.239c5.387 0 9.755-4.368 9.755-9.755 0-5.388-4.368-9.755-9.755-9.755a9.755 9.755 0 0 0-9.755 9.755c0 5.387 4.367 9.755 9.754 9.755Z"
      />
      <path
        fill="#D74816"
        d="M75.108 147.021a8.537 8.537 0 1 0 0-17.075 8.538 8.538 0 1 0 0 17.075Z"
      />
      <path
        fill="#FF9317"
        d="M74.733 130.624h.77a.84.84 0 0 0 .165.011c.222.019.441.042.665.077.523.08 1.037.214 1.533.4a7.896 7.896 0 0 1 4.729 4.931 7.723 7.723 0 0 1 .37 2.01.24.24 0 0 0 .012.121v.633a.223.223 0 0 0-.012.121 7.643 7.643 0 0 1-.869 3.182 7.87 7.87 0 0 1-9.128 3.947 7.81 7.81 0 0 1-4.37-3.154 7.794 7.794 0 0 1-1.33-3.759 2.516 2.516 0 0 0-.024-.277v-.754a.112.112 0 0 0 .01-.059 7.808 7.808 0 0 1 .772-2.994 7.887 7.887 0 0 1 6.54-4.422c.056-.001.112-.006.167-.014Zm-6.174 7.864a6.557 6.557 0 1 0 6.555-6.559 6.554 6.554 0 0 0-6.56 6.559h.005Z"
      />
      <path
        fill="#FF9317"
        d="M77.086 138.254c.278.047.549.13.805.249.323.146.593.387.775.69.12.21.196.442.223.683.04.304.027.613-.04.912a1.599 1.599 0 0 1-.999 1.176 3.666 3.666 0 0 1-.848.225 6.646 6.646 0 0 1-.588.065c-.07 0-.07 0-.07.075v1.167c0 .074 0 .075-.074.075h-.854c-.083 0-.078 0-.078-.078v-1.129c0-.094.007-.084-.085-.084H74.4c-.068 0-.068 0-.07.071v1.136c0 .087 0 .084-.079.084h-.843c-.081 0-.081 0-.081-.084v-1.167c0-.02-.01-.042-.038-.04h-1.926c-.035 0-.05-.008-.05-.045v-.837c0-.039.016-.051.053-.051h.52c.106-.002.21-.015.313-.04a.281.281 0 0 0 .228-.23c.031-.138.045-.279.043-.419v-4.377c0-.132-.014-.265-.04-.395a.291.291 0 0 0-.085-.165.288.288 0 0 0-.166-.082 1.958 1.958 0 0 0-.405-.035h-.39c-.07 0-.07 0-.07-.072v-.775c0-.088-.008-.082.08-.084h1.845c.083 0 .083 0 .085-.079v-1.16c0-.029.013-.041.041-.038h.882c.09 0 .082-.009.082.079v1.107c0 .103-.007.091.094.091h.846c.067 0 .067 0 .069-.072v-1.167c0-.029.01-.041.04-.038h.881c.087 0 .084-.013.084.085v1.153c0 .057 0 .057.057.061.304.021.605.07.899.147.162.044.32.103.47.177a1.337 1.337 0 0 1 .735.89c.098.348.104.714.018 1.065-.09.359-.308.672-.613.882-.24.165-.505.291-.785.372-.01.004-.028.001-.038.026Zm-2.758 1.823v1.22c0 .065 0 .066.064.066h.442c.313.008.627-.007.939-.045.184-.023.365-.069.538-.137a.932.932 0 0 0 .558-.612c.088-.286.1-.589.039-.882a.93.93 0 0 0-.412-.623 1.332 1.332 0 0 0-.39-.15 3.444 3.444 0 0 0-.677-.095c-.352-.021-.703 0-1.054-.012-.038 0-.05.015-.047.048.003.034 0 .036 0 .053v1.169Zm0-3.346v1.085c0 .037 0 .054.05.054.378.011.757-.001 1.135-.033.184-.02.366-.064.54-.131a.823.823 0 0 0 .507-.588c.058-.23.07-.469.032-.703a.811.811 0 0 0-.563-.673 2.662 2.662 0 0 0-.735-.129c-.294-.015-.588 0-.882 0h-.031c-.025 0-.037.011-.035.036v.061l-.018 1.021ZM67.59 131.81a2.053 2.053 0 1 0 0-4.107 2.053 2.053 0 0 0 0 4.107Z"
      />
      <path
        fill="#FF9317"
        d="M64.51 128.729a2.053 2.053 0 1 0 0-4.107 2.053 2.053 0 0 0 0 4.107Z"
      />
      <path
        fill="#FF9317"
        d="M61.43 125.649a2.053 2.053 0 1 0 0-4.106 2.053 2.053 0 0 0 0 4.106Z"
      />
      <path
        fill="#FF9317"
        d="M58.349 122.568a2.053 2.053 0 1 0 0-4.107 2.053 2.053 0 0 0 0 4.107Z"
      />
      <path
        fill="#F96020"
        d="M82.992 131.81a2.053 2.053 0 1 0 0-4.107 2.053 2.053 0 0 0 0 4.107ZM86.073 128.729a2.053 2.053 0 1 0 0-4.107 2.053 2.053 0 0 0 0 4.107ZM89.153 125.649a2.053 2.053 0 1 0 0-4.106 2.053 2.053 0 0 0 0 4.106ZM92.234 122.568a2.053 2.053 0 1 0 0-4.106 2.053 2.053 0 0 0 0 4.106Z"
      />
      <path
        fill="#F26227"
        d="M20.827 78.892a9.002 9.002 0 1 0 0-18.005 9.002 9.002 0 1 0 0 18.005Z"
      />
      <path
        fill="#D74B27"
        d="M20.827 77.768a7.88 7.88 0 1 0 0-15.758 7.88 7.88 0 0 0 0 15.758Z"
      />
      <path
        fill="#F79421"
        d="M20.48 62.636h.709c.048.008.097.011.146.01.206.018.41.039.615.071.484.074.959.198 1.417.37a7.284 7.284 0 0 1 4.364 4.556 6.971 6.971 0 0 1 .336 1.85.218.218 0 0 0 .01.111v.588a.201.201 0 0 0-.01.11 7.04 7.04 0 0 1-.806 2.937 7.247 7.247 0 0 1-7.037 3.893 7.03 7.03 0 0 1-1.386-.25 7.213 7.213 0 0 1-4.034-2.91 7.215 7.215 0 0 1-1.227-3.47 1.836 1.836 0 0 0-.022-.254V69.551a.083.083 0 0 0 .01-.053c.016-.315.053-.628.112-.938a7.25 7.25 0 0 1 6.637-5.907c.056-.001.111-.007.166-.017Zm-5.702 7.258a6.052 6.052 0 1 0 3.731-5.598 6.045 6.045 0 0 0-3.731 5.598Z"
      />
      <path
        fill="#F79421"
        d="M22.651 69.68c.257.042.506.119.742.228a1.6 1.6 0 0 1 .716.637c.111.193.181.408.206.63.038.28.025.565-.037.841a1.478 1.478 0 0 1-.923 1.088c-.252.1-.515.17-.783.208-.181.028-.362.048-.546.058-.063 0-.064 0-.064.07v1.078c0 .068 0 .068-.07.068h-.786c-.076 0-.072 0-.072-.07v-1.043c0-.087.007-.078-.08-.078h-.784c-.064 0-.064 0-.064.065v1.05c0 .08.008.076-.073.076h-.782c-.074 0-.075 0-.075-.076v-1.078c0-.025-.009-.04-.035-.037-.027.003-.039 0-.056 0h-1.717c-.031 0-.046 0-.046-.042v-.772c0-.037.015-.047.05-.046h.48c.099-.002.197-.014.293-.038a.259.259 0 0 0 .21-.213 1.62 1.62 0 0 0 .039-.387V67.86a1.69 1.69 0 0 0-.035-.364.277.277 0 0 0-.233-.228 1.88 1.88 0 0 0-.373-.034h-.362c-.063 0-.063 0-.063-.066v-.716c0-.082 0-.076.074-.076h1.702c.076 0 .076 0 .076-.075V65.23c0-.02.014-.037.039-.036h.814c.082 0 .075-.009.075.074v1.021c0 .096 0 .086.085.086h.78c.063 0 .063 0 .063-.068V65.229c0-.016.01-.038.036-.036h.815c.08 0 .076-.012.076.08v1.062c0 .053 0 .051.054.056.28.018.557.063.828.134.152.04.3.094.441.163a1.25 1.25 0 0 1 .684.823c.09.32.095.659.016.982a1.403 1.403 0 0 1-.566.816c-.222.153-.466.269-.725.344-.019.006-.035.001-.044.026Zm-2.545 1.68v1.127c0 .06 0 .06.06.06h.408a5.9 5.9 0 0 0 .867-.041 2.09 2.09 0 0 0 .497-.126.857.857 0 0 0 .515-.565c.08-.263.092-.541.035-.81a.85.85 0 0 0-.38-.575 1.222 1.222 0 0 0-.365-.147 3.183 3.183 0 0 0-.625-.088c-.324-.019-.648 0-.973-.01-.034 0-.046.013-.043.044.003.03 0 .032 0 .049l.004 1.083Zm0-3.086v1c0 .033.008.05.046.05.35.01.7 0 1.048-.03.17-.02.338-.06.498-.122a.754.754 0 0 0 .468-.54c.053-.214.061-.436.022-.654a.735.735 0 0 0-.517-.62 2.453 2.453 0 0 0-.683-.12c-.273-.014-.546 0-.82 0h-.035a.03.03 0 0 0-.025.008.03.03 0 0 0-.007.025v.056c.003.319.004.634.004.947h.001Z"
      />
      <path
        fill="#F26227"
        d="M131.959 79.33a9.002 9.002 0 0 0 9.002-9.003 9.002 9.002 0 1 0-18.004 0 9.002 9.002 0 0 0 9.002 9.003Z"
      />
      <path
        fill="#D74B27"
        d="M131.959 78.207a7.879 7.879 0 0 0 7.879-7.88 7.879 7.879 0 1 0-15.758 0 7.88 7.88 0 0 0 7.879 7.88Z"
      />
      <path
        fill="#F79421"
        d="M131.612 63.073h.709a.762.762 0 0 0 .147.01 7.111 7.111 0 0 1 2.028.441 7.281 7.281 0 0 1 4.365 4.557 7.024 7.024 0 0 1 .335 1.851.223.223 0 0 0 .01.112v.588a.203.203 0 0 0-.01.111 7.029 7.029 0 0 1-.806 2.936 7.262 7.262 0 0 1-8.423 3.644 7.222 7.222 0 0 1-4.034-2.91 7.233 7.233 0 0 1-1.227-3.47 1.826 1.826 0 0 0-.022-.255V69.999a.086.086 0 0 0 .01-.055c.016-.315.053-.627.112-.937a7.051 7.051 0 0 1 .601-1.827 7.28 7.28 0 0 1 4.769-3.88c.416-.104.84-.17 1.267-.2.057-.005.114-.014.169-.027Zm-5.702 7.257a6.051 6.051 0 1 0 12.103-.002 6.051 6.051 0 0 0-12.103.002Z"
      />
      <path
        fill="#F79421"
        d="M133.783 70.116c.254.043.502.12.735.23.299.133.549.356.716.637.111.193.182.408.206.63.038.28.025.565-.037.842a1.463 1.463 0 0 1-.923 1.086c-.252.1-.515.17-.783.207a6.162 6.162 0 0 1-.546.06c-.063 0-.064 0-.064.07v1.077c0 .068 0 .07-.07.07h-.786c-.076 0-.072 0-.072-.073V73.91c0-.087 0-.078-.079-.078h-.785c-.064 0-.064 0-.064.066v1.05c0 .08.008.076-.073.076h-.775c-.073 0-.075 0-.075-.078V73.87c0-.025-.009-.038-.035-.037H128.5c-.031 0-.045-.007-.045-.041v-.772c0-.038.014-.047.05-.047h.479a1.38 1.38 0 0 0 .294-.038.26.26 0 0 0 .21-.213c.028-.127.041-.256.038-.386v-4.038a1.667 1.667 0 0 0-.035-.366.277.277 0 0 0-.232-.227 1.88 1.88 0 0 0-.374-.034h-.361c-.064 0-.064 0-.064-.066v-.716c0-.081 0-.077.074-.077h1.702c.077 0 .077 0 .077-.073v-1.07c0-.018.013-.039.038-.037h.814c.083 0 .075-.007.075.073v1.022c0 .096-.007.085.085.085h.78c.063 0 .063 0 .063-.066v-1.077c0-.017.01-.039.037-.037h.814c.081 0 .076-.01.076.08v1.065c0 .053 0 .053.055.056.279.02.556.064.827.135.152.04.3.094.441.162a1.247 1.247 0 0 1 .684.823c.09.32.095.66.016.983a1.393 1.393 0 0 1-.566.815c-.221.153-.466.27-.725.344-.019.001-.035-.002-.044.022Zm-2.544 1.681v1.127c0 .06 0 .06.06.06h.407c.29.007.58-.007.868-.043.17-.02.337-.063.496-.125a.855.855 0 0 0 .515-.566c.08-.262.092-.541.035-.81a.842.842 0 0 0-.379-.575 1.222 1.222 0 0 0-.366-.146 3.416 3.416 0 0 0-.625-.089c-.323-.019-.648 0-.973-.01-.034 0-.046.013-.043.046.003.032 0 .032 0 .048.003.362.003.722.005 1.084Zm0-3.087v1.002c0 .032.007.05.045.048.35.01.7 0 1.048-.03.171-.019.339-.06.499-.121a.748.748 0 0 0 .467-.538c.054-.215.061-.438.022-.656a.733.733 0 0 0-.517-.62 2.45 2.45 0 0 0-.682-.12c-.274-.014-.547 0-.82-.007h-.036c-.022 0-.034.01-.032.034v.056l.004.953h.002Z"
      />
      <path
        fill="#BA3D11"
        d="m44.33 40.411-.974-3.788c-2.499-9.735-14.272-13.574-22.035-7.188L5.886 42.139l22.44 5.465 10.692-8.796 2.94 3.567"
      />
      <path
        fill="#A31905"
        d="m105.314 40.522.975-3.788c2.499-9.736 14.272-13.574 22.035-7.189l15.442 12.706-22.438 5.464-10.701-8.804-2.94 3.567"
      />
      <path fill="#FA8CB2" d="M78.161 81.43H46.665V96.25h31.496V81.43Z" />
      <path fill="#EFE1DA" d="M78.163 83.281H50.37v11.116h27.792V83.281Z" />
      <path
        fill="#001A1B"
        d="M55.93 83.281h-1.853v11.116h1.852V83.281ZM61.487 83.281h-1.852v11.116h1.852V83.281ZM67.05 83.275h-1.852v11.116h1.852V83.275ZM72.608 83.275h-1.853v11.116h1.853V83.275Z"
      />
      <path fill="#F46461" d="M73.526 96.256h28.732V81.433H73.526v14.823Z" />
      <path fill="#EFE1DA" d="M72.599 94.403h25.954V83.287H72.6v11.116Z" />
      <path
        fill="#001A1B"
        d="M92.994 94.403h1.852V83.287h-1.852v11.116ZM87.435 94.403h1.853V83.287h-1.853v11.116ZM81.873 94.397h1.852V83.281h-1.852v11.116ZM76.315 94.397h1.852V83.281h-1.852v11.116Z"
      />
      <path
        fill="#883A62"
        d="M100.419 82.355H48.488v6.492h51.931v-6.492Z"
        opacity={0.3}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path
        stroke="#070707"
        strokeMiterlimit={10}
        strokeWidth={2.707}
        d="M117.328 67.674a18.186 18.186 0 0 1-5.414 12.95 18.186 18.186 0 0 1-13.021 5.243c-9.996-.13-17.953-8.464-17.953-18.463a5.793 5.793 0 0 0-5.793-5.793h-.273M32.42 67.674a18.194 18.194 0 0 0 18.438 18.193c9.995-.13 17.951-8.465 17.951-18.463a5.792 5.792 0 0 1 5.795-5.794h.27"
      />
      <path
        fill="#8E97C4"
        d="M32.42 67.674h36.39a18.194 18.194 0 0 1-36.39 0ZM80.77 67.507h36.389a18.194 18.194 0 0 1-36.388 0Z"
        opacity={0.57}
        style={{
          mixBlendMode: "color-dodge",
        }}
      />
      <path
        fill="#FFF8DA"
        d="M51.215 72.18c6.137 0 11.112-4.975 11.112-11.112 0-6.137-4.975-11.112-11.112-11.112-6.137 0-11.112 4.975-11.112 11.112 0 6.137 4.975 11.112 11.112 11.112Z"
      />
      <path
        fill="#ABA2CA"
        d="M98.128 72.18c6.137 0 11.112-4.975 11.112-11.112 0-6.137-4.975-11.112-11.112-11.112-6.136 0-11.111 4.975-11.111 11.112 0 6.137 4.975 11.112 11.111 11.112Z"
      />
      <path
        fill="#2C1F41"
        d="M51.215 67.24a6.172 6.172 0 1 0 0-12.345 6.172 6.172 0 0 0 0 12.345Z"
      />
      <path
        fill="#172027"
        d="M98.129 67.24a6.172 6.172 0 1 0 0-12.345 6.173 6.173 0 0 0 0 12.345Z"
      />
      <path
        fill="#4D2F47"
        d="M79.997 79.674a5.5 5.5 0 0 1-5.5-5.501v-9.849h6.132a5.803 5.803 0 0 1 5.653 7.125l-.928 3.97a5.502 5.502 0 0 1-5.357 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M79.925 77.88a3.637 3.637 0 1 0 0-7.273 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M79.876 76.984a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path
        fill="#883A62"
        d="M69.015 79.63a5.5 5.5 0 0 0 5.501-5.501V64.28h-6.134a5.803 5.803 0 0 0-5.653 7.125l.93 3.97a5.5 5.5 0 0 0 5.356 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M69.084 77.836a3.637 3.637 0 1 0 0-7.273 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M69.134 76.94a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path
        fill="#DF4624"
        d="m97.631 35.391 10.962-13.806v4.313c0 3.056-2.425 5.91-6.468 7.604l-4.494 1.889Z"
      />
      <path
        fill="#F96020"
        d="M53.186 35.227 42.23 21.422v4.313c0 3.055 2.424 5.908 6.468 7.603l4.488 1.889Z"
      />
      <path
        fill="#BA3272"
        d="M71.658 28.25V9.848l-1.294.815c-7.357 4.633-7.357 12.145 0 16.778l1.294.809Z"
      />
      <path
        fill="#8E97C4"
        d="M71.658 28.25 57.043 16.746v6.903l14.615 11.504"
      />
      <path
        fill="#BA3272"
        d="M57.043 23.649V2.939l-4.386 5.525c-4.323 5.446-6.574 11.451-6.574 17.54v6.848h23.426"
      />
      <path
        fill="#532D5A"
        d="M79.16 28.414V10.006l1.29.816c7.35 4.631 7.35 12.145 0 16.777l-1.29.815Z"
      />
      <path fill="#4F4F8E" d="M79.16 28.414 93.773 16.91v6.903L79.16 35.316" />
      <path
        fill="#532D5A"
        d="M93.776 23.814V3.104L98.16 8.63c4.324 5.439 6.575 11.45 6.575 17.54v6.847H81.533"
      />
      <path
        fill="#F96020"
        d="m71.658 28.25-10.96-13.806v4.314c0 3.055 2.424 5.908 6.46 7.603l4.5 1.889Z"
      />
      <path
        fill="#DF4624"
        d="M79.16 28.415 90.12 14.608v4.313c0 3.056-2.424 5.91-6.468 7.603l-4.494 1.89Z"
      />
      <path
        fill="#F96020"
        d="M75.507 35.997a8.03 8.03 0 1 0 0-16.061 8.03 8.03 0 0 0 0 16.061Z"
      />
      <path
        fill="#D74816"
        d="M75.505 34.996a7.03 7.03 0 1 0 0-14.06 7.03 7.03 0 0 0 0 14.06Z"
      />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace115;
