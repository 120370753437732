import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace672 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#A9D4EB" d="M150.675 0H0v150.675h150.675V0Z" />
      <path fill="#383838" d="M104.968 100.02H44.013v46.995h60.955V100.02Z" />
      <path
        fill="#7814BC"
        d="M104.821 100.735H73.784v46.28h31.037v-46.28Z"
        opacity={0.64}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path
        fill="#DF2D2C"
        d="M30.66 147.015H20.223v-13.23c0-13.818 11.242-25.064 25.063-25.064v10.437a14.642 14.642 0 0 0-14.626 14.627v13.23Z"
      />
      <path
        fill="#C12E58"
        d="M128.635 147.015h-10.437v-13.23a14.642 14.642 0 0 0-14.626-14.627v-10.437c13.818 0 25.063 11.244 25.063 25.064v13.23Z"
      />
      <path
        fill="#383838"
        d="M46.61 107.325h-6.616v15.876h6.615v-15.876ZM108.79 107.325h-6.615v15.876h6.615v-15.876Z"
      />
      <path
        fill="#172027"
        d="M131.377 33.134v25.225a17.786 17.786 0 0 1-17.787 17.787h-4.806l-6.966 26.098a21.918 21.918 0 0 1-21.178 16.27h-5.76v-85.38"
      />
      <path
        fill="#542F5F"
        d="M18.967 33.14V57.77a17.787 17.787 0 0 0 17.787 17.787h4.807l6.96 26.101a21.921 21.921 0 0 0 21.188 16.267h5.76V33.14"
      />
      <path
        fill="#F26227"
        d="M20.827 78.892a9.002 9.002 0 1 0 0-18.005 9.002 9.002 0 0 0 0 18.005Z"
      />
      <path
        fill="#D74B27"
        d="M20.827 77.769a7.88 7.88 0 1 0 0-15.759 7.88 7.88 0 0 0 0 15.759Z"
      />
      <path
        fill="#F79421"
        d="M20.48 62.637h.709c.048.007.097.01.146.01.206.017.41.038.615.07.484.074.959.198 1.417.37a7.284 7.284 0 0 1 4.364 4.556 6.973 6.973 0 0 1 .336 1.851.218.218 0 0 0 .01.11v.588a.201.201 0 0 0-.01.11 7.04 7.04 0 0 1-.806 2.937 7.247 7.247 0 0 1-7.037 3.893 7.03 7.03 0 0 1-1.386-.25 7.213 7.213 0 0 1-4.034-2.91 7.215 7.215 0 0 1-1.227-3.47 1.838 1.838 0 0 0-.022-.254V69.551a.083.083 0 0 0 .01-.052c.016-.315.053-.629.112-.938a7.25 7.25 0 0 1 6.637-5.906c.056-.003.111-.01.166-.018Zm-5.702 7.257a6.052 6.052 0 1 0 3.731-5.598 6.045 6.045 0 0 0-3.731 5.598Z"
      />
      <path
        fill="#F79421"
        d="M22.651 69.68c.257.042.507.12.742.229a1.6 1.6 0 0 1 .716.636c.111.194.181.408.206.63.038.28.026.565-.037.842a1.48 1.48 0 0 1-.923 1.088c-.252.1-.515.169-.783.207-.181.028-.362.048-.546.059-.063 0-.064 0-.064.07v1.078c0 .067 0 .067-.07.067h-.786c-.076 0-.072 0-.072-.07v-1.043c0-.086.008-.077-.08-.077h-.784c-.063 0-.063 0-.063.064v1.05c0 .08.007.076-.074.076h-.782c-.073 0-.075 0-.075-.076v-1.078c0-.025-.009-.04-.035-.036-.027.003-.038 0-.056 0h-1.717c-.03 0-.046 0-.046-.043v-.772c0-.037.015-.047.05-.045h.48c.099-.002.197-.015.294-.038a.259.259 0 0 0 .21-.214c.028-.127.04-.256.038-.386V67.86a1.696 1.696 0 0 0-.035-.365.277.277 0 0 0-.233-.228 1.878 1.878 0 0 0-.373-.034h-.361c-.064 0-.064 0-.064-.066v-.716c0-.082 0-.076.074-.076h1.702c.076 0 .076 0 .076-.075v-1.07c0-.02.014-.037.039-.035h.814c.082 0 .075-.01.075.073v1.022c0 .095 0 .085.085.085h.78c.063 0 .063 0 .063-.068V65.23c0-.016.01-.038.036-.037h.815c.08 0 .076-.012.076.08v1.062c0 .053 0 .052.055.056.28.019.556.064.827.134.152.04.3.094.441.163a1.25 1.25 0 0 1 .684.823c.09.32.095.659.016.982a1.403 1.403 0 0 1-.566.816c-.222.153-.466.27-.725.344-.019.006-.035.001-.044.026Zm-2.544 1.681v1.126c0 .06 0 .06.06.06h.407a5.9 5.9 0 0 0 .867-.04c.17-.022.337-.065.497-.127a.858.858 0 0 0 .515-.565c.08-.262.092-.541.035-.81a.848.848 0 0 0-.38-.574 1.224 1.224 0 0 0-.365-.147 3.19 3.19 0 0 0-.625-.089c-.323-.019-.648 0-.973-.01-.034 0-.046.013-.043.044.003.031 0 .033 0 .049l.005 1.083Zm0-3.087v1c0 .034.007.051.045.05.35.01.7 0 1.048-.03.17-.019.338-.06.499-.122a.753.753 0 0 0 .467-.54c.054-.213.061-.436.022-.653a.735.735 0 0 0-.517-.62 2.454 2.454 0 0 0-.682-.121c-.274-.013-.547 0-.82 0h-.036l-.013.001a.029.029 0 0 0-.018.02.03.03 0 0 0-.001.013v.055c.003.319.004.635.004.947h.002Z"
      />
      <path
        fill="#F26227"
        d="M131.959 79.33a9.002 9.002 0 0 0 9.002-9.002 9.002 9.002 0 1 0-18.004 0 9.002 9.002 0 0 0 9.002 9.002Z"
      />
      <path
        fill="#D74B27"
        d="M131.959 78.207a7.879 7.879 0 0 0 7.879-7.88 7.879 7.879 0 1 0-15.758 0 7.879 7.879 0 0 0 7.879 7.88Z"
      />
      <path
        fill="#F79421"
        d="M131.612 63.073h.708a.765.765 0 0 0 .147.01c.206.018.411.04.615.071.483.075.957.199 1.414.37a7.283 7.283 0 0 1 4.365 4.558 7.102 7.102 0 0 1 .335 1.85.223.223 0 0 0 .01.112v.588a.203.203 0 0 0-.01.112 7.029 7.029 0 0 1-.806 2.936 7.262 7.262 0 0 1-8.423 3.643 7.222 7.222 0 0 1-4.034-2.91 7.233 7.233 0 0 1-1.227-3.47 1.99 1.99 0 0 0-.022-.255V69.999a.093.093 0 0 0 .01-.055c.016-.314.053-.627.112-.936a7.269 7.269 0 0 1 6.637-5.908 1.08 1.08 0 0 0 .169-.027Zm-5.702 7.258a6.046 6.046 0 0 0 7.234 5.934 6.053 6.053 0 1 0-7.234-5.934Z"
      />
      <path
        fill="#F79421"
        d="M133.783 70.116c.254.043.502.12.735.23.299.133.549.356.716.637.111.194.181.408.206.63.038.28.025.565-.037.842a1.464 1.464 0 0 1-.923 1.086c-.252.1-.515.17-.784.207-.18.028-.361.049-.545.06-.063 0-.065 0-.065.07v1.077c0 .068 0 .07-.069.07h-.786c-.077 0-.072 0-.072-.073V73.91c0-.087 0-.078-.08-.078h-.784c-.064 0-.064 0-.064.066v1.05c0 .08.008.076-.073.076h-.775c-.073 0-.075 0-.075-.078V73.87c0-.025-.009-.038-.035-.037H128.5c-.031 0-.046-.007-.046-.041v-.772c0-.038.015-.047.05-.047h.48c.099-.002.197-.015.294-.038a.262.262 0 0 0 .139-.073.266.266 0 0 0 .071-.14c.028-.127.041-.256.038-.385v-4.038a1.667 1.667 0 0 0-.035-.366.277.277 0 0 0-.232-.228 1.887 1.887 0 0 0-.374-.034h-.361c-.064 0-.064 0-.064-.066v-.716c0-.08 0-.077.074-.077h1.702c.077 0 .077 0 .077-.073v-1.07c0-.018.013-.038.038-.037h.814c.082 0 .075-.007.075.074v1.021c0 .096-.007.085.085.085h.779c.064 0 .064 0 .064-.066v-1.077c0-.016.01-.038.036-.037h.815c.081 0 .076-.01.076.08v1.065c0 .053 0 .053.055.056.279.02.556.065.827.135.152.04.3.094.441.162a1.257 1.257 0 0 1 .684.823c.09.321.095.66.016.984a1.397 1.397 0 0 1-.566.814c-.221.153-.466.27-.725.344-.019.001-.035-.001-.044.022Zm-2.544 1.682v1.126c0 .06 0 .06.06.06h.407c.29.007.58-.007.867-.043a2.08 2.08 0 0 0 .497-.125.855.855 0 0 0 .515-.566c.08-.262.092-.54.035-.81a.845.845 0 0 0-.379-.574 1.222 1.222 0 0 0-.366-.147 3.416 3.416 0 0 0-.625-.088c-.323-.02-.648 0-.973-.01-.034 0-.046.013-.043.045.003.032 0 .032 0 .048.003.362.003.722.005 1.084Zm0-3.087v1.001c0 .032.007.05.045.048.35.01.7 0 1.048-.03.171-.018.338-.06.499-.121a.748.748 0 0 0 .467-.538 1.56 1.56 0 0 0 .022-.656.733.733 0 0 0-.517-.62 2.46 2.46 0 0 0-.682-.12c-.274-.014-.547 0-.821-.007h-.035c-.022 0-.034.01-.032.034v.056l.004.953h.002Z"
      />
      <path
        fill="#172027"
        d="m103.467 34.279 10.189-10.189a1.104 1.104 0 0 1 1.861.564 4.072 4.072 0 0 0 3.998 3.276 4.074 4.074 0 0 0 4.074-4.073v-4.738a4.16 4.16 0 0 0-6.183-3.638l-1.595.882a.967.967 0 0 1-1.422-1.004l.199-1.19a2.74 2.74 0 0 0-1.549-2.932 2.735 2.735 0 0 0-3.78 1.731l-.916 3.189a1.072 1.072 0 0 1-.873.764 1.079 1.079 0 0 1-1.061-.47l-1.572-2.352a2.022 2.022 0 1 0-3.109 2.555l1.838 1.837a1.62 1.62 0 0 1 .421 1.58 1.615 1.615 0 0 1-1.174 1.14 1.617 1.617 0 0 1-1.931-1.06l-1.051-3.152a2.598 2.598 0 0 0-2.466-1.777 2.598 2.598 0 0 0-2.262 3.888l.58 1.013a1.677 1.677 0 1 1-3.094 1.192l-.895-4.025a5.35 5.35 0 0 0-10.413-.136L77 34.279"
      />
      <path
        fill="#542F5F"
        d="M48.58 34.022 38.39 23.833a1.102 1.102 0 0 0-1.859.569 4.072 4.072 0 0 1-8.066-.798v-4.742a4.161 4.161 0 0 1 6.183-3.637l1.593.882a.966.966 0 0 0 1.422-1.003l-.197-1.19a2.736 2.736 0 0 1 5.329-1.198l.91 3.184a1.078 1.078 0 0 0 1.934.293l1.572-2.352a2.025 2.025 0 1 1 3.114 2.554l-1.839 1.833a1.616 1.616 0 0 0 .755 2.72 1.617 1.617 0 0 0 1.931-1.06l1.05-3.15a2.599 2.599 0 0 1 3.764-1.426 2.6 2.6 0 0 1 .96 3.542l-.579 1.013a1.676 1.676 0 0 0 2.505 2.14c.298-.238.505-.57.588-.943l.894-4.025a5.351 5.351 0 0 1 5.221-4.191 5.351 5.351 0 0 1 5.19 4.057l4.281 17.121"
      />
      <path fill="#E63580" d="M115.539 80.115H33.842v23.717h81.697V80.115Z" />
      <path fill="#DBB9BE" d="M74.692 103.833h37.644V82.488H74.692v21.345Z" />
      <path fill="#161F26" d="M103.525 103.833h2.403V82.488h-2.403v21.345Z" />
      <path fill="#161F26" d="M73.8 92.764h38.534v-.79H73.8v.79Z" />
      <path fill="#EFE1DA" d="M74.69 82.486H37.048v21.346H74.69V82.486Z" />
      <path fill="#161F26" d="M45.858 82.486h-2.403v21.346h2.403V82.486Z" />
      <path fill="#161F26" d="M75.582 91.974H37.047v.79h38.535v-.79Z" />
      <path
        fill="#161F26"
        d="M53.867 82.486h-2.404v21.346h2.404V82.486ZM61.877 82.486h-2.404v21.346h2.404V82.486ZM69.885 82.486h-2.403v21.346h2.403V82.486ZM81.9 82.486h-2.404v21.346H81.9V82.486ZM89.91 82.486h-2.404v21.346h2.404V82.486ZM97.92 82.486h-2.404v21.346h2.403V82.486Z"
      />
      <path
        fill="#7814BC"
        d="M114.489 81.317H35.746v5.25h78.743v-5.25Z"
        opacity={0.34}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path fill="#E63580" d="M115.539 103.366H33.645v2.099h81.894v-2.099Z" />
      <path
        fill="#7814BC"
        d="M113.438 104.416H34.695v3.15h78.743v-3.15Z"
        opacity={0.34}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path
        fill="#883A62"
        d="M65.09 69.118h-5.723a11.634 11.634 0 0 1-11.635-11.64V40.6l21.079-11.159v19.845a4.962 4.962 0 0 1-4.963 4.957l1.24 1.24a8.764 8.764 0 0 1 0 12.399"
      />
      <path
        fill="#4D2F47"
        d="M60.063 66.569h4.96v4.96a2.48 2.48 0 1 1-4.96 0v-4.96Z"
      />
      <path
        fill="#FAE7DB"
        d="M46.492 56.718h7.164A11.433 11.433 0 0 1 65.09 68.154v3.442h-1.242v-4.958H58.96a8.673 8.673 0 0 1-8.673-8.68h-2.555l-1.24-1.24Z"
      />
      <path
        fill="#FA8424"
        d="M57.17 65.466a4.409 4.409 0 1 0 0-8.817 4.409 4.409 0 0 0 0 8.817Z"
      />
      <path
        fill="#4D2F47"
        d="M57.17 62.848a1.79 1.79 0 1 0 0-3.58 1.79 1.79 0 0 0 0 3.58ZM84.913 69.058h5.724a11.634 11.634 0 0 0 11.634-11.634V40.541l-21.077-11.16v19.845a4.961 4.961 0 0 0 4.96 4.96l-1.24 1.233a8.769 8.769 0 0 0 0 12.4"
      />
      <path
        fill="#4D2F47"
        d="M87.462 73.95a2.48 2.48 0 0 1-2.48-2.48v-4.96h4.959v4.96a2.48 2.48 0 0 1-2.479 2.48Z"
      />
      <path
        fill="#F9C1C0"
        d="M103.512 56.66h-7.164a11.433 11.433 0 0 0-11.435 11.433v3.445h1.24v-4.96h4.89a8.673 8.673 0 0 0 8.673-8.673h2.549l1.247-1.245Z"
      />
      <path
        fill="#C32429"
        d="M92.835 65.408a4.409 4.409 0 1 0 0-8.817 4.409 4.409 0 0 0 0 8.817Z"
      />
      <path
        fill="#010A15"
        d="M92.835 62.79a1.79 1.79 0 1 0 0-3.581 1.79 1.79 0 0 0 0 3.58Z"
      />
      <path
        fill="#361A11"
        d="M65.809 67.62h-.957a10.97 10.97 0 0 0-10.956-10.956h-10.12v-.957h10.12A11.926 11.926 0 0 1 65.81 67.62ZM83.931 67.748h.957a10.969 10.969 0 0 1 10.956-10.956h10.121v-.957H95.844a11.927 11.927 0 0 0-11.913 11.913Z"
      />
      <path
        fill="#727FBD"
        d="M53.928 73.015v-.957A10.97 10.97 0 0 1 42.974 61.1V50.981h-.957v10.12a11.926 11.926 0 0 0 11.911 11.914Z"
      />
      <path
        fill="#727FBD"
        d="M50.2 74.132V73.5a7.327 7.327 0 0 1-7.319-7.32V59.42h-.642v6.762a7.968 7.968 0 0 0 7.962 7.951Z"
      />
      <path
        fill="#4D2F47"
        d="M97.102 72.934v-.959a10.968 10.968 0 0 0 10.956-10.955V50.9h.957v10.12a11.927 11.927 0 0 1-11.913 11.914Z"
        opacity={0.56}
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        fill="#4D2F47"
        d="M100.83 74.051v-.64a7.327 7.327 0 0 0 7.319-7.318v-.306l.64-2.646v2.952a7.966 7.966 0 0 1-7.959 7.958Z"
        opacity={0.56}
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        fill="#F55B20"
        d="M74.264 65.64v14.984a5.686 5.686 0 0 1-5.586-6.702l1.506-8.282h4.08Z"
      />
      <path
        fill="#883A62"
        d="M74.12 65.64v14.984a5.683 5.683 0 0 0 5.586-6.702L78.2 65.64h-4.08Z"
      />
      <path fill="#542F5F" d="M74.382 12.936H53.214v19.845h21.168V12.936Z" />
      <path fill="#172027" d="M95.55 12.936H74.382v19.845H95.55V12.936Z" />
      <path fill="#172027" d="M112.749 31.458H37.338v2.646h75.411v-2.646Z" />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace672;
