import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace476 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#F9C1C0" d="M150.675 0H0v150.675h150.675V0Z" />
      <path fill="#BA3D11" d="M124.803 66.541H23.698v81.168h101.105V66.541Z" />
      <path fill="#542F5F" d="M101.677 66.32H45.483v81.389h56.194v-81.39Z" />
      <path fill="#172027" d="M102.165 66.32H73.553v81.389h28.612v-81.39Z" />
      <path
        fill="#8E97C4"
        d="M74.676 23.226h-11.63A30.099 30.099 0 0 0 36.35 39.439L14.87 80.732l33.18 38.42a35.187 35.187 0 0 0 26.626 12.187V23.226Z"
      />
      <path
        fill="#4F4F8E"
        d="M74.676 23.226h11.629a30.1 30.1 0 0 1 26.706 16.213l21.472 41.294-33.18 38.419a35.182 35.182 0 0 1-26.627 12.187V23.226Z"
      />
      <path
        fill="#B2BCDF"
        d="m54.65 84.129-24.557 24.557.34.338 24.556-24.556-.339-.34ZM57.52 84.447l-19.852 19.852.339.339 19.852-19.852-.338-.339ZM56.086 88.273l-13.794 13.793.34.339 13.793-13.793-.34-.34ZM64.377 87.635l-26.949 26.949.34.339 26.948-26.949-.339-.339ZM59.114 98.32l-18.258 18.258.339.339 18.258-18.258-.34-.339ZM65.015 98.958 44.843 119.13l.34.339 20.17-20.172-.338-.339ZM43.965 67.227l-26.23 26.23.338.339 26.23-26.23-.338-.34ZM31.314 76.093l-.346-.333c.941-.974 9.216-9.526 9.872-9.85l.216.428c-.484.26-6.092 5.977-9.742 9.755ZM41.735 61.804l-8.691 8.691.339.339 8.69-8.691-.338-.339ZM40.458 57.18 21.722 75.918l.339.339L40.797 57.52l-.339-.34ZM38.386 55.267l-6.219 6.219.339.339 6.219-6.22-.339-.338Z"
      />
      <path
        fill="#7272AE"
        d="m94.494 84.089-.339.339 24.557 24.557.339-.339-24.557-24.557ZM91.625 84.409l-.34.338 19.853 19.853.339-.339-19.852-19.852ZM93.06 88.236l-.339.339 13.793 13.793.339-.339L93.06 88.236ZM84.767 87.597l-.339.34 26.949 26.948.339-.339-26.949-26.949ZM90.03 98.28l-.34.34 18.259 18.258.339-.339L90.03 98.281ZM84.13 98.92l-.339.338 20.172 20.172.338-.339L84.13 98.92ZM105.179 67.187l-.339.339 26.231 26.23.338-.339-26.23-26.23ZM117.832 76.053c-3.65-3.778-9.261-9.494-9.742-9.759l.215-.428c.656.328 8.93 8.88 9.873 9.85l-.346.337ZM107.411 61.767l-.339.338 8.691 8.691.338-.339-8.69-8.69ZM108.687 57.142l-.339.339 18.736 18.736.339-.339-18.736-18.736ZM110.76 55.228l-.339.338 6.219 6.22.339-.34-6.219-6.218Z"
      />
      <path
        fill="#F26227"
        d="M20.827 78.892a9.002 9.002 0 1 0 0-18.005 9.002 9.002 0 0 0 0 18.005Z"
      />
      <path
        fill="#D74B27"
        d="M20.827 77.769a7.88 7.88 0 1 0 0-15.758 7.88 7.88 0 0 0 0 15.758Z"
      />
      <path
        fill="#F79421"
        d="M20.48 62.637h.709c.048.007.098.01.147.01.206.018.41.038.614.07.484.075.959.198 1.417.37a7.286 7.286 0 0 1 4.365 4.557 6.974 6.974 0 0 1 .335 1.85.218.218 0 0 0 .01.11v.589a.202.202 0 0 0-.01.11 7.036 7.036 0 0 1-.806 2.937 7.246 7.246 0 0 1-7.037 3.893 7.032 7.032 0 0 1-1.386-.25 7.214 7.214 0 0 1-4.034-2.91 7.217 7.217 0 0 1-1.227-3.47 1.835 1.835 0 0 0-.022-.254V69.552a.085.085 0 0 0 .01-.053c.016-.315.053-.628.112-.938a7.25 7.25 0 0 1 6.637-5.907 1.34 1.34 0 0 0 .166-.017Zm-5.702 7.257a6.053 6.053 0 1 0 3.731-5.598 6.044 6.044 0 0 0-3.731 5.598Z"
      />
      <path
        fill="#F79421"
        d="M22.651 69.68c.257.042.507.12.742.229a1.6 1.6 0 0 1 .716.636c.111.194.181.408.206.63.038.28.026.566-.037.842a1.48 1.48 0 0 1-.923 1.088c-.252.1-.515.17-.783.207-.181.028-.362.049-.546.059-.063 0-.064 0-.064.07v1.078c0 .067 0 .067-.07.067h-.786c-.076 0-.072 0-.072-.07v-1.042c0-.087.008-.078-.08-.078h-.784c-.063 0-.063 0-.063.064v1.05c0 .081.007.076-.074.076h-.782c-.073 0-.075 0-.075-.076v-1.077c0-.025-.009-.04-.035-.037-.027.003-.038 0-.056 0h-1.717c-.03 0-.046 0-.046-.043v-.771c0-.037.015-.048.05-.046h.48c.099-.002.197-.015.294-.038a.26.26 0 0 0 .21-.214 1.61 1.61 0 0 0 .038-.386V67.86a1.69 1.69 0 0 0-.035-.365.277.277 0 0 0-.233-.228 1.879 1.879 0 0 0-.373-.033h-.361c-.064 0-.064 0-.064-.066v-.716c0-.083 0-.077.074-.077h1.702c.076 0 .076 0 .076-.075v-1.07c0-.02.014-.037.039-.035h.814c.082 0 .075-.01.075.073v1.022c0 .095 0 .085.085.085h.78c.063 0 .063 0 .063-.067V65.23c0-.016.01-.038.036-.037h.815c.08 0 .076-.011.076.08v1.062c0 .053 0 .052.055.056.279.019.556.064.827.134.152.04.3.095.441.163a1.25 1.25 0 0 1 .684.823c.09.32.095.66.016.982a1.402 1.402 0 0 1-.566.816c-.222.153-.466.27-.725.344-.019.006-.035.002-.044.027Zm-2.544 1.681v1.126c0 .06 0 .06.06.06h.407a5.9 5.9 0 0 0 .867-.04c.17-.022.337-.065.497-.127a.856.856 0 0 0 .515-.564c.08-.263.092-.542.035-.81a.85.85 0 0 0-.38-.575 1.217 1.217 0 0 0-.365-.147 3.174 3.174 0 0 0-.625-.088c-.323-.02-.648 0-.973-.01-.034 0-.046.013-.043.043.003.031 0 .033 0 .049l.005 1.083Zm0-3.087v1c0 .034.007.051.045.05.35.01.7 0 1.048-.03.17-.019.338-.06.499-.121a.755.755 0 0 0 .467-.54c.054-.214.061-.437.022-.654a.735.735 0 0 0-.517-.62 2.454 2.454 0 0 0-.682-.121c-.274-.013-.547 0-.82 0h-.036a.03.03 0 0 0-.031.02.029.029 0 0 0-.001.014v.056c.003.318.004.634.004.946h.002Z"
      />
      <path
        fill="#F26227"
        d="M131.959 79.33a9.002 9.002 0 0 0 9.002-9.002 9.002 9.002 0 0 0-9.002-9.002 9.002 9.002 0 0 0-9.002 9.002 9.002 9.002 0 0 0 9.002 9.002Z"
      />
      <path
        fill="#D74B27"
        d="M131.959 78.207a7.88 7.88 0 1 0 0-15.759 7.88 7.88 0 0 0 0 15.76Z"
      />
      <path
        fill="#F79421"
        d="M131.612 63.074h.709a.754.754 0 0 0 .147.01 7.118 7.118 0 0 1 2.028.441 7.284 7.284 0 0 1 4.365 4.557 7.102 7.102 0 0 1 .335 1.85.224.224 0 0 0 .01.112v.588a.203.203 0 0 0-.01.112 7.03 7.03 0 0 1-.806 2.936 7.262 7.262 0 0 1-8.423 3.644 7.223 7.223 0 0 1-4.034-2.91 7.234 7.234 0 0 1-1.227-3.47 1.991 1.991 0 0 0-.022-.256V70a.093.093 0 0 0 .01-.055c.016-.314.053-.627.112-.936a7.28 7.28 0 0 1 5.37-5.708c.416-.103.84-.17 1.267-.2a1.08 1.08 0 0 0 .169-.026Zm-5.702 7.257a6.046 6.046 0 0 0 7.235 5.934 6.054 6.054 0 0 0 4.753-4.757 6.049 6.049 0 0 0-2.576-6.212 6.054 6.054 0 0 0-9.412 5.035Z"
      />
      <path
        fill="#F79421"
        d="M133.783 70.116c.255.043.502.12.735.23.299.134.549.356.716.638.112.193.182.407.206.629.038.28.025.566-.037.842a1.463 1.463 0 0 1-.923 1.086c-.252.1-.515.17-.783.208a6.124 6.124 0 0 1-.546.06c-.063 0-.064 0-.064.069v1.078c0 .067 0 .069-.069.069h-.787c-.076 0-.072 0-.072-.072V73.91c0-.086 0-.078-.079-.078h-.785c-.063 0-.063 0-.063.067v1.05c0 .079.007.076-.074.076h-.775c-.073 0-.075 0-.075-.078v-1.078c0-.025-.008-.038-.035-.037H128.5c-.031 0-.045-.007-.045-.04v-.772c0-.039.014-.047.05-.047h.479c.099-.003.198-.015.294-.039a.26.26 0 0 0 .21-.213c.028-.126.041-.255.038-.385v-4.038a1.667 1.667 0 0 0-.035-.366.277.277 0 0 0-.232-.228 1.88 1.88 0 0 0-.374-.034h-.361c-.063 0-.063 0-.063-.066v-.716c0-.08 0-.076.073-.076h1.702c.077 0 .077 0 .077-.074v-1.07c0-.018.013-.038.038-.037h.814c.083 0 .075-.007.075.074v1.022c0 .095-.007.085.086.085h.779c.063 0 .063 0 .063-.066v-1.078c0-.016.01-.038.037-.037h.814c.081 0 .077-.01.077.081v1.064c0 .053 0 .053.054.056.279.02.556.065.828.136.151.039.299.093.441.161a1.253 1.253 0 0 1 .683.823c.09.321.095.66.016.984a1.393 1.393 0 0 1-.566.814c-.221.154-.466.27-.724.344-.02.002-.036-.001-.045.022Zm-2.544 1.682v1.126c0 .06 0 .06.06.06h.407c.29.007.58-.007.868-.042.17-.021.337-.063.497-.125a.862.862 0 0 0 .514-.566c.08-.263.093-.542.035-.81a.843.843 0 0 0-.379-.575 1.222 1.222 0 0 0-.366-.147 3.416 3.416 0 0 0-.625-.088c-.323-.02-.648 0-.973-.01-.034 0-.045.013-.043.045.003.032 0 .032 0 .049.003.361.003.721.005 1.083Zm0-3.087v1.001c0 .032.007.05.045.049.35.01.7 0 1.049-.03.17-.019.338-.06.498-.122a.753.753 0 0 0 .467-.538c.054-.214.061-.438.022-.656a.731.731 0 0 0-.517-.62 2.45 2.45 0 0 0-.682-.119c-.274-.015-.547 0-.82-.007h-.036c-.022 0-.033.01-.032.034v.055l.004.953h.002Z"
      />
      <path
        fill="#883A62"
        d="M53.589 30.247 35.067 17.899l-4.912 6.138a10.914 10.914 0 0 0 2.469 15.895l8.615 5.744"
      />
      <path
        fill="#4D2F47"
        d="M94.728 30.198 113.25 17.85l4.911 6.14a10.9 10.9 0 0 1 .677 12.689 10.913 10.913 0 0 1-3.143 3.205l-8.617 5.745"
      />
      <path fill="#172027" d="M90.524 87.424H60.118v20.281h30.406V87.424Z" />
      <path
        fill="#EFE1DA"
        d="M65.07 87.337v13.946l-2.607-3.047a15.897 15.897 0 0 1-3.806-10.33v-.622l6.412.053Z"
      />
      <path
        fill="#DBB9BE"
        d="M85.069 87.378v13.939l2.606-3.047a15.893 15.893 0 0 0 3.813-10.332v-.62l-6.42.06Z"
      />
      <path
        fill="#4D2F47"
        d="M91.691 103.412v15.879l-4.914-9.527v28.584l-8.191-15.881-3.277 19.056v-34.936"
      />
      <path
        fill="#883A62"
        d="M59.035 103.306v15.88l4.914-9.528v28.584l8.191-15.879 3.277 19.055v-34.936M65.09 69.118h-5.723a11.635 11.635 0 0 1-11.635-11.64V40.6l21.079-11.159v19.845a4.961 4.961 0 0 1-4.963 4.957l1.24 1.24a8.766 8.766 0 0 1 0 12.399"
      />
      <path
        fill="#4D2F47"
        d="M60.063 66.57h4.96v4.959a2.48 2.48 0 1 1-4.96 0v-4.96Z"
      />
      <path
        fill="#FAE7DB"
        d="M46.492 56.719h7.164A11.435 11.435 0 0 1 65.09 68.154v3.442h-1.242v-4.958H58.96a8.672 8.672 0 0 1-8.673-8.68h-2.555l-1.24-1.24Z"
      />
      <path
        fill="#FA8424"
        d="M57.17 65.466a4.408 4.408 0 1 0 0-8.816 4.408 4.408 0 0 0 0 8.816Z"
      />
      <path
        fill="#4D2F47"
        d="M57.17 62.848a1.79 1.79 0 1 0 0-3.58 1.79 1.79 0 0 0 0 3.58ZM84.913 69.058h5.724a11.634 11.634 0 0 0 11.634-11.634V40.541l-21.077-11.16v19.845a4.961 4.961 0 0 0 4.96 4.96l-1.241 1.233a8.768 8.768 0 0 0 0 12.4"
      />
      <path
        fill="#4D2F47"
        d="M87.462 73.95a2.48 2.48 0 0 1-2.48-2.48v-4.96h4.958v4.96a2.48 2.48 0 0 1-2.478 2.48Z"
      />
      <path
        fill="#F9C1C0"
        d="M103.512 56.66h-7.164a11.433 11.433 0 0 0-11.435 11.433v3.445h1.24v-4.96h4.89a8.673 8.673 0 0 0 8.673-8.673h2.549l1.247-1.245Z"
      />
      <path
        fill="#C32429"
        d="M92.835 65.408a4.408 4.408 0 1 0 0-8.817 4.408 4.408 0 0 0 0 8.817Z"
      />
      <path
        fill="#010A15"
        d="M92.835 62.79a1.79 1.79 0 1 0 0-3.581 1.79 1.79 0 0 0 0 3.58Z"
      />
      <path
        fill="#361A11"
        d="M65.809 67.62h-.957a10.97 10.97 0 0 0-10.956-10.956H43.775v-.957h10.12A11.926 11.926 0 0 1 65.81 67.62ZM83.93 67.748h.958a10.969 10.969 0 0 1 10.956-10.956h10.121v-.957H95.844A11.927 11.927 0 0 0 83.93 67.748Z"
      />
      <path
        fill="#727FBD"
        d="M53.928 73.015v-.957a10.969 10.969 0 0 1-10.954-10.957V50.98h-.957v10.12a11.926 11.926 0 0 0 11.911 11.914Z"
      />
      <path
        fill="#727FBD"
        d="M50.2 74.132V73.5a7.326 7.326 0 0 1-7.319-7.319v-6.762h-.642v6.762a7.968 7.968 0 0 0 7.961 7.951Z"
      />
      <path
        fill="#4D2F47"
        d="M97.102 72.934v-.958a10.968 10.968 0 0 0 10.956-10.956V50.9h.957v10.12a11.927 11.927 0 0 1-11.913 11.914Z"
        opacity={0.56}
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        fill="#4D2F47"
        d="M100.83 74.051v-.64a7.327 7.327 0 0 0 7.319-7.318v-.306l.64-2.646v2.952a7.97 7.97 0 0 1-7.959 7.958Z"
        opacity={0.56}
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        fill="#4D2F47"
        d="M79.997 79.674a5.5 5.5 0 0 1-5.5-5.5v-9.85h6.132a5.804 5.804 0 0 1 5.652 7.125l-.927 3.97a5.502 5.502 0 0 1-5.357 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M79.925 77.88a3.637 3.637 0 1 0 0-7.273 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M79.876 76.984a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path
        fill="#883A62"
        d="M69.015 79.63a5.5 5.5 0 0 0 5.5-5.5v-9.85h-6.133a5.804 5.804 0 0 0-5.653 7.125l.93 3.97a5.5 5.5 0 0 0 5.356 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M69.084 77.837a3.637 3.637 0 1 0 0-7.274 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M69.134 76.94a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path fill="#542F5F" d="M74.382 12.936H53.214v19.845h21.168V12.936Z" />
      <path fill="#172027" d="M95.55 12.936H74.382v19.845H95.55V12.936Z" />
      <path fill="#172027" d="M112.749 31.458H37.338v2.646h75.411v-2.646Z" />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace476;
