import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace425 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#3A6A7E" d="M150.675 0H0v150.675h150.675V0Z" />
      <path
        fill="#111C0D"
        d="M28.981 27.075h90.655a28.982 28.982 0 0 1 28.981 28.98v91.658H0V56.056a28.981 28.981 0 0 1 28.981-28.981Z"
      />
      <path
        fill="#8E97C4"
        d="M74.676 23.226h-11.63A30.1 30.1 0 0 0 36.35 39.438L14.87 80.732l33.18 38.42a35.187 35.187 0 0 0 26.626 12.187V23.226Z"
      />
      <path
        fill="#4F4F8E"
        d="M74.676 23.226h11.63a30.1 30.1 0 0 1 26.705 16.213l21.472 41.293-33.179 38.42a35.195 35.195 0 0 1-26.628 12.187V23.226Z"
      />
      <path
        fill="#B2BCDF"
        d="m54.65 84.129-24.556 24.557.338.338L54.99 84.468l-.338-.34ZM57.52 84.447l-19.852 19.852.34.339 19.851-19.852-.338-.34ZM56.086 88.273l-13.794 13.793.34.339 13.793-13.793-.34-.34ZM64.377 87.635 37.43 114.584l.338.339 26.95-26.949-.34-.339ZM59.114 98.32l-18.258 18.258.339.339 18.258-18.258-.339-.339ZM65.015 98.958 44.844 119.13l.338.339 20.172-20.172-.339-.339ZM43.965 67.227l-26.23 26.23.338.339 26.23-26.23-.338-.34ZM31.314 76.093l-.346-.334c.941-.973 9.216-9.525 9.872-9.849l.216.428c-.484.26-6.092 5.977-9.742 9.755ZM41.735 61.804l-8.69 8.69.338.34 8.69-8.691-.338-.339ZM40.458 57.18 21.722 75.918l.339.339L40.797 57.52l-.339-.34ZM38.386 55.267l-6.219 6.219.339.339 6.219-6.22-.339-.338Z"
      />
      <path
        fill="#7272AE"
        d="m94.494 84.089-.338.338 24.557 24.557.338-.338-24.557-24.557ZM91.625 84.409l-.34.338 19.853 19.853.339-.339-19.852-19.852ZM93.06 88.236l-.339.339 13.794 13.793.338-.339L93.06 88.236ZM84.767 87.597l-.339.34 26.949 26.948.339-.339-26.949-26.949ZM90.03 98.28l-.34.34 18.259 18.258.339-.339L90.03 98.281ZM84.13 98.92l-.339.338 20.172 20.172.339-.339L84.13 98.92ZM105.179 67.187l-.339.339 26.231 26.23.338-.339-26.23-26.23ZM117.832 76.053c-3.65-3.778-9.261-9.495-9.741-9.76l.214-.427c.656.328 8.93 8.88 9.873 9.85l-.346.337ZM107.411 61.767l-.339.338 8.691 8.691.339-.339-8.691-8.69ZM108.687 57.142l-.339.339 18.736 18.736.339-.339-18.736-18.736ZM110.76 55.228l-.339.338 6.219 6.22.339-.34-6.219-6.218Z"
      />
      <path
        fill="#AAAECC"
        d="M29.51 78.173h-.309a15.697 15.697 0 0 1-15.695-15.698v-9.452h39.145l-23.14 25.15Z"
      />
      <path
        fill="#727FBD"
        d="M118.673 78.173h.316a15.696 15.696 0 0 0 15.688-15.698v-9.452h-32.928l16.924 25.15Z"
      />
      <path
        fill="#4D2F47"
        d="M120.96 58.74a8.003 8.003 0 0 1 0 16.003M27.223 74.743a8.003 8.003 0 0 1 0-16.004"
      />
      <path
        fill="#727FBD"
        d="M27.223 73.69a5.19 5.19 0 0 1 0-10.379M120.343 63.311a5.192 5.192 0 0 1 5.103 5.19 5.193 5.193 0 0 1-5.103 5.19"
      />
      <path
        fill="#883A62"
        d="M74.213 80.151H34.848c-3.79 0-5.936 4.344-3.634 7.35l5.267 6.887a9.492 9.492 0 0 0 7.543 3.728h30.189"
      />
      <path
        fill="#883A62"
        d="M71.042 80.151h42.983c3.79 0 5.936 4.344 3.634 7.35l-5.267 6.887a9.486 9.486 0 0 1-7.54 3.733h-33.81"
      />
      <path fill="#542F5F" d="M109.095 83.841H39.33v9.514h69.765v-9.514Z" />
      <path fill="#172027" d="M110.741 81.827H74.367v5.457h36.374v-5.457Z" />
      <path
        fill="#F489AE"
        d="M104.78 81.777a2.268 2.268 0 0 1 1.608.666 2.273 2.273 0 0 1 .666 1.608v3.183h-4.546V84.05a2.274 2.274 0 0 1 2.272-2.274Z"
      />
      <path fill="#172027" d="M104.326 91.78H74.317v3.637h30.009v-3.636Z" />
      <path
        fill="#F489AE"
        d="M100.689 91.78h-4.546v3.637h4.546v-3.636ZM101.649 87.334v8.135h5.457a7.72 7.72 0 0 0-2.808-5.946l-2.649-2.189ZM108.924 84.555v9.094l1.963-1.636a9.71 9.71 0 0 0 3.494-7.458 2.731 2.731 0 0 0-2.729-2.728 2.73 2.73 0 0 0-2.728 2.728Z"
      />
      <path fill="#172027" d="M37.944 87.234h36.373v-5.457H37.944v5.457Z" />
      <path
        fill="#F9C1C0"
        d="M46.177 87.178H41.63v-3.182a2.274 2.274 0 1 1 4.549 0v3.182h-.002Z"
      />
      <path fill="#172027" d="M44.359 95.367h30.008v-3.636H44.36v3.636Z" />
      <path
        fill="#F9C1C0"
        d="M47.997 95.367h4.547v-3.636h-4.547v3.636ZM47.037 87.284v8.134h-5.456a7.714 7.714 0 0 1 2.807-5.951l2.65-2.183ZM39.762 84.506v9.093L37.8 91.963a9.703 9.703 0 0 1-3.495-7.457 2.728 2.728 0 1 1 5.457 0ZM53.048 87.178H48.5v-3.182a2.274 2.274 0 0 1 4.548 0v3.182h-.001ZM54.868 95.367h4.547v-3.636h-4.547v3.636ZM59.732 87.178h-4.547v-3.182a2.274 2.274 0 0 1 4.548 0v3.182h-.001ZM61.552 95.367h4.547v-3.636h-4.547v3.636ZM66.79 87.178h-4.547v-3.182a2.274 2.274 0 0 1 4.548 0v3.182h-.002ZM68.608 95.367h4.546v-3.636h-4.546v3.636ZM74.212 87.178h-4.547v-3.182a2.274 2.274 0 0 1 4.548 0v3.182h-.001Z"
      />
      <path
        fill="#F489AE"
        d="M75.479 95.469h4.546v-3.637H75.48v3.637ZM82.35 95.469h4.546v-3.637H82.35v3.637ZM89.034 95.469h4.546v-3.637h-4.546v3.637ZM96.09 95.469h4.548v-3.637h-4.547v3.637ZM79.474 87.178h-4.547v-3.182a2.274 2.274 0 0 1 4.549 0v3.182h-.002ZM86.16 87.178h-4.547v-3.182a2.274 2.274 0 1 1 4.548 0v3.182h-.001ZM93.216 87.178h-4.547v-3.182a2.274 2.274 0 0 1 4.548 0v3.182h-.001Z"
      />
      <path
        stroke="#070707"
        strokeMiterlimit={10}
        strokeWidth={2.707}
        d="M117.328 67.674a18.186 18.186 0 0 1-5.414 12.951 18.187 18.187 0 0 1-13.021 5.242c-9.996-.13-17.953-8.464-17.953-18.463a5.793 5.793 0 0 0-5.794-5.793h-.272M32.42 67.674a18.196 18.196 0 0 0 18.438 18.193c9.996-.13 17.951-8.464 17.951-18.463a5.794 5.794 0 0 1 5.795-5.793h.27"
      />
      <path
        fill="#8E97C4"
        d="M32.42 67.674h36.39a18.194 18.194 0 1 1-36.39 0ZM80.77 67.507h36.389a18.195 18.195 0 0 1-36.388 0Z"
        opacity={0.57}
        style={{
          mixBlendMode: "color-dodge",
        }}
      />
      <path
        fill="#001A1B"
        d="M53.445 78.72c7.605 0 13.77-6.165 13.77-13.771 0-7.605-6.165-13.77-13.77-13.77-7.606 0-13.771 6.164-13.771 13.77s6.165 13.771 13.77 13.771Z"
      />
      <path
        fill="#EFE1DA"
        d="M53.445 73.474a8.525 8.525 0 1 0 0-17.05 8.525 8.525 0 0 0 0 17.05Z"
      />
      <path
        fill="#ED7E29"
        d="M53.445 70.851a5.902 5.902 0 1 0 0-11.804 5.902 5.902 0 0 0 0 11.804Z"
      />
      <path fill="#EFE1DA" d="M54.1 57.81v5.244l4.59-4.59" />
      <path
        stroke="#883A62"
        strokeMiterlimit={10}
        strokeWidth={1.203}
        d="M53.476 74.786c5.445 0 9.859-4.414 9.859-9.86 0-5.444-4.414-9.858-9.86-9.858-5.444 0-9.859 4.414-9.859 9.859s4.415 9.86 9.86 9.86Z"
      />
      <path
        fill="#001A1B"
        d="M95.394 78.293c7.606 0 13.771-6.165 13.771-13.77 0-7.606-6.165-13.771-13.77-13.771-7.606 0-13.772 6.165-13.772 13.77 0 7.606 6.166 13.771 13.771 13.771Z"
      />
      <path
        fill="#EFE1DA"
        d="M95.394 73.047a8.525 8.525 0 1 0 0-17.05 8.525 8.525 0 0 0 0 17.05Z"
      />
      <path
        fill="#ED7E29"
        d="M95.394 70.425a5.902 5.902 0 1 0 0-11.804 5.902 5.902 0 0 0 0 11.804Z"
      />
      <path fill="#EFE1DA" d="M96.05 57.383v5.245l4.591-4.59" />
      <path
        stroke="#883A62"
        strokeMiterlimit={10}
        strokeWidth={1.203}
        d="M95.425 74.36c5.445 0 9.859-4.414 9.859-9.86 0-5.444-4.414-9.859-9.859-9.859s-9.86 4.415-9.86 9.86c0 5.445 4.415 9.859 9.86 9.859Z"
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.203}
        d="M81.435 63.551v-7.69a13.17 13.17 0 0 1 13.17-13.17h2.478"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.203}
        d="M80.27 63.504v-8.247A14.112 14.112 0 0 1 94.38 41.145h2.657"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.203}
        d="M77.713 63.57v-9.474a16.225 16.225 0 0 1 16.226-16.233h3.055M67.577 63.551v-7.69a13.17 13.17 0 0 0-13.17-13.17h-2.475"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.203}
        d="M68.743 63.504v-8.247a14.112 14.112 0 0 0-14.112-14.112H51.98"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.203}
        d="M71.3 63.57v-9.474a16.225 16.225 0 0 0-16.226-16.233h-3.055"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        fill="#001A1B"
        d="m105.868 55.806 12.618 10.096h-12.618M43.372 55.806 30.754 65.902h12.618"
      />
      <path
        fill="#4D2F47"
        d="M79.998 79.674a5.5 5.5 0 0 1-5.501-5.5v-9.85h6.133a5.803 5.803 0 0 1 5.652 7.125l-.928 3.97a5.502 5.502 0 0 1-5.356 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M79.925 77.88a3.637 3.637 0 1 0 0-7.273 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M79.875 76.984a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path
        fill="#883A62"
        d="M69.015 79.63a5.5 5.5 0 0 0 5.5-5.501V64.28h-6.133a5.803 5.803 0 0 0-5.653 7.125l.93 3.97a5.5 5.5 0 0 0 5.356 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M69.084 77.837a3.637 3.637 0 1 0 0-7.274 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M69.134 76.94a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path
        fill="#AF3271"
        d="m74.145 30.37-.051-5.233a2.308 2.308 0 0 0 2.143-1.45l4.307-10.769h2.75c6.476 0 9.894 7.674 5.56 12.495l-4.47 4.957H74.145Z"
      />
      <path
        fill="#E9632A"
        d="M74.165 20.636a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path
        fill="#E9632A"
        d="M74.165 25.174a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path
        fill="#DE2F7A"
        d="M74.378 25.137h-.183a2.308 2.308 0 0 1-2.143-1.451l-4.307-10.77h-2.749c-6.477 0-9.894 7.673-5.56 12.494l4.47 4.968h10.24"
      />
      <path fill="#4F4F8E" d="M74.494 30.37H63.905v4.538h10.589V30.37Z" />
      <path fill="#532D5C" d="M74.144 34.908h10.24V30.37h-10.24v4.538Z" />
      <path
        fill="#EB6329"
        d="M74.203 16.058a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path fill="#C84626" d="M74.203 16.135a2.27 2.27 0 0 1 0 4.539" />
      <path
        fill="#C84626"
        d="M74.203 20.596a2.267 2.267 0 0 1 2.327 2.269 2.27 2.27 0 0 1-2.327 2.269"
      />
      <path
        fill="#E8582C"
        d="M74.145 30.37h-10.24v.698h10.24v-.698ZM74.145 34.56h-10.24v.698h10.24v-.699Z"
      />
      <path
        fill="#F4ACC5"
        d="M74.145 31.03h-10.24v.388h10.24v-.388ZM74.145 34.172h-10.24v.388h10.24v-.388ZM74.145 32.115h-.698v1.396h.698v-1.396Z"
      />
      <path
        fill="#E9632A"
        d="m58.374 17.024-.108.162 13.615 9.076.107-.162-13.614-9.076ZM57.659 20.856l-.07.181 13.614 5.237.07-.18-13.614-5.238ZM58.682 23.987l-.035.192 11.52 2.093.035-.191-11.52-2.094Z"
      />
      <path
        fill="#C9481D"
        d="M74.144 31.068h10.24v-.698h-10.24v.698ZM74.144 35.256h10.24v-.698h-10.24v.698Z"
      />
      <path
        fill="#F28DB2"
        d="M74.144 31.417h10.24v-.388h-10.24v.388ZM74.144 34.56h10.24v-.389h-10.24v.389ZM74.144 33.513h.698v-1.396h-.698v1.396Z"
      />
      <path
        fill="#C84626"
        d="M89.917 17.024 76.303 26.1l.107.162 13.615-9.076-.108-.162ZM90.633 20.849l-13.615 5.237.07.181 13.614-5.237-.07-.181ZM89.606 23.985l-11.52 2.096.034.192 11.52-2.096-.034-.192ZM74.203 11.48a2.269 2.269 0 1 1 0 4.538"
      />
      <path
        fill="#EA6428"
        d="m79.388 7.856-5.184-5.184-5.184 5.184 5.184 5.183 5.184-5.183Z"
      />
      <path
        fill="#532D5A"
        d="M78.4 7.855 74.204 3.66l-4.197 4.196 4.197 4.197L78.4 7.855Z"
      />
      <path
        fill="#BA3272"
        d="M74.203 10.124a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path fill="#BA3272" d="M74.379 0h-.35v7.68h.35V0Z" />
      <path
        fill="#E9632A"
        d="M69.41 23.931 64.919 12.89h2.842l4.307 10.766a2.308 2.308 0 0 0 2.143 1.45l-.012 1.22h-1.19a3.874 3.874 0 0 1-3.597-2.394Z"
      />
      <path
        fill="#C84626"
        d="m78.954 23.92 4.494-11.041h-2.842l-4.307 10.766a2.31 2.31 0 0 1-2.145 1.45l.012 1.22h1.19a3.872 3.872 0 0 0 3.598-2.395Z"
      />
      <path fill="#F4ACC5" d="M74.03 26.18H69.49v1.397h4.538v-1.396Z" />
      <path
        fill="#2D2044"
        d="M74.145 26.18v4.19a1.744 1.744 0 0 1-1.745-1.745v-.695a1.743 1.743 0 0 1 1.745-1.75Z"
      />
      <path fill="#4F4F8E" d="M74.144 26.879h-1.047v2.793h1.047v-2.793Z" />
      <path fill="#F28DB2" d="M74.261 27.579H78.8v-1.397h-4.538v1.397Z" />
      <path
        fill="#172027"
        d="M74.145 30.37v-4.19a1.744 1.744 0 0 1 1.745 1.745v.696a1.745 1.745 0 0 1-1.745 1.75Z"
      />
      <path fill="#532D5C" d="M74.107 29.672h1.047v-2.793h-1.047v2.793Z" />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace425;
