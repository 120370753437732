import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace613 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#FEC233" d="M150.675 0H0v150.675h150.675V0Z" />
      <path fill="#001A1B" d="M148.47 77.488H0v69.776h148.47V77.488Z" />
      <path fill="#BCAFB1" d="M116.092 95.356H33.956v52.232h82.136V95.356Z" />
      <path fill="#172027" d="M86.574 84.582H61.965v63.006h24.61V84.582Z" />
      <path fill="#DBB9BE" d="M75.088 66.78H23.773v34.435h51.315V66.781Z" />
      <path
        fill="#D7BBBD"
        d="M78.054 100.363h-61.27L40.61 124.19h37.444v-23.827Z"
      />
      <path
        fill="#EFE1DA"
        d="M23.921 66.714h51.06V16.79h-1.376a22.62 22.62 0 0 0-17.898 8.788L23.921 66.714Z"
      />
      <path
        fill="#DBB9BE"
        d="M73.027 21.328h-.63v38.579h.63V21.328ZM54.818 35.902l-.52.356 14.75 21.56.52-.357-14.75-21.559Z"
      />
      <path
        fill="#FBF5DE"
        d="M64.768 34.945a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54ZM60.229 29.272a1.135 1.135 0 1 0 0-2.27 1.135 1.135 0 0 0 0 2.27ZM62.499 38.348a1.135 1.135 0 1 0 0-2.27 1.135 1.135 0 0 0 0 2.27ZM53.421 42.887a1.135 1.135 0 1 0 0-2.27 1.135 1.135 0 0 0 0 2.27Z"
      />
      <path fill="#DBB9BE" d="M74.445 101.086h51.315V66.651H74.445v34.435Z" />
      <path
        fill="#D7BBBD"
        d="M71.479 100.266h61.271l-23.827 23.827H71.479v-23.827Z"
      />
      <path
        fill="#EFE1DA"
        d="M125.613 66.617h-51.06V16.693h1.376a22.62 22.62 0 0 1 17.897 8.788l31.787 41.136Z"
      />
      <path
        fill="#DBB9BE"
        d="M77.138 21.231h-.63V59.81h.63V21.23ZM94.716 35.803l-14.75 21.559.52.356 14.75-21.559-.52-.356Z"
      />
      <path
        fill="#FBF5DE"
        d="M84.765 34.848a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54ZM89.304 29.175a1.135 1.135 0 1 0 0-2.27 1.135 1.135 0 0 0 0 2.27ZM87.034 38.252a1.135 1.135 0 1 0 0-2.27 1.135 1.135 0 0 0 0 2.27ZM96.112 42.79a1.135 1.135 0 1 0 0-2.27 1.135 1.135 0 0 0 0 2.27Z"
      />
      <path
        fill="#883A62"
        d="M53.589 30.247 35.067 17.899l-4.911 6.138a10.91 10.91 0 0 0 2.468 15.895l8.615 5.744"
      />
      <path
        fill="#4D2F47"
        d="M94.728 30.198 113.25 17.85l4.911 6.14a10.9 10.9 0 0 1 .677 12.689 10.913 10.913 0 0 1-3.143 3.205l-8.617 5.745"
      />
      <path
        fill="#542F5F"
        d="M75.429 110.093v-59.26L35.922 90.34h19.753a19.753 19.753 0 0 1 19.754 19.753Z"
      />
      <path
        fill="#F0E49A"
        d="M35.922 90.34h14.815L75.43 65.65V50.834L35.922 90.34Z"
      />
      <path
        fill="#542F5F"
        d="M68.021 68.118a2.47 2.47 0 1 0 0-4.939 2.47 2.47 0 0 0 0 4.94Z"
      />
      <path
        fill="#172027"
        d="M75.429 110.093V50.834l39.506 39.506H95.181a19.753 19.753 0 0 0-19.752 19.753Z"
      />
      <path
        fill="#E9642A"
        d="M114.935 90.34H100.12L75.429 65.65V50.834l39.506 39.506Z"
      />
      <path
        fill="#172027"
        d="M82.836 68.118a2.47 2.47 0 1 0 0-4.939 2.47 2.47 0 0 0 0 4.94Z"
      />
      <path
        stroke="#070707"
        strokeMiterlimit={10}
        strokeWidth={2.707}
        d="M117.328 67.674c0 2.41-.478 4.796-1.407 7.019a18.206 18.206 0 0 1-9.992 9.86 18.19 18.19 0 0 1-7.036 1.314c-9.996-.13-17.953-8.464-17.953-18.463a5.794 5.794 0 0 0-5.793-5.793h-.272M32.42 67.674a18.194 18.194 0 0 0 18.438 18.193c9.996-.13 17.951-8.464 17.951-18.463a5.793 5.793 0 0 1 5.795-5.793h.27"
      />
      <path
        fill="#8E97C4"
        d="M32.42 67.674h36.39a18.195 18.195 0 0 1-36.39 0ZM80.77 67.507h36.389a18.195 18.195 0 0 1-36.388 0Z"
        opacity={0.57}
        style={{
          mixBlendMode: "color-dodge",
        }}
      />
      <path
        fill="#3D2F39"
        d="m102.258 52.51-13.733 4.975a7.528 7.528 0 0 0-4.515 9.641l.312.861a7.528 7.528 0 0 0 9.642 4.515l13.733-4.974a7.528 7.528 0 0 0 4.514-9.642l-.312-.86a7.527 7.527 0 0 0-9.641-4.515Z"
      />
      <path
        fill="#EB6447"
        d="M94.894 70.51a6.844 6.844 0 1 0 0-13.69 6.844 6.844 0 0 0 0 13.69Z"
      />
      <path
        fill="#3D2F39"
        d="m93.01 58.287-.12.044c-.56.202-.848.82-.646 1.378l3.152 8.703c.203.56.82.848 1.378.646l.122-.045c.559-.202.848-.819.645-1.378l-3.152-8.703a1.076 1.076 0 0 0-1.378-.645Z"
      />
      <path
        fill="#80191B"
        d="m40.48 67.746 13.734 4.974a7.528 7.528 0 0 0 9.641-4.515l.312-.86a7.528 7.528 0 0 0-4.514-9.642L45.92 52.729a7.528 7.528 0 0 0-9.642 4.514l-.312.861a7.528 7.528 0 0 0 4.515 9.642Z"
      />
      <path
        fill="#EB6447"
        d="M53.289 70.733a6.844 6.844 0 1 0 0-13.688 6.844 6.844 0 0 0 0 13.688Z"
      />
      <path
        fill="#3D2F39"
        d="m51.28 69.231.122.044a1.076 1.076 0 0 0 1.379-.645l3.152-8.703a1.076 1.076 0 0 0-.645-1.379l-.122-.044a1.076 1.076 0 0 0-1.378.646l-3.152 8.703a1.076 1.076 0 0 0 .645 1.378Z"
      />
      <path
        fill="#F9C1C0"
        d="m68.286 61.72-3.73 15.546h9.945v-17.85M64.557 77.266H74.5v2.304l-9.944-2.304Z"
      />
      <path
        fill="#883A62"
        d="M64.557 77.266c1.617 1.876 4.427 3.48 8.057 4.6l1.887.588V79.57l-9.944-2.304Z"
      />
      <path
        fill="#F489AE"
        d="M80.702 61.691 84.43 77.24h-9.946V59.388M84.43 77.24h-9.945v2.303l9.946-2.303Z"
      />
      <path
        fill="#4D2F47"
        d="M84.428 77.24c-1.617 1.874-4.429 3.478-8.057 4.598l-1.889.588v-2.883l9.946-2.303Z"
      />
      <path
        fill="#AF3271"
        d="m74.145 30.37-.051-5.233a2.308 2.308 0 0 0 2.143-1.451l4.307-10.768h2.75c6.476 0 9.894 7.673 5.56 12.495l-4.47 4.957H74.145Z"
      />
      <path
        fill="#E9632A"
        d="M74.165 20.635a2.27 2.27 0 1 0 0-4.539 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path
        fill="#E9632A"
        d="M74.165 25.174a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path
        fill="#DE2F7A"
        d="M74.378 25.137h-.183a2.307 2.307 0 0 1-2.143-1.451l-4.307-10.77h-2.749c-6.477 0-9.894 7.672-5.56 12.494l4.47 4.967h10.24"
      />
      <path fill="#4F4F8E" d="M74.494 30.37H63.905v4.538h10.589V30.37Z" />
      <path fill="#532D5C" d="M74.144 34.908h10.24V30.37h-10.24v4.538Z" />
      <path
        fill="#EB6329"
        d="M74.203 16.058a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path fill="#C84626" d="M74.203 16.134a2.27 2.27 0 0 1 0 4.54" />
      <path fill="#C84626" d="M74.203 20.596a2.27 2.27 0 1 1 0 4.538" />
      <path
        fill="#E8582C"
        d="M74.145 30.37h-10.24v.698h10.24v-.698ZM74.145 34.56h-10.24v.697h10.24v-.698Z"
      />
      <path
        fill="#F4ACC5"
        d="M74.145 31.03h-10.24v.388h10.24v-.388ZM74.145 34.171h-10.24v.388h10.24v-.388ZM74.145 32.115h-.698v1.396h.698v-1.396Z"
      />
      <path
        fill="#E9632A"
        d="m58.374 17.024-.108.161 13.615 9.077.107-.162-13.614-9.076ZM57.659 20.856l-.07.181 13.614 5.237.07-.181-13.614-5.237ZM58.681 23.987l-.034.192 11.52 2.093.035-.191-11.52-2.094Z"
      />
      <path
        fill="#C9481D"
        d="M74.144 31.068h10.24v-.698h-10.24v.698ZM74.144 35.256h10.24v-.698h-10.24v.698Z"
      />
      <path
        fill="#F28DB2"
        d="M74.144 31.417h10.24v-.389h-10.24v.389ZM74.144 34.56h10.24v-.389h-10.24v.388ZM74.144 33.513h.698v-1.397h-.698v1.397Z"
      />
      <path
        fill="#C84626"
        d="M89.917 17.024 76.303 26.1l.107.162 13.615-9.077-.108-.161ZM90.633 20.849l-13.615 5.237.07.18 13.614-5.236-.07-.181ZM89.606 23.985l-11.52 2.096.034.191 11.52-2.095-.034-.192ZM74.203 11.48a2.27 2.27 0 1 1 0 4.538"
      />
      <path
        fill="#EA6428"
        d="m79.388 7.855-5.184-5.183-5.184 5.183 5.184 5.184 5.184-5.184Z"
      />
      <path
        fill="#532D5A"
        d="M78.4 7.855 74.204 3.66l-4.197 4.196 4.197 4.196L78.4 7.855Z"
      />
      <path
        fill="#BA3272"
        d="M74.203 10.124a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path fill="#BA3272" d="M74.379 0h-.35v7.679h.35v-7.68Z" />
      <path
        fill="#E9632A"
        d="m69.41 23.931-4.492-11.043h2.842l4.307 10.767a2.308 2.308 0 0 0 2.143 1.45l-.012 1.22h-1.19a3.875 3.875 0 0 1-3.597-2.394Z"
      />
      <path
        fill="#C84626"
        d="m78.954 23.92 4.493-11.042h-2.841l-4.307 10.767a2.31 2.31 0 0 1-2.145 1.45l.012 1.219h1.19a3.872 3.872 0 0 0 3.598-2.395Z"
      />
      <path fill="#F4ACC5" d="M74.03 26.18H69.49v1.397h4.538V26.18Z" />
      <path
        fill="#2D2044"
        d="M74.145 26.18v4.19a1.745 1.745 0 0 1-1.745-1.745v-.695a1.745 1.745 0 0 1 1.745-1.75Z"
      />
      <path fill="#4F4F8E" d="M74.144 26.878h-1.047v2.793h1.047v-2.793Z" />
      <path fill="#F28DB2" d="M74.261 27.578H78.8v-1.396h-4.538v1.396Z" />
      <path
        fill="#172027"
        d="M74.145 30.37v-4.19a1.745 1.745 0 0 1 1.745 1.745v.696a1.747 1.747 0 0 1-1.745 1.749Z"
      />
      <path fill="#532D5C" d="M74.107 29.672h1.047v-2.794h-1.047v2.794Z" />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace613;
