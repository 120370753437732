import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace521 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#FEC233" d="M150.675 0H0v150.675h150.675V0Z" />
      <path fill="#A02A15" d="M120.642 69.09H30.156v79.847h90.486V69.09Z" />
      <path
        fill="#DE2F7A"
        d="M120.224 144.462H30.26v4.475h89.964v-4.475ZM120.224 138.869H30.26v4.475h89.964v-4.475ZM120.224 133.274H30.26v4.475h89.964v-4.475ZM120.224 127.681H30.26v4.475h89.964v-4.475ZM120.224 122.088H30.26v4.474h89.964v-4.474ZM120.224 116.494H30.26v4.475h89.964v-4.475ZM120.224 110.901H30.26v4.474h89.964v-4.474ZM120.224 105.306H30.26v4.475h89.964v-4.475ZM120.224 99.713H30.26v4.474h89.964v-4.474ZM120.224 94.12H30.26v4.474h89.964v-4.475ZM120.224 88.526H30.26v4.475h89.964v-4.475ZM120.224 82.932H30.26v4.475h89.964v-4.475Z"
      />
      <path
        fill="#7814BC"
        d="M120.696 74.868H74.794v74.069h45.902V74.868Z"
        opacity={0.34}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path
        fill="#FFA520"
        d="M74.161 124.491H62.497l-48.216-74.41c-5.614-8.66.601-20.093 10.922-20.093h48.958v94.503Z"
      />
      <path
        fill="#F96020"
        d="M74.154 124.509H85.82l48.216-74.412c5.611-8.658-.605-20.091-10.922-20.091H74.154v94.503Z"
      />
      <path
        fill="#F26227"
        d="M20.827 78.892a9.002 9.002 0 1 0 0-18.005 9.002 9.002 0 0 0 0 18.005Z"
      />
      <path
        fill="#D74B27"
        d="M20.827 77.769a7.88 7.88 0 1 0 0-15.759 7.88 7.88 0 0 0 0 15.759Z"
      />
      <path
        fill="#F79421"
        d="M20.48 62.637h.709c.048.007.097.01.147.01.205.018.41.038.614.07.484.075.959.198 1.417.37a7.284 7.284 0 0 1 4.365 4.556 6.971 6.971 0 0 1 .335 1.851.217.217 0 0 0 .01.11v.588a.2.2 0 0 0-.01.11 7.037 7.037 0 0 1-.806 2.937 7.247 7.247 0 0 1-7.037 3.893 7.033 7.033 0 0 1-1.386-.25 7.213 7.213 0 0 1-4.034-2.91 7.215 7.215 0 0 1-1.227-3.47 1.838 1.838 0 0 0-.022-.254v-.696a.083.083 0 0 0 .01-.053c.016-.315.053-.628.112-.938a7.25 7.25 0 0 1 6.637-5.907 1.34 1.34 0 0 0 .166-.017Zm-5.702 7.257a6.051 6.051 0 1 0 6.049-6.058 6.045 6.045 0 0 0-6.049 6.058Z"
      />
      <path
        fill="#F79421"
        d="M22.651 69.68c.257.042.507.12.743.229a1.6 1.6 0 0 1 .716.636c.11.194.18.408.205.63.038.28.026.565-.036.842a1.48 1.48 0 0 1-.924 1.088c-.252.1-.515.17-.783.207-.18.028-.362.048-.546.059-.063 0-.064 0-.064.07v1.078c0 .067 0 .067-.07.067h-.786c-.076 0-.072 0-.072-.07v-1.042c0-.087.008-.078-.08-.078h-.784c-.063 0-.063 0-.063.064v1.05c0 .08.007.076-.074.076h-.782c-.073 0-.075 0-.075-.076v-1.078c0-.025-.009-.04-.035-.036-.027.003-.038 0-.056 0h-1.717c-.03 0-.045 0-.045-.043v-.772c0-.037.014-.047.05-.045h.479c.099-.002.197-.015.294-.038a.26.26 0 0 0 .21-.214 1.61 1.61 0 0 0 .038-.386V67.86a1.69 1.69 0 0 0-.035-.365.277.277 0 0 0-.232-.228 1.879 1.879 0 0 0-.374-.034h-.361c-.064 0-.064 0-.064-.066v-.716c0-.082 0-.076.074-.076h1.702c.077 0 .077 0 .077-.075v-1.07c0-.02.013-.037.038-.035h.814c.082 0 .075-.01.075.073v1.022c0 .095 0 .085.085.085h.78c.063 0 .063 0 .063-.068V65.23c0-.016.01-.038.037-.037h.814c.08 0 .076-.012.076.08v1.062c0 .053 0 .052.055.056.28.019.556.064.827.134.152.04.3.094.441.163a1.25 1.25 0 0 1 .684.823c.09.32.095.659.016.982a1.403 1.403 0 0 1-.566.816c-.221.153-.466.27-.725.344-.019.006-.035.001-.044.026Zm-2.544 1.681v1.126c0 .06 0 .06.06.06h.407a5.9 5.9 0 0 0 .867-.04 2.09 2.09 0 0 0 .497-.127.856.856 0 0 0 .515-.565c.08-.262.092-.541.035-.81a.85.85 0 0 0-.38-.574 1.22 1.22 0 0 0-.365-.147 3.182 3.182 0 0 0-.625-.089c-.323-.019-.648 0-.973-.01-.034 0-.046.013-.043.044.003.031 0 .033 0 .049l.005 1.083Zm0-3.087v1c0 .034.007.051.045.05.35.01.7 0 1.048-.03.171-.019.338-.06.499-.122a.754.754 0 0 0 .467-.54c.054-.213.061-.436.022-.653a.735.735 0 0 0-.517-.62 2.458 2.458 0 0 0-.682-.121c-.274-.013-.547 0-.82 0h-.036a.03.03 0 0 0-.031.02.029.029 0 0 0-.001.014v.055c.003.319.004.635.004.947h.002Z"
      />
      <path
        fill="#F26227"
        d="M131.959 79.33a9.002 9.002 0 0 0 9.002-9.002 9.002 9.002 0 1 0-18.004 0 9.002 9.002 0 0 0 9.002 9.002Z"
      />
      <path
        fill="#D74B27"
        d="M131.959 78.207a7.88 7.88 0 1 0 0-15.759 7.88 7.88 0 0 0 0 15.759Z"
      />
      <path
        fill="#F79421"
        d="M131.612 63.073h.708a.766.766 0 0 0 .147.01c.206.018.411.04.615.071.483.075.957.199 1.414.37a7.284 7.284 0 0 1 4.365 4.558 7.102 7.102 0 0 1 .335 1.85.223.223 0 0 0 .01.112v.588a.203.203 0 0 0-.01.112 7.03 7.03 0 0 1-.806 2.936 7.262 7.262 0 0 1-8.423 3.643 7.223 7.223 0 0 1-4.034-2.91 7.234 7.234 0 0 1-1.227-3.47 1.991 1.991 0 0 0-.022-.255V69.999a.093.093 0 0 0 .01-.055c.016-.314.053-.627.112-.936a7.267 7.267 0 0 1 6.637-5.908 1.08 1.08 0 0 0 .169-.027Zm-5.702 7.258a6.046 6.046 0 0 0 7.234 5.934 6.053 6.053 0 0 0 2.178-10.97 6.054 6.054 0 0 0-8.953 2.72 6.037 6.037 0 0 0-.459 2.316Z"
      />
      <path
        fill="#F79421"
        d="M133.783 70.116c.254.043.501.12.735.23.299.133.548.356.716.637.111.194.181.408.206.63.038.28.025.565-.037.842a1.474 1.474 0 0 1-.923 1.086c-.252.1-.515.17-.784.207-.18.028-.361.049-.545.06-.063 0-.065 0-.065.07v1.077c0 .068 0 .07-.069.07h-.786c-.077 0-.072 0-.072-.073V73.91c0-.087 0-.078-.08-.078h-.785c-.063 0-.063 0-.063.066v1.05c0 .08.008.076-.073.076h-.775c-.074 0-.075 0-.075-.078V73.87c0-.025-.009-.038-.035-.037H128.5c-.031 0-.046-.007-.046-.041v-.772c0-.038.015-.047.05-.047h.48a1.38 1.38 0 0 0 .294-.038.263.263 0 0 0 .139-.073.266.266 0 0 0 .071-.14c.028-.127.041-.256.038-.385v-4.038a1.73 1.73 0 0 0-.035-.366.28.28 0 0 0-.233-.228 1.877 1.877 0 0 0-.373-.034h-.362c-.063 0-.063 0-.063-.066v-.716c0-.081 0-.077.074-.077h1.702c.076 0 .076 0 .076-.073v-1.07c0-.018.014-.038.039-.037h.814c.082 0 .075-.007.075.073v1.022c0 .096-.007.085.085.085h.779c.064 0 .064 0 .064-.066v-1.077c0-.016.01-.038.036-.037h.815c.081 0 .076-.01.076.08v1.065c0 .053 0 .053.055.056.279.02.556.065.827.135.152.04.3.094.441.162a1.243 1.243 0 0 1 .684.823c.09.321.095.66.016.984a1.397 1.397 0 0 1-.566.814c-.221.153-.466.27-.725.344-.019.001-.035-.001-.044.022Zm-2.544 1.682v1.126c0 .06 0 .06.06.06h.407c.29.007.58-.007.867-.043.17-.02.337-.063.497-.125a.858.858 0 0 0 .515-.566c.08-.262.092-.54.035-.81a.862.862 0 0 0-.379-.574 1.23 1.23 0 0 0-.366-.147 3.428 3.428 0 0 0-.625-.088c-.324-.02-.648 0-.973-.01-.034 0-.046.012-.043.045.003.032 0 .032 0 .048.003.362.003.722.005 1.084Zm0-3.087v1.001c0 .032.007.05.045.048.35.01.7 0 1.048-.03.171-.018.338-.06.499-.121a.747.747 0 0 0 .467-.538c.054-.215.061-.438.022-.656a.733.733 0 0 0-.517-.62 2.457 2.457 0 0 0-.682-.12c-.274-.014-.547 0-.821-.007h-.035c-.022 0-.034.01-.032.034v.056l.004.953h.002Z"
      />
      <path
        fill="#542F5F"
        d="M44.979 33.81v9.408a3.335 3.335 0 0 1-3.328 3.323h-3.065a14.36 14.36 0 0 0-5.523 1.104 12.804 12.804 0 0 1-16.484-6.32l-.653-1.36a12.726 12.726 0 0 1 14.773-17.768l13.122 3.5H27.607a5.79 5.79 0 0 0 0 11.58h.713c1.049.001 2.079-.283 2.98-.821l5.99-3.596a6.291 6.291 0 0 1 7.689.947v.003Z"
      />
      <path
        fill="#111C0D"
        d="M106.278 33.833v9.408a3.331 3.331 0 0 0 3.334 3.334h3.059c1.895 0 3.772.375 5.521 1.104a12.806 12.806 0 0 0 16.486-6.313l.65-1.366a12.731 12.731 0 0 0 .417-9.965 12.71 12.71 0 0 0-6.895-7.206 12.726 12.726 0 0 0-8.292-.598l-13.123 3.494h16.214a5.785 5.785 0 0 1 4.095 1.696 5.788 5.788 0 0 1 1.255 6.312 5.785 5.785 0 0 1-5.35 3.574h-.707a5.797 5.797 0 0 1-2.98-.825l-5.993-3.595a6.29 6.29 0 0 0-7.687.946h-.004Z"
      />
      <path
        fill="#542F5F"
        d="M58.239 83.41a16.226 16.226 0 0 0 32.395 0H58.24Z"
      />
      <path
        fill="#001A1B"
        d="M62.263 86.077a12.243 12.243 0 0 0 23.977 0H62.263Z"
      />
      <path
        stroke="#F96020"
        strokeMiterlimit={10}
        strokeWidth={3.61}
        d="M58.237 67.129h30.48"
      />
      <path
        fill="#F96020"
        d="M53.858 83.79c5.536 0 10.024-4.488 10.024-10.024 0-5.536-4.488-10.024-10.024-10.024-5.536 0-10.024 4.488-10.024 10.024 0 5.536 4.488 10.024 10.024 10.024Z"
      />
      <path
        fill="#D74816"
        d="M53.856 82.539a8.773 8.773 0 1 0 0-17.546 8.773 8.773 0 0 0 0 17.546Z"
      />
      <path
        fill="#F96020"
        d="M95.628 84.062c5.536 0 10.024-4.488 10.024-10.024 0-5.536-4.488-10.024-10.024-10.024-5.536 0-10.024 4.488-10.024 10.024 0 5.536 4.488 10.024 10.024 10.024Z"
      />
      <path
        fill="#D74816"
        d="M95.628 82.81a8.773 8.773 0 1 0 0-17.546 8.773 8.773 0 0 0 0 17.547Z"
      />
      <path
        fill="#FFA520"
        d="m45.864 77.06 4.059 4.06 11.062-11.062-4.06-4.06-11.061 11.063ZM88.548 77.746l4.06 4.059 11.061-11.062-4.06-4.06-11.06 11.063Z"
      />
      <path
        fill="#3D2F39"
        d="m102.257 52.51-13.732 4.975a7.528 7.528 0 0 0-4.515 9.641l.312.861a7.528 7.528 0 0 0 9.642 4.515l13.732-4.974a7.527 7.527 0 0 0 4.515-9.642l-.312-.86a7.528 7.528 0 0 0-9.642-4.515Z"
      />
      <path
        fill="#EB6447"
        d="M94.894 70.51a6.845 6.845 0 1 0 0-13.69 6.845 6.845 0 0 0 0 13.69Z"
      />
      <path
        fill="#3D2F39"
        d="m93.01 58.287-.12.044c-.56.202-.848.819-.646 1.378l3.152 8.703c.203.559.82.848 1.378.645l.122-.044c.559-.202.848-.82.645-1.378l-3.152-8.703a1.076 1.076 0 0 0-1.378-.645Z"
      />
      <path
        fill="#80191B"
        d="m40.48 67.746 13.733 4.974a7.528 7.528 0 0 0 9.642-4.515l.312-.861a7.528 7.528 0 0 0-4.515-9.641L45.92 52.728a7.528 7.528 0 0 0-9.641 4.514l-.312.861a7.528 7.528 0 0 0 4.514 9.642Z"
      />
      <path
        fill="#EB6447"
        d="M53.289 70.733a6.844 6.844 0 1 0 0-13.689 6.844 6.844 0 0 0 0 13.69Z"
      />
      <path
        fill="#3D2F39"
        d="m51.28 69.23.123.045a1.076 1.076 0 0 0 1.378-.645l3.152-8.704a1.076 1.076 0 0 0-.645-1.378l-.122-.044a1.076 1.076 0 0 0-1.378.645l-3.152 8.704a1.076 1.076 0 0 0 .645 1.378Z"
      />
      <path
        fill="#4D2F47"
        d="M79.998 79.674a5.5 5.5 0 0 1-5.501-5.5v-9.85h6.133a5.803 5.803 0 0 1 5.652 7.125l-.928 3.97a5.502 5.502 0 0 1-5.356 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M79.925 77.88a3.637 3.637 0 1 0 0-7.273 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M79.876 76.984a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path
        fill="#883A62"
        d="M69.015 79.63a5.5 5.5 0 0 0 5.5-5.501V64.28h-6.133a5.804 5.804 0 0 0-5.653 7.125l.93 3.97a5.499 5.499 0 0 0 5.356 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M69.084 77.837a3.637 3.637 0 1 0 0-7.274 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M69.134 76.94a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path fill="#542F5F" d="M74.382 12.936H53.214V32.78h21.168V12.936Z" />
      <path fill="#172027" d="M95.55 12.936H74.382V32.78H95.55V12.936Z" />
      <path fill="#172027" d="M112.749 31.458H37.338v2.646h75.411v-2.646Z" />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace521;
