import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace443 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#3A6A7E" d="M150.675 0H0v150.675h150.675V0Z" />
      <path
        fill="#6C1908"
        d="M74.088 72.65a60.662 60.662 0 0 1 60.654 60.661v14.13H13.421v-14.13a60.663 60.663 0 0 1 60.667-60.66Z"
      />
      <path
        fill="#727FBD"
        d="M74.842 22.594c27.565 0 51.234 22.344 51.234 49.91v34.786H74.239"
      />
      <path
        fill="#AAAECC"
        d="M76.813 22.557H75.49c-27.564 0-49.908 22.346-49.908 49.914v34.818h50.245"
      />
      <path
        fill="#727FBD"
        d="M75.827 126.01h14.582a43.328 43.328 0 0 0 35.663-18.721H75.827v18.721Z"
      />
      <path
        fill="#AAAECC"
        d="M75.827 126.01H61.242a43.332 43.332 0 0 1-35.66-18.721h50.245v18.721Z"
      />
      <path
        fill="#F26227"
        d="M20.827 78.892a9.002 9.002 0 1 0 0-18.005 9.002 9.002 0 0 0 0 18.005Z"
      />
      <path
        fill="#D74B27"
        d="M20.827 77.769a7.88 7.88 0 1 0 0-15.759 7.88 7.88 0 0 0 0 15.759Z"
      />
      <path
        fill="#F79421"
        d="M20.48 62.637h.709c.048.007.097.01.147.01.205.018.41.038.614.07.484.075.959.198 1.417.37a7.284 7.284 0 0 1 4.365 4.557 6.973 6.973 0 0 1 .335 1.85.217.217 0 0 0 .01.11v.589a.2.2 0 0 0-.01.11 7.036 7.036 0 0 1-.806 2.937 7.248 7.248 0 0 1-8.423 3.643 7.214 7.214 0 0 1-4.034-2.911 7.216 7.216 0 0 1-1.227-3.47 1.838 1.838 0 0 0-.022-.254v-.696a.084.084 0 0 0 .01-.053c.016-.315.053-.628.112-.938a7.25 7.25 0 0 1 6.637-5.907 1.34 1.34 0 0 0 .166-.017Zm-5.702 7.257a6.051 6.051 0 1 0 6.049-6.058 6.045 6.045 0 0 0-6.049 6.058Z"
      />
      <path
        fill="#F79421"
        d="M22.651 69.68c.257.042.507.12.743.229a1.6 1.6 0 0 1 .716.636c.11.194.18.408.205.63.038.28.026.566-.036.842a1.48 1.48 0 0 1-.924 1.088c-.252.1-.515.17-.783.207a5.6 5.6 0 0 1-.546.059c-.063 0-.064 0-.064.07v1.078c0 .067 0 .067-.07.067h-.786c-.076 0-.072 0-.072-.07v-1.042c0-.087.008-.078-.08-.078h-.784c-.063 0-.063 0-.063.064v1.05c0 .08.007.076-.074.076h-.782c-.073 0-.075 0-.075-.076v-1.078c0-.025-.009-.04-.035-.036-.027.003-.038 0-.056 0h-1.717c-.03 0-.045 0-.045-.043v-.772c0-.036.014-.047.05-.045h.479c.099-.002.197-.015.294-.038a.26.26 0 0 0 .21-.214 1.61 1.61 0 0 0 .038-.386V67.86a1.692 1.692 0 0 0-.035-.365.277.277 0 0 0-.232-.228 1.879 1.879 0 0 0-.374-.033h-.361c-.064 0-.064 0-.064-.067v-.716c0-.082 0-.076.074-.076h1.702c.077 0 .077 0 .077-.075v-1.07c0-.02.013-.037.038-.035h.814c.082 0 .075-.01.075.073v1.022c0 .095 0 .085.085.085h.78c.063 0 .063 0 .063-.068V65.23c0-.016.01-.038.037-.037h.814c.08 0 .076-.012.076.08v1.062c0 .053 0 .052.055.056a4.5 4.5 0 0 1 .827.134c.152.04.3.095.441.163a1.25 1.25 0 0 1 .684.823c.09.32.095.659.016.982a1.402 1.402 0 0 1-.566.816c-.221.153-.466.27-.725.344-.019.006-.035.002-.044.027Zm-2.544 1.681v1.126c0 .06 0 .06.06.06h.407a5.9 5.9 0 0 0 .867-.04c.17-.022.338-.065.497-.127a.859.859 0 0 0 .515-.564c.08-.263.092-.542.035-.81a.85.85 0 0 0-.38-.575 1.222 1.222 0 0 0-.365-.147 3.19 3.19 0 0 0-.625-.089c-.323-.019-.648 0-.973-.01-.034 0-.046.013-.043.044.003.031 0 .033 0 .049l.005 1.083Zm0-3.087v1c0 .034.007.051.045.05.35.01.7 0 1.048-.03.171-.019.338-.06.499-.122a.755.755 0 0 0 .467-.54c.054-.213.061-.436.022-.653a.735.735 0 0 0-.517-.62 2.454 2.454 0 0 0-.682-.121c-.274-.013-.547 0-.82 0h-.036a.03.03 0 0 0-.024.009.03.03 0 0 0-.008.025v.056c.003.318.004.634.004.946h.002Z"
      />
      <path
        fill="#F26227"
        d="M131.959 79.33a9.002 9.002 0 0 0 9.002-9.002 9.002 9.002 0 0 0-9.002-9.002 9.002 9.002 0 0 0-9.002 9.002 9.002 9.002 0 0 0 9.002 9.002Z"
      />
      <path
        fill="#D74B27"
        d="M131.959 78.207a7.88 7.88 0 1 0 0-15.759 7.88 7.88 0 0 0 0 15.759Z"
      />
      <path
        fill="#F79421"
        d="M131.612 63.074h.708a.774.774 0 0 0 .147.01 7.118 7.118 0 0 1 2.029.441 7.282 7.282 0 0 1 4.365 4.557 7.102 7.102 0 0 1 .335 1.85.223.223 0 0 0 .01.112v.588a.203.203 0 0 0-.01.112 7.028 7.028 0 0 1-.806 2.936 7.261 7.261 0 0 1-8.423 3.644 7.223 7.223 0 0 1-4.034-2.91 7.234 7.234 0 0 1-1.227-3.47 1.99 1.99 0 0 0-.022-.256V70a.093.093 0 0 0 .01-.055c.016-.314.053-.627.112-.936a7.267 7.267 0 0 1 6.637-5.908c.057-.004.113-.013.169-.026Zm-5.702 7.257a6.046 6.046 0 0 0 7.234 5.934 6.053 6.053 0 0 0 2.178-10.97 6.053 6.053 0 0 0-9.412 5.036Z"
      />
      <path
        fill="#F79421"
        d="M133.783 70.116c.254.043.502.12.735.23.299.134.549.356.716.638.111.193.181.407.206.629.038.28.025.566-.037.842a1.463 1.463 0 0 1-.923 1.086c-.252.1-.515.17-.784.208-.18.028-.361.048-.545.06-.063 0-.065 0-.065.069v1.077c0 .068 0 .07-.069.07h-.786c-.077 0-.072 0-.072-.073V73.91c0-.087 0-.078-.08-.078h-.784c-.064 0-.064 0-.064.067v1.05c0 .079.008.076-.073.076h-.775c-.073 0-.075 0-.075-.078v-1.078c0-.025-.009-.038-.035-.037H128.5c-.031 0-.046-.007-.046-.04v-.773c0-.038.015-.047.05-.047h.48a1.38 1.38 0 0 0 .294-.038.263.263 0 0 0 .139-.073.266.266 0 0 0 .071-.14c.028-.126.041-.256.038-.385v-4.038a1.666 1.666 0 0 0-.035-.366.277.277 0 0 0-.232-.228 1.887 1.887 0 0 0-.374-.034h-.361c-.064 0-.064 0-.064-.066v-.716c0-.08 0-.076.074-.076h1.702c.077 0 .077 0 .077-.074v-1.07c0-.018.013-.038.038-.037h.814c.082 0 .075-.007.075.074v1.021c0 .096-.007.086.085.086h.779c.064 0 .064 0 .064-.066v-1.078c0-.016.01-.038.036-.037h.815c.081 0 .076-.01.076.081v1.064c0 .053 0 .053.055.056.279.02.556.065.827.135.152.04.3.094.441.162a1.258 1.258 0 0 1 .684.823c.09.321.095.66.016.984a1.397 1.397 0 0 1-.566.814c-.221.154-.466.27-.725.344-.019.002-.035-.001-.044.022Zm-2.544 1.682v1.126c0 .06 0 .06.06.06h.407c.29.007.58-.007.867-.042a2.08 2.08 0 0 0 .497-.125.857.857 0 0 0 .515-.566c.08-.263.092-.542.035-.81a.846.846 0 0 0-.379-.575 1.22 1.22 0 0 0-.366-.147 3.416 3.416 0 0 0-.625-.088c-.323-.02-.648 0-.973-.01-.034 0-.046.013-.043.045.003.032 0 .032 0 .049.003.361.003.721.005 1.083Zm0-3.087v1.001c0 .032.007.05.045.048.35.01.7 0 1.048-.029.171-.02.338-.06.499-.122a.747.747 0 0 0 .467-.538c.054-.215.061-.438.022-.656a.733.733 0 0 0-.517-.62 2.46 2.46 0 0 0-.682-.119c-.274-.015-.547 0-.821-.007h-.035c-.022 0-.034.01-.032.033v.056l.004.953h.002Z"
      />
      <path
        fill="#883A62"
        d="M19.657 66.937a9.1 9.1 0 1 0 0-18.202 9.1 9.1 0 0 0 0 18.202Z"
      />
      <path
        fill="#883A62"
        d="M22.69 75.026a6.067 6.067 0 1 0 0-12.134 6.067 6.067 0 0 0 0 12.134Z"
      />
      <path
        fill="#542F5F"
        d="M26.735 68.96a6.067 6.067 0 1 0 0-12.134 6.067 6.067 0 0 0 0 12.133Z"
      />
      <g
        stroke="#070707"
        strokeMiterlimit={10}
        strokeWidth={0.902}
        opacity={0.57}
        style={{
          mixBlendMode: "overlay",
        }}
      >
        <path d="m18.534 52.894 10.112 10.11M26.735 64.19l-5.197 5.198M22.838 57.696l-3.897 3.897M24.137 58.994l-5.196 5.196" />
      </g>
      <path
        fill="#F96020"
        d="M17.618 86.324a7.819 7.819 0 1 0 0-15.637 7.819 7.819 0 0 0 0 15.637Z"
      />
      <path
        fill="#D74816"
        d="M17.616 85.348a6.843 6.843 0 1 0 0-13.686 6.843 6.843 0 0 0 0 13.686Z"
      />
      <path
        fill="#883A62"
        d="M130.845 66.85a9.1 9.1 0 0 0 9.1-9.101 9.1 9.1 0 1 0-18.201 0 9.1 9.1 0 0 0 9.101 9.1Z"
      />
      <path
        fill="#883A62"
        d="M127.812 74.938a6.067 6.067 0 1 0 0-12.134 6.067 6.067 0 0 0 0 12.133Z"
      />
      <path
        fill="#542F5F"
        d="M123.767 68.87a6.066 6.066 0 1 0 0-12.132 6.066 6.066 0 0 0 0 12.133Z"
      />
      <g
        stroke="#070707"
        strokeMiterlimit={10}
        strokeWidth={0.902}
        opacity={0.57}
        style={{
          mixBlendMode: "overlay",
        }}
      >
        <path d="m131.969 52.805-10.112 10.112M123.766 64.103l5.198 5.197M127.665 57.608l3.897 3.898M126.365 58.907l5.197 5.197" />
      </g>
      <path
        fill="#F96020"
        d="M132.885 86.238a7.82 7.82 0 1 0 0-15.639 7.82 7.82 0 0 0 0 15.639Z"
      />
      <path
        fill="#D74816"
        d="M132.887 85.261a6.842 6.842 0 0 0 6.842-6.843 6.842 6.842 0 1 0-13.685 0 6.842 6.842 0 0 0 6.843 6.843Z"
      />
      <path
        fill="#883A62"
        d="M74.213 80.151H34.848c-3.79 0-5.936 4.344-3.634 7.35l5.267 6.887a9.492 9.492 0 0 0 7.543 3.728h30.19"
      />
      <path
        fill="#883A62"
        d="M71.042 80.151h42.983c3.79 0 5.936 4.344 3.634 7.35l-5.267 6.887a9.486 9.486 0 0 1-7.54 3.733h-33.81"
      />
      <path fill="#542F5F" d="M109.095 83.841H39.33v9.514h69.765v-9.514Z" />
      <path fill="#172027" d="M110.741 81.827H74.367v5.457h36.374v-5.457Z" />
      <path
        fill="#F489AE"
        d="M104.78 81.777a2.268 2.268 0 0 1 1.608.666 2.272 2.272 0 0 1 .666 1.608v3.183h-4.546V84.05a2.274 2.274 0 0 1 2.272-2.274Z"
      />
      <path fill="#172027" d="M104.326 91.78H74.317v3.637h30.009v-3.636Z" />
      <path
        fill="#F489AE"
        d="M100.689 91.78h-4.546v3.637h4.546v-3.636ZM101.649 87.334v8.135h5.457a7.72 7.72 0 0 0-2.808-5.946l-2.649-2.189ZM108.924 84.555v9.094l1.963-1.636a9.71 9.71 0 0 0 3.494-7.458 2.731 2.731 0 0 0-2.729-2.728 2.73 2.73 0 0 0-2.728 2.728Z"
      />
      <path fill="#172027" d="M37.944 87.234h36.373v-5.457H37.944v5.457Z" />
      <path
        fill="#F9C1C0"
        d="M46.177 87.178H41.63v-3.182a2.276 2.276 0 0 1 3.883-1.608c.426.426.666 1.004.666 1.608v3.182h-.002Z"
      />
      <path fill="#172027" d="M44.359 95.367h30.008v-3.636H44.36v3.636Z" />
      <path
        fill="#F9C1C0"
        d="M47.997 95.367h4.547v-3.636h-4.547v3.636ZM47.037 87.284v8.134h-5.456a7.712 7.712 0 0 1 2.807-5.951l2.65-2.183ZM39.762 84.505V93.6L37.8 91.963a9.703 9.703 0 0 1-3.495-7.457 2.729 2.729 0 0 1 5.457 0ZM53.048 87.178h-4.547v-3.182a2.274 2.274 0 1 1 4.549 0v3.182h-.002ZM54.868 95.367h4.547v-3.636h-4.547v3.636ZM59.732 87.178h-4.547v-3.182a2.274 2.274 0 1 1 4.548 0v3.182h-.001ZM61.552 95.367h4.547v-3.636h-4.547v3.636ZM66.79 87.178h-4.547v-3.182a2.274 2.274 0 1 1 4.548 0v3.182h-.001ZM68.608 95.367h4.547v-3.636h-4.547v3.636ZM74.212 87.178h-4.547v-3.182a2.274 2.274 0 1 1 4.548 0v3.182h-.001Z"
      />
      <path
        fill="#F489AE"
        d="M75.479 95.469h4.546v-3.637H75.48v3.637ZM82.35 95.469h4.546v-3.637H82.35v3.637ZM89.034 95.469h4.546v-3.637h-4.546v3.637ZM96.091 95.469h4.547v-3.637h-4.547v3.637ZM79.474 87.178h-4.547v-3.182a2.274 2.274 0 1 1 4.549 0v3.182h-.002ZM86.16 87.178h-4.547v-3.182a2.274 2.274 0 1 1 4.548 0v3.182h-.001ZM93.216 87.178h-4.547v-3.182a2.274 2.274 0 1 1 4.548 0v3.182h-.001Z"
      />
      <path fill="#E1D4D1" d="M59.79 78.123 48.504 66.835H59.69" />
      <path fill="#AEA4B2" d="m89.59 78.123 11.289-11.288H89.532" />
      <path fill="#959ACC" d="M113.787 60.711a12.53 12.53 0 1 0-25.06 0" />
      <path
        fill="#727FBD"
        d="M98.249 70.241a9.524 9.524 0 0 0 9.524-9.524 9.524 9.524 0 1 0-19.048 0 9.524 9.524 0 0 0 9.524 9.524Z"
      />
      <path
        fill="#213F7D"
        d="M98.089 64.517a3.8 3.8 0 1 0 0-7.601 3.8 3.8 0 0 0 0 7.6Z"
      />
      <path fill="#213F7D" d="m95.375 58.003-5.428 4.343h5.428" />
      <path
        fill="#131640"
        d="M98.089 63.432a2.715 2.715 0 1 0 0-5.43 2.715 2.715 0 0 0 0 5.43Z"
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M85.5 63.07v-8.872a15.187 15.187 0 0 1 15.186-15.187h2.859"
        opacity={0.49}
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path fill="#D0BAC3" d="M35.595 60.711a12.532 12.532 0 0 1 25.062 0" />
      <path
        fill="#AAAECC"
        d="M51.132 70.241a9.524 9.524 0 1 0 0-19.048 9.524 9.524 0 0 0 0 19.048Z"
      />
      <path fill="#727FBD" d="m53.785 58.003 5.428 4.343h-5.428" />
      <path
        fill="#727FBD"
        d="M51.07 64.517a3.8 3.8 0 1 0 0-7.6 3.8 3.8 0 0 0 0 7.6Z"
      />
      <path
        fill="#213F7D"
        d="M51.07 63.432a2.715 2.715 0 1 0 0-5.43 2.715 2.715 0 0 0 0 5.43Z"
      />
      <path
        fill="#EFF3F9"
        d="M49.97 60.82a.645.645 0 1 0 0-1.29.645.645 0 0 0 0 1.29Z"
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M63.445 62.891v-8.873a15.185 15.185 0 0 0-15.179-15.187h-2.865"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M37.35 60.664a11.592 11.592 0 1 1 23.183 0"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M39.578 60.774a10.391 10.391 0 1 1 20.782 0"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M43.643 60.733a8.397 8.397 0 0 1 16.793 0"
        opacity={0.58}
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M111.877 60.81a11.592 11.592 0 1 0-23.184 0"
        opacity={0.49}
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M109.647 60.92a10.39 10.39 0 0 0-17.694-7.268 10.391 10.391 0 0 0-3.087 7.268"
        opacity={0.49}
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M105.583 60.88a8.396 8.396 0 0 0-16.794 0M84.163 62.916v-9.505a16.269 16.269 0 0 1 16.269-16.267h3.063"
        opacity={0.49}
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M81.216 62.999V52.065a18.711 18.711 0 0 1 18.71-18.71h3.528"
        opacity={0.49}
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M64.789 62.834v-9.507A16.266 16.266 0 0 0 48.522 37.06h-3.064"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        stroke="#E9DEDB"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={0.301}
        d="M67.738 62.916V51.981a18.712 18.712 0 0 0-18.71-18.71h-3.529"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        fill="#EFF3F9"
        d="M96.932 60.81a.645.645 0 1 0 0-1.291.645.645 0 0 0 0 1.29Z"
      />
      <path
        fill="#4D2F47"
        d="M79.998 79.674a5.5 5.5 0 0 1-5.501-5.5v-9.85h6.133a5.803 5.803 0 0 1 5.652 7.125l-.928 3.97a5.502 5.502 0 0 1-5.356 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M79.925 77.88a3.637 3.637 0 1 0 0-7.273 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M79.876 76.984a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path
        fill="#883A62"
        d="M69.015 79.63a5.5 5.5 0 0 0 5.5-5.501V64.28h-6.133a5.803 5.803 0 0 0-5.653 7.125l.93 3.97a5.5 5.5 0 0 0 5.356 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M69.084 77.837a3.637 3.637 0 1 0 0-7.274 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M69.134 76.94a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path
        fill="#DF4624"
        d="m97.632 35.391 10.961-13.806v4.313c0 3.056-2.425 5.91-6.468 7.604l-4.493 1.89Z"
      />
      <path
        fill="#F96020"
        d="M53.186 35.227 42.23 21.422v4.313c0 3.055 2.424 5.908 6.468 7.603l4.488 1.889Z"
      />
      <path
        fill="#BA3272"
        d="M71.658 28.25V9.849l-1.294.814c-7.357 4.633-7.357 12.145 0 16.779l1.294.808Z"
      />
      <path
        fill="#8E97C4"
        d="M71.658 28.25 57.044 16.746v6.903l14.614 11.504"
      />
      <path
        fill="#BA3272"
        d="M57.043 23.65V2.94l-4.386 5.524c-4.323 5.446-6.574 11.451-6.574 17.54v6.849h23.426"
      />
      <path
        fill="#532D5A"
        d="M79.16 28.415v-18.41l1.29.817c7.35 4.632 7.35 12.145 0 16.777l-1.29.816Z"
      />
      <path fill="#4F4F8E" d="m79.16 28.415 14.614-11.506v6.903L79.16 35.316" />
      <path
        fill="#532D5A"
        d="M93.776 23.814V3.104L98.16 8.63c4.324 5.439 6.575 11.451 6.575 17.54v6.847H81.534"
      />
      <path
        fill="#F96020"
        d="m71.658 28.25-10.96-13.806v4.314c0 3.055 2.424 5.908 6.46 7.603l4.5 1.89Z"
      />
      <path
        fill="#DF4624"
        d="M79.16 28.415 90.12 14.609v4.313c0 3.056-2.424 5.909-6.468 7.602l-4.494 1.89Z"
      />
      <path
        fill="#F96020"
        d="M75.507 35.997a8.03 8.03 0 1 0 0-16.061 8.03 8.03 0 0 0 0 16.061Z"
      />
      <path
        fill="#D74816"
        d="M75.505 34.996a7.03 7.03 0 1 0 0-14.059 7.03 7.03 0 0 0 0 14.06Z"
      />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace443;
