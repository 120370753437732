import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace428 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#A9D4EB" d="M150.675 0H0v150.675h150.675V0Z" />
      <path fill="#BA3D11" d="M124.803 66.541H23.698v81.167h101.105V66.541Z" />
      <path fill="#542F5F" d="M101.677 66.319H45.483v81.39h56.194v-81.39Z" />
      <path fill="#172027" d="M102.165 66.319H73.553v81.39h28.612v-81.39Z" />
      <path
        fill="#DBB9BE"
        d="M74.125 20.595V125.85L20.196 92.805V45.073l53.929-24.478Z"
      />
      <path
        fill="#BCAFB1"
        d="M74.192 20.58v105.256l53.929-33.045V45.058L74.192 20.58Z"
      />
      <path
        fill="#F96020"
        d="M75.928 147.658a9.116 9.116 0 0 0 9.118-9.116 9.116 9.116 0 0 0-9.118-9.117 9.116 9.116 0 0 0-9.116 9.117 9.116 9.116 0 0 0 9.117 9.116Z"
      />
      <path
        fill="#D74816"
        d="M75.929 146.521a7.98 7.98 0 1 0-.002-15.96 7.98 7.98 0 0 0 .002 15.96Z"
      />
      <path
        fill="#FFA520"
        d="m68.657 141.538 3.692 3.692 10.06-10.061-3.691-3.692-10.061 10.061Z"
      />
      <path
        fill="#F96020"
        d="M75.11 148.239a9.755 9.755 0 0 0 9.755-9.755c0-5.387-4.368-9.755-9.755-9.755-5.388 0-9.755 4.368-9.755 9.755 0 5.388 4.367 9.755 9.755 9.755Z"
      />
      <path
        fill="#D74816"
        d="M75.108 147.022a8.538 8.538 0 1 0 0-17.076 8.538 8.538 0 0 0 0 17.076Z"
      />
      <path
        fill="#FF9317"
        d="M74.733 130.624h.77a.77.77 0 0 0 .165.012c.222.019.441.041.665.076.523.08 1.037.214 1.533.4a7.898 7.898 0 0 1 4.729 4.932 7.69 7.69 0 0 1 .37 2.009.24.24 0 0 0 .012.121v.633a.225.225 0 0 0-.012.121 7.651 7.651 0 0 1-.869 3.183 7.876 7.876 0 0 1-9.129 3.946 7.806 7.806 0 0 1-4.37-3.154 7.79 7.79 0 0 1-1.33-3.759 2.498 2.498 0 0 0-.023-.276v-.754a.116.116 0 0 0 .01-.059 7.808 7.808 0 0 1 .772-2.995 7.897 7.897 0 0 1 5.167-4.204 7.97 7.97 0 0 1 1.373-.217c.056-.001.112-.006.167-.015Zm-6.174 7.864a6.56 6.56 0 0 0 7.84 6.43 6.556 6.556 0 0 0-1.284-12.989 6.568 6.568 0 0 0-6.061 4.049 6.553 6.553 0 0 0-.5 2.51h.005Z"
      />
      <path
        fill="#FF9317"
        d="M77.085 138.255c.279.046.55.13.806.248.322.146.593.387.775.691.12.21.196.442.223.682.04.304.027.613-.04.913a1.604 1.604 0 0 1-1 1.176 3.717 3.717 0 0 1-.847.225 7.044 7.044 0 0 1-.588.065c-.07 0-.07 0-.07.075v1.167c0 .073 0 .075-.075.075h-.854c-.082 0-.078 0-.078-.078v-1.129c0-.094.008-.084-.085-.084H74.4c-.068 0-.068 0-.07.071v1.136c0 .087 0 .084-.079.084h-.844c-.08 0-.08 0-.08-.084v-1.167c0-.021-.01-.043-.039-.04h-1.925c-.036 0-.05-.009-.05-.046v-.836c0-.04.016-.051.053-.051h.52c.106-.003.21-.016.313-.04a.281.281 0 0 0 .228-.231c.031-.137.045-.278.043-.419V136.282a1.99 1.99 0 0 0-.04-.395.293.293 0 0 0-.251-.247 1.959 1.959 0 0 0-.405-.036h-.39c-.07 0-.07 0-.07-.072v-.774c0-.089-.009-.083.08-.084h1.844c.084 0 .084 0 .086-.08v-1.159c0-.03.013-.042.04-.039h.883c.09 0 .082-.008.082.08v1.107c0 .103-.007.091.094.091h.846c.067 0 .067 0 .069-.072v-1.167c0-.03.01-.042.04-.039h.881c.087 0 .084-.013.084.086v1.152c0 .057 0 .057.057.062.304.02.604.069.898.147.163.043.32.102.47.176a1.353 1.353 0 0 1 .736.891c.097.347.104.714.018 1.064-.09.359-.308.673-.613.882-.24.166-.505.292-.785.372-.01.005-.028.002-.039.027Zm-2.757 1.822v1.221c0 .064 0 .066.064.066h.441c.314.007.628-.008.94-.046.184-.023.365-.068.538-.137a.919.919 0 0 0 .558-.611c.088-.286.1-.59.038-.882a.925.925 0 0 0-.411-.623 1.332 1.332 0 0 0-.39-.15 3.524 3.524 0 0 0-.677-.096c-.352-.02-.703 0-1.054-.012-.038 0-.05.015-.047.049.003.034 0 .035 0 .053v1.168Zm0-3.345v1.085c0 .036 0 .054.05.054.378.01.757-.001 1.135-.034.184-.02.366-.064.539-.131a.811.811 0 0 0 .507-.588c.059-.229.07-.468.032-.702a.805.805 0 0 0-.563-.674 2.7 2.7 0 0 0-.735-.129c-.294-.015-.588 0-.882 0h-.03c-.025 0-.037.012-.036.037.002.025 0 .04 0 .06l-.017 1.022ZM67.59 131.81a2.053 2.053 0 1 0 .001-4.106 2.053 2.053 0 0 0 0 4.106Z"
      />
      <path
        fill="#FF9317"
        d="M64.51 128.729a2.053 2.053 0 1 0 0-4.106 2.053 2.053 0 0 0 0 4.106Z"
      />
      <path
        fill="#FF9317"
        d="M61.43 125.649a2.053 2.053 0 1 0 0-4.106 2.053 2.053 0 0 0 0 4.106Z"
      />
      <path
        fill="#FF9317"
        d="M58.349 122.568a2.053 2.053 0 1 0 0-4.106 2.053 2.053 0 0 0 0 4.106Z"
      />
      <path
        fill="#F96020"
        d="M82.992 131.81a2.053 2.053 0 1 0 0-4.106 2.053 2.053 0 0 0 0 4.106ZM86.073 128.729a2.053 2.053 0 1 0 0-4.106 2.053 2.053 0 0 0 0 4.106ZM89.153 125.649a2.053 2.053 0 1 0 0-4.106 2.053 2.053 0 0 0 0 4.106ZM92.234 122.568a2.053 2.053 0 1 0 0-4.106 2.053 2.053 0 0 0 0 4.106Z"
      />
      <path
        fill="#F26227"
        d="M20.827 78.892a9.002 9.002 0 1 0 0-18.005 9.002 9.002 0 0 0 0 18.005Z"
      />
      <path
        fill="#D74B27"
        d="M20.827 77.769a7.88 7.88 0 1 0 0-15.759 7.88 7.88 0 0 0 0 15.759Z"
      />
      <path
        fill="#F79421"
        d="M20.48 62.637h.709c.048.007.097.01.147.01.205.018.41.038.614.07.484.075.959.198 1.417.37a7.284 7.284 0 0 1 4.365 4.557 6.973 6.973 0 0 1 .335 1.85.217.217 0 0 0 .01.11v.589a.2.2 0 0 0-.01.11 7.036 7.036 0 0 1-.806 2.937 7.248 7.248 0 0 1-8.423 3.643 7.214 7.214 0 0 1-4.034-2.911 7.216 7.216 0 0 1-1.227-3.47 1.838 1.838 0 0 0-.022-.254v-.696a.084.084 0 0 0 .01-.053c.016-.315.053-.628.112-.938a7.25 7.25 0 0 1 6.637-5.907 1.34 1.34 0 0 0 .166-.017Zm-5.702 7.257a6.051 6.051 0 1 0 6.049-6.058 6.045 6.045 0 0 0-6.049 6.058Z"
      />
      <path
        fill="#F79421"
        d="M22.651 69.68c.257.042.507.12.743.229a1.6 1.6 0 0 1 .716.636c.11.194.18.408.206.63.037.28.025.566-.037.842a1.479 1.479 0 0 1-.923 1.088c-.253.1-.516.17-.784.207-.18.028-.362.048-.545.059-.064 0-.065 0-.065.07v1.078c0 .067 0 .067-.07.067h-.785c-.077 0-.073 0-.073-.07v-1.042c0-.087.008-.078-.079-.078h-.785c-.063 0-.063 0-.063.064v1.05c0 .08.007.076-.074.076h-.782c-.073 0-.075 0-.075-.076v-1.078c0-.025-.008-.04-.035-.036-.026.003-.038 0-.056 0h-1.717c-.03 0-.045 0-.045-.043v-.772c0-.036.014-.047.05-.045h.479c.099-.002.198-.015.294-.038a.26.26 0 0 0 .21-.214c.028-.127.041-.256.038-.386V67.86a1.692 1.692 0 0 0-.035-.365.277.277 0 0 0-.232-.228 1.879 1.879 0 0 0-.374-.033h-.361c-.063 0-.063 0-.063-.067v-.716c0-.082 0-.076.073-.076h1.702c.077 0 .077 0 .077-.075v-1.07c0-.02.013-.037.038-.035h.814c.083 0 .075-.01.075.073v1.022c0 .095 0 .085.086.085h.779c.063 0 .063 0 .063-.068V65.23c0-.016.01-.038.037-.037h.814c.08 0 .076-.012.076.08v1.062c0 .053 0 .052.055.056a4.5 4.5 0 0 1 .828.134c.151.04.3.095.44.163a1.25 1.25 0 0 1 .684.823c.09.32.095.659.016.982a1.402 1.402 0 0 1-.566.816c-.221.153-.466.27-.725.344-.019.006-.035.002-.044.027Zm-2.544 1.681v1.126c0 .06 0 .06.06.06h.407a5.9 5.9 0 0 0 .868-.04c.17-.022.337-.065.497-.127a.859.859 0 0 0 .514-.564c.08-.263.093-.542.035-.81a.85.85 0 0 0-.379-.575 1.222 1.222 0 0 0-.366-.147 3.189 3.189 0 0 0-.625-.089c-.323-.019-.648 0-.973-.01-.034 0-.046.013-.043.044.003.031 0 .033 0 .049l.005 1.083Zm0-3.087v1c0 .034.007.051.045.05.35.01.7 0 1.049-.03.17-.019.338-.06.498-.122a.755.755 0 0 0 .467-.54c.054-.213.061-.436.022-.653a.735.735 0 0 0-.517-.62 2.455 2.455 0 0 0-.682-.121c-.274-.013-.547 0-.82 0h-.036c-.004 0-.009 0-.013.002a.03.03 0 0 0-.019.032v.056c.003.318.004.634.004.946h.002Z"
      />
      <path
        fill="#F26227"
        d="M131.959 79.33a9.002 9.002 0 0 0 9.002-9.002 9.002 9.002 0 0 0-9.002-9.002 9.002 9.002 0 0 0-9.002 9.002 9.002 9.002 0 0 0 9.002 9.002Z"
      />
      <path
        fill="#D74B27"
        d="M131.959 78.207a7.88 7.88 0 1 0 0-15.759 7.88 7.88 0 0 0 0 15.759Z"
      />
      <path
        fill="#F79421"
        d="M131.612 63.074h.709a.762.762 0 0 0 .147.01 7.118 7.118 0 0 1 2.028.441 7.282 7.282 0 0 1 4.365 4.557 7.026 7.026 0 0 1 .335 1.85.223.223 0 0 0 .01.112v.588a.203.203 0 0 0-.01.112 6.03 6.03 0 0 1-.071.697 7.028 7.028 0 0 1-.735 2.239 7.261 7.261 0 0 1-8.423 3.644 7.218 7.218 0 0 1-4.033-2.91 7.225 7.225 0 0 1-1.228-3.47 1.826 1.826 0 0 0-.022-.256V70a.086.086 0 0 0 .01-.055c.016-.314.054-.627.112-.936a7.088 7.088 0 0 1 .601-1.827 7.277 7.277 0 0 1 4.769-3.88c.416-.105.84-.171 1.267-.201a1.05 1.05 0 0 0 .169-.026Zm-5.702 7.257a6.052 6.052 0 1 0 12.105-.004 6.052 6.052 0 0 0-12.105.004Z"
      />
      <path
        fill="#F79421"
        d="M133.783 70.116c.254.043.502.12.735.23.299.134.549.356.716.638.111.193.181.407.206.629.038.28.025.566-.037.842a1.463 1.463 0 0 1-.923 1.086c-.252.1-.515.17-.784.208-.18.028-.361.048-.545.06-.063 0-.065 0-.065.069v1.077c0 .068 0 .07-.069.07h-.786c-.077 0-.072 0-.072-.073V73.91c0-.087 0-.078-.08-.078h-.784c-.064 0-.064 0-.064.067v1.05c0 .078.008.076-.073.076h-.775c-.073 0-.075 0-.075-.078v-1.078c0-.025-.009-.038-.035-.037H128.5c-.031 0-.046-.007-.046-.04v-.773c0-.038.015-.047.05-.047h.48a1.38 1.38 0 0 0 .294-.038.263.263 0 0 0 .139-.073.266.266 0 0 0 .071-.14c.028-.126.041-.256.038-.385v-4.038a1.666 1.666 0 0 0-.035-.366.277.277 0 0 0-.232-.228 1.887 1.887 0 0 0-.374-.034h-.361c-.064 0-.064 0-.064-.066v-.716c0-.08 0-.076.074-.076h1.702c.077 0 .077 0 .077-.074v-1.07c0-.018.013-.038.038-.037h.814c.082 0 .075-.007.075.074v1.021c0 .096-.007.086.085.086h.779c.064 0 .064 0 .064-.066v-1.078c0-.016.01-.038.036-.037h.815c.081 0 .076-.01.076.081v1.064c0 .053 0 .053.055.056.279.02.556.065.827.135.152.04.3.094.441.162a1.258 1.258 0 0 1 .684.823c.09.321.095.66.016.984a1.397 1.397 0 0 1-.566.814c-.221.154-.466.27-.725.344-.019.002-.035-.001-.044.022Zm-2.544 1.682v1.126c0 .06 0 .06.06.06h.407c.29.007.58-.007.867-.042a2.08 2.08 0 0 0 .497-.125.857.857 0 0 0 .515-.566c.08-.263.092-.542.035-.81a.846.846 0 0 0-.379-.575 1.22 1.22 0 0 0-.366-.147 3.416 3.416 0 0 0-.625-.088c-.323-.02-.648 0-.973-.01-.034 0-.046.013-.043.045.003.032 0 .032 0 .049.003.361.003.721.005 1.083Zm0-3.087v1.001c0 .032.007.05.045.048.35.01.7 0 1.048-.029.171-.02.338-.06.499-.122a.747.747 0 0 0 .467-.538c.054-.215.061-.438.022-.656a.733.733 0 0 0-.517-.62 2.46 2.46 0 0 0-.682-.119c-.274-.015-.547 0-.821-.007h-.035c-.022 0-.034.01-.032.033v.056l.004.953h.002Z"
      />
      <path
        fill="#DF2D2C"
        d="M19.817 49.561h31.192v26.46H19.817A10.584 10.584 0 0 1 9.233 65.437v-5.292a10.584 10.584 0 0 1 10.584-10.584Z"
      />
      <path
        fill="#C12E58"
        d="M129.334 76.021h-21.73v-26.46h21.73a10.584 10.584 0 0 1 10.584 10.584v5.292a10.584 10.584 0 0 1-10.584 10.584Z"
      />
      <path
        fill="#A02960"
        d="M25.11 59.484a4.63 4.63 0 0 0-9.262 0v6.615a4.63 4.63 0 0 0 9.261 0v-6.615Z"
      />
      <path
        fill="#4A2955"
        d="M134.036 59.484a4.63 4.63 0 1 0-9.261 0v6.615a4.63 4.63 0 1 0 9.261 0v-6.615Z"
        opacity={0.45}
      />
      <path
        fill="#542F5F"
        d="M58.239 83.41a16.226 16.226 0 0 0 32.395 0H58.24Z"
      />
      <path
        fill="#001A1B"
        d="M62.263 86.077a12.244 12.244 0 0 0 23.977 0H62.263Z"
      />
      <path
        fill="#3D2F39"
        d="m102.257 52.511-13.732 4.974a7.528 7.528 0 0 0-4.515 9.642l.312.86a7.528 7.528 0 0 0 9.642 4.515l13.732-4.974a7.527 7.527 0 0 0 4.515-9.642l-.312-.86a7.528 7.528 0 0 0-9.642-4.515Z"
      />
      <path
        fill="#EB6447"
        d="M94.894 70.51a6.845 6.845 0 1 0 0-13.69 6.845 6.845 0 0 0 0 13.69Z"
      />
      <path
        fill="#3D2F39"
        d="m93.01 58.287-.12.044c-.56.202-.848.82-.646 1.378l3.152 8.703c.203.56.82.848 1.378.646l.122-.044c.559-.203.848-.82.645-1.378l-3.152-8.704a1.076 1.076 0 0 0-1.378-.645Z"
      />
      <path
        fill="#80191B"
        d="m40.48 67.746 13.733 4.974a7.528 7.528 0 0 0 9.642-4.514l.312-.862a7.528 7.528 0 0 0-4.515-9.641L45.92 52.729a7.528 7.528 0 0 0-9.641 4.514l-.312.861a7.528 7.528 0 0 0 4.514 9.642Z"
      />
      <path
        fill="#EB6447"
        d="M53.289 70.733a6.844 6.844 0 1 0 0-13.688 6.844 6.844 0 0 0 0 13.688Z"
      />
      <path
        fill="#3D2F39"
        d="m51.28 69.231.122.044a1.076 1.076 0 0 0 1.379-.645l3.152-8.703a1.076 1.076 0 0 0-.645-1.379l-.122-.044a1.076 1.076 0 0 0-1.378.646l-3.153 8.703a1.076 1.076 0 0 0 .646 1.378Z"
      />
      <path
        fill="#4A3022"
        d="m73.985 79.198-14.628-8.776a15.876 15.876 0 0 1 14.068 1.127l.56.336"
      />
      <path
        fill="#021213"
        d="m74.051 79.182 14.628-8.778a15.875 15.875 0 0 0-14.07 1.128l-.558.335"
      />
      <path
        fill="#AF3271"
        d="m74.145 30.37-.051-5.233a2.307 2.307 0 0 0 2.143-1.45l4.307-10.769h2.749c6.477 0 9.894 7.674 5.561 12.495l-4.47 4.957H74.145Z"
      />
      <path
        fill="#E9632A"
        d="M74.165 20.636a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path
        fill="#E9632A"
        d="M74.165 25.174a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path
        fill="#DE2F7A"
        d="M74.378 25.137h-.183a2.308 2.308 0 0 1-2.143-1.451l-4.307-10.77h-2.749c-6.477 0-9.894 7.673-5.561 12.494l4.47 4.968h10.24"
      />
      <path fill="#4F4F8E" d="M74.494 30.37H63.905v4.538h10.589V30.37Z" />
      <path fill="#532D5C" d="M74.144 34.908h10.24V30.37h-10.24v4.538Z" />
      <path
        fill="#EB6329"
        d="M74.203 16.058a2.27 2.27 0 1 0 0-4.539 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path fill="#C84626" d="M74.203 16.134a2.27 2.27 0 1 1 0 4.54" />
      <path fill="#C84626" d="M74.203 20.596a2.27 2.27 0 1 1 0 4.538" />
      <path
        fill="#E8582C"
        d="M74.145 30.37h-10.24v.698h10.24v-.698ZM74.145 34.56h-10.24v.698h10.24v-.699Z"
      />
      <path
        fill="#F4ACC5"
        d="M74.145 31.03h-10.24v.388h10.24v-.388ZM74.145 34.172h-10.24v.388h10.24v-.388ZM74.145 32.115h-.698v1.396h.698v-1.396Z"
      />
      <path
        fill="#E9632A"
        d="m58.374 17.024-.108.162 13.615 9.076.107-.162-13.614-9.076ZM57.658 20.856l-.07.181 13.615 5.237.07-.18-13.614-5.238ZM58.681 23.987l-.034.192 11.52 2.093.035-.191-11.52-2.094Z"
      />
      <path
        fill="#C9481D"
        d="M74.144 31.068h10.24v-.698h-10.24v.698ZM74.144 35.256h10.24v-.698h-10.24v.698Z"
      />
      <path
        fill="#F28DB2"
        d="M74.144 31.417h10.24v-.388h-10.24v.388ZM74.144 34.56h10.24v-.389h-10.24v.389ZM74.144 33.513h.698v-1.396h-.698v1.396Z"
      />
      <path
        fill="#C84626"
        d="M89.917 17.024 76.303 26.1l.107.162 13.615-9.076-.108-.162ZM90.633 20.849l-13.615 5.237.07.181 13.614-5.237-.07-.181ZM89.606 23.985l-11.52 2.096.034.192 11.52-2.096-.034-.192ZM74.203 11.48a2.27 2.27 0 1 1 0 4.538"
      />
      <path
        fill="#EA6428"
        d="m79.387 7.856-5.183-5.184-5.184 5.184 5.184 5.183 5.183-5.183Z"
      />
      <path
        fill="#532D5A"
        d="M78.4 7.855 74.204 3.66l-4.197 4.196 4.197 4.197L78.4 7.855Z"
      />
      <path
        fill="#BA3272"
        d="M74.203 10.124a2.27 2.27 0 1 0 0-4.54 2.27 2.27 0 0 0 0 4.54Z"
      />
      <path fill="#BA3272" d="M74.378 0h-.349v7.68h.35V0Z" />
      <path
        fill="#E9632A"
        d="M69.41 23.931 64.918 12.89h2.842l4.307 10.766a2.308 2.308 0 0 0 2.143 1.45l-.012 1.22h-1.19a3.875 3.875 0 0 1-3.598-2.394Z"
      />
      <path
        fill="#C84626"
        d="m78.954 23.92 4.494-11.042h-2.842l-4.307 10.767a2.309 2.309 0 0 1-2.145 1.45l.012 1.22h1.19a3.872 3.872 0 0 0 3.598-2.395Z"
      />
      <path fill="#F4ACC5" d="M74.03 26.18H69.49v1.397h4.538v-1.396Z" />
      <path
        fill="#2D2044"
        d="M74.145 26.18v4.19a1.745 1.745 0 0 1-1.745-1.745v-.695a1.743 1.743 0 0 1 1.745-1.75Z"
      />
      <path fill="#4F4F8E" d="M74.144 26.879h-1.047v2.793h1.047v-2.793Z" />
      <path fill="#F28DB2" d="M74.261 27.579H78.8v-1.397h-4.538v1.397Z" />
      <path
        fill="#172027"
        d="M74.145 30.37v-4.19a1.745 1.745 0 0 1 1.745 1.745v.696a1.744 1.744 0 0 1-1.745 1.75Z"
      />
      <path fill="#532D5C" d="M74.107 29.672h1.047v-2.793h-1.047v2.793Z" />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace428;
