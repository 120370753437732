import { BitcoinFaceLogoProps } from "../../store/faces";

const BitcoinFace515 = (props: BitcoinFaceLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 147 147"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#prefix__a)">
      <path fill="#A9D4EB" d="M150.675 0H0v150.675h150.675V0Z" />
      <path fill="#BA3D11" d="M124.803 66.54H23.698v81.168h101.105V66.541Z" />
      <path fill="#542F5F" d="M101.677 66.319H45.483v81.389h56.194v-81.39Z" />
      <path fill="#172027" d="M102.165 66.319H73.553v81.389h28.612v-81.39Z" />
      <path
        fill="#DF2D2C"
        d="M74.62 21.912H63.235a29.466 29.466 0 0 0-26.141 15.867L16.073 78.204l32.487 37.61a34.432 34.432 0 0 0 26.06 11.929V21.912Z"
      />
      <path
        fill="#C12E58"
        d="M74.62 21.912h11.384a29.469 29.469 0 0 1 26.141 15.867l21.021 40.425-32.487 37.608a34.445 34.445 0 0 1-26.059 11.931V21.912Z"
      />
      <path
        fill="#F26227"
        d="M20.827 78.892a9.002 9.002 0 1 0 0-18.005 9.002 9.002 0 0 0 0 18.005Z"
      />
      <path
        fill="#D74B27"
        d="M20.827 77.769a7.88 7.88 0 1 0 0-15.759 7.88 7.88 0 0 0 0 15.759Z"
      />
      <path
        fill="#F79421"
        d="M20.48 62.637h.709c.048.007.097.01.147.01.205.018.41.038.614.07.484.075.959.198 1.417.37a7.284 7.284 0 0 1 4.365 4.556 6.973 6.973 0 0 1 .335 1.851.217.217 0 0 0 .01.11v.588a.2.2 0 0 0-.01.11 7.037 7.037 0 0 1-.806 2.937 7.247 7.247 0 0 1-7.037 3.893 7.033 7.033 0 0 1-1.386-.25 7.213 7.213 0 0 1-4.034-2.91 7.215 7.215 0 0 1-1.227-3.47 1.838 1.838 0 0 0-.022-.254v-.696a.083.083 0 0 0 .01-.053c.016-.315.053-.628.112-.938a7.25 7.25 0 0 1 6.637-5.907 1.34 1.34 0 0 0 .166-.017Zm-5.702 7.257a6.051 6.051 0 1 0 6.049-6.058 6.045 6.045 0 0 0-6.049 6.058Z"
      />
      <path
        fill="#F79421"
        d="M22.651 69.68c.257.042.507.12.743.229a1.6 1.6 0 0 1 .716.636c.11.194.18.408.205.63.038.28.026.565-.036.842a1.48 1.48 0 0 1-.924 1.088c-.252.1-.515.169-.783.207-.18.028-.362.048-.545.059-.064 0-.065 0-.065.07v1.078c0 .067 0 .067-.07.067h-.786c-.076 0-.072 0-.072-.07v-1.042c0-.087.008-.078-.079-.078h-.785c-.063 0-.063 0-.063.064v1.05c0 .08.007.076-.074.076h-.782c-.073 0-.075 0-.075-.076v-1.078c0-.025-.008-.04-.035-.036-.026.003-.038 0-.056 0h-1.717c-.03 0-.045 0-.045-.043v-.772c0-.037.014-.047.05-.045h.479c.099-.002.197-.015.294-.038a.26.26 0 0 0 .21-.214c.028-.127.041-.256.038-.386V67.86a1.697 1.697 0 0 0-.035-.365.277.277 0 0 0-.232-.228 1.879 1.879 0 0 0-.374-.034h-.361c-.064 0-.064 0-.064-.066v-.716c0-.082 0-.076.074-.076h1.702c.077 0 .077 0 .077-.075v-1.07c0-.02.013-.037.038-.035h.814c.083 0 .075-.01.075.073v1.022c0 .095 0 .085.085.085h.78c.063 0 .063 0 .063-.068V65.23c0-.016.01-.038.037-.037h.814c.08 0 .076-.012.076.08v1.062c0 .053 0 .052.055.056.28.019.556.064.827.134.152.04.3.094.441.163a1.25 1.25 0 0 1 .684.823c.09.32.095.659.016.982a1.402 1.402 0 0 1-.566.816c-.221.153-.466.27-.725.344-.019.006-.035.001-.044.026Zm-2.544 1.681v1.126c0 .06 0 .06.06.06h.407a5.9 5.9 0 0 0 .868-.04c.17-.022.337-.065.496-.127a.856.856 0 0 0 .515-.565c.08-.262.092-.541.035-.81a.85.85 0 0 0-.379-.574 1.219 1.219 0 0 0-.366-.147 3.174 3.174 0 0 0-.625-.089c-.323-.019-.648 0-.973-.01-.034 0-.046.013-.043.044.003.031 0 .033 0 .049l.005 1.083Zm0-3.087v1c0 .034.007.051.045.05.35.01.7 0 1.049-.03.17-.019.338-.06.498-.122a.754.754 0 0 0 .467-.54c.054-.213.061-.436.022-.653a.735.735 0 0 0-.517-.62 2.454 2.454 0 0 0-.682-.121c-.274-.013-.547 0-.82 0h-.036l-.013.001a.029.029 0 0 0-.018.02.03.03 0 0 0-.001.013v.055c.003.319.004.635.004.947h.002Z"
      />
      <path
        fill="#F26227"
        d="M131.959 79.33a9.002 9.002 0 0 0 9.002-9.002 9.002 9.002 0 1 0-18.004 0 9.002 9.002 0 0 0 9.002 9.002Z"
      />
      <path
        fill="#D74B27"
        d="M131.959 78.207a7.879 7.879 0 0 0 7.879-7.88 7.879 7.879 0 1 0-15.758 0 7.879 7.879 0 0 0 7.879 7.88Z"
      />
      <path
        fill="#F79421"
        d="M131.612 63.073h.708a.766.766 0 0 0 .147.01c.206.018.411.04.615.071.483.075.957.199 1.414.37a7.284 7.284 0 0 1 4.365 4.558 7.102 7.102 0 0 1 .335 1.85.224.224 0 0 0 .01.112v.588a.203.203 0 0 0-.01.112 7.03 7.03 0 0 1-.806 2.936 7.262 7.262 0 0 1-8.423 3.643 7.223 7.223 0 0 1-4.034-2.91 7.234 7.234 0 0 1-1.227-3.47 1.991 1.991 0 0 0-.022-.255V69.999a.093.093 0 0 0 .01-.055c.016-.314.053-.627.112-.936a7.267 7.267 0 0 1 6.637-5.908 1.08 1.08 0 0 0 .169-.027Zm-5.702 7.258a6.046 6.046 0 0 0 7.234 5.934 6.054 6.054 0 0 0 4.754-4.757 6.049 6.049 0 0 0-2.576-6.212 6.054 6.054 0 0 0-9.412 5.035Z"
      />
      <path
        fill="#F79421"
        d="M133.783 70.116c.254.043.502.12.735.23.299.133.549.356.716.637.111.194.181.408.206.63.038.28.025.565-.037.842a1.463 1.463 0 0 1-.923 1.086c-.252.1-.515.17-.784.207-.18.028-.361.049-.545.06-.063 0-.065 0-.065.07v1.077c0 .068 0 .07-.069.07h-.786c-.077 0-.072 0-.072-.073V73.91c0-.087 0-.078-.08-.078h-.784c-.064 0-.064 0-.064.066v1.05c0 .08.008.076-.073.076h-.775c-.073 0-.075 0-.075-.078V73.87c0-.025-.009-.038-.035-.037H128.5c-.031 0-.046-.007-.046-.041v-.772c0-.038.015-.047.05-.047h.48a1.38 1.38 0 0 0 .294-.038.263.263 0 0 0 .139-.073.266.266 0 0 0 .071-.14c.028-.127.041-.256.038-.385v-4.038a1.666 1.666 0 0 0-.035-.366.277.277 0 0 0-.232-.228 1.887 1.887 0 0 0-.374-.034h-.361c-.064 0-.064 0-.064-.066v-.716c0-.081 0-.077.074-.077h1.702c.077 0 .077 0 .077-.073v-1.07c0-.018.013-.038.038-.037h.814c.082 0 .075-.007.075.073v1.022c0 .096-.007.085.085.085h.779c.064 0 .064 0 .064-.066v-1.077c0-.016.01-.038.036-.037h.815c.081 0 .076-.01.076.08v1.065c0 .053 0 .053.055.056.279.02.556.065.827.135.152.04.3.094.441.162a1.256 1.256 0 0 1 .684.823c.09.321.095.66.016.984a1.397 1.397 0 0 1-.566.814c-.221.153-.466.27-.725.344-.019.001-.035-.001-.044.022Zm-2.544 1.682v1.126c0 .06 0 .06.06.06h.407c.29.007.58-.007.867-.043a2.08 2.08 0 0 0 .497-.125.858.858 0 0 0 .515-.566c.08-.262.092-.54.035-.81a.846.846 0 0 0-.379-.574 1.22 1.22 0 0 0-.366-.147 3.416 3.416 0 0 0-.625-.088c-.323-.02-.648 0-.973-.01-.034 0-.046.012-.043.045.003.032 0 .032 0 .048.003.362.003.722.005 1.084Zm0-3.087v1.001c0 .032.007.05.045.048.35.01.7 0 1.048-.03.171-.018.338-.06.499-.121a.747.747 0 0 0 .467-.538c.054-.215.061-.438.022-.656a.733.733 0 0 0-.517-.62 2.457 2.457 0 0 0-.682-.12c-.274-.014-.547 0-.821-.007h-.035c-.022 0-.034.01-.032.034v.056l.004.953h.002Z"
      />
      <path
        fill="#883A62"
        d="M53.589 30.247 35.067 17.898l-4.912 6.14a10.91 10.91 0 0 0 2.468 15.894l8.616 5.744"
      />
      <path
        fill="#4D2F47"
        d="M94.728 30.198 113.25 17.85l4.911 6.14a10.902 10.902 0 0 1 2.263 8.49 10.912 10.912 0 0 1-4.729 7.404l-8.617 5.745"
      />
      <path
        fill="#BA3D11"
        d="M46.999 94.743a43.889 43.889 0 0 0 25.173 7.938h2.034v-7.938"
      />
      <path
        fill="#A31905"
        d="M101.58 94.702a43.902 43.902 0 0 1-25.175 7.938h-2.033v-7.938"
      />
      <path
        fill="#6C1908"
        d="m74.206 90.208-3.402-3.4-24.94 7.935h28.342v-4.535Z"
      />
      <path
        fill="#3E1B11"
        d="m74.372 90.167 3.401-3.4 24.94 7.935H74.372v-4.535Z"
      />
      <path
        fill="#3D2F39"
        d="m102.258 52.51-13.733 4.975a7.528 7.528 0 0 0-4.515 9.641l.312.861a7.528 7.528 0 0 0 9.642 4.515l13.733-4.974a7.527 7.527 0 0 0 4.514-9.642l-.312-.86a7.527 7.527 0 0 0-9.641-4.515Z"
      />
      <path
        fill="#EB6447"
        d="M94.894 70.51a6.845 6.845 0 1 0 0-13.69 6.845 6.845 0 0 0 0 13.69Z"
      />
      <path
        fill="#3D2F39"
        d="m93.01 58.287-.12.044c-.56.202-.848.82-.646 1.378l3.152 8.703c.203.559.82.848 1.378.645l.122-.044c.559-.202.848-.819.645-1.378l-3.152-8.703a1.076 1.076 0 0 0-1.378-.645Z"
      />
      <path
        fill="#80191B"
        d="m40.48 67.746 13.734 4.974a7.528 7.528 0 0 0 9.641-4.515l.312-.86a7.528 7.528 0 0 0-4.514-9.642L45.92 52.729a7.528 7.528 0 0 0-9.642 4.514l-.312.861a7.528 7.528 0 0 0 4.515 9.642Z"
      />
      <path
        fill="#EB6447"
        d="M53.29 70.733a6.844 6.844 0 1 0 0-13.689 6.844 6.844 0 0 0 0 13.69Z"
      />
      <path
        fill="#3D2F39"
        d="m51.28 69.23.122.045a1.076 1.076 0 0 0 1.379-.645l3.152-8.704a1.076 1.076 0 0 0-.645-1.378l-.122-.044a1.076 1.076 0 0 0-1.378.645l-3.153 8.704a1.076 1.076 0 0 0 .646 1.378Z"
      />
      <path
        fill="#4D2F47"
        d="M79.998 79.674a5.5 5.5 0 0 1-5.501-5.5v-9.85h6.133a5.803 5.803 0 0 1 5.652 7.125l-.928 3.97a5.502 5.502 0 0 1-5.356 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M79.925 77.88a3.637 3.637 0 1 0 0-7.273 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M79.875 76.984a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
      <path
        fill="#883A62"
        d="M69.015 79.63a5.5 5.5 0 0 0 5.5-5.501V64.28h-6.133a5.804 5.804 0 0 0-5.653 7.125l.93 3.97a5.499 5.499 0 0 0 5.356 4.255Z"
      />
      <path
        fill="#EB6447"
        d="M69.084 77.837a3.637 3.637 0 1 0 0-7.274 3.637 3.637 0 0 0 0 7.273Z"
      />
      <path
        fill="#3D2F39"
        d="M69.134 76.94a2.69 2.69 0 1 0 0-5.38 2.69 2.69 0 0 0 0 5.38Z"
      />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <rect width={147} height={147} fill="#fff" rx={7} />
      </clipPath>
    </defs>
  </svg>
);
export default BitcoinFace515;
